import React, { useEffect, useRef, useState } from "react";
import { InamdarHospiLogo } from "../../assets/printAssets/InmadarHospiLogo";
import { GeneratePrint } from "../../common/components/printModal/GeneratePrint";
import {
  OrderSheetPrintData,
  fetchOrderSheetAllList,
} from "../services/cliinicalChartServices/orderSheetServices/OrderSheetServices";

export default function OrderSheetPrint(props) {
  console.log("OrderSheetPrint");
  const printRef = useRef(null);

  const [printDataResult, setPrintDataResult] = useState();

  console.log(props);
  const handlePrintAllOrder = () => {
    let defaultParams = {
      searchId: props?.searchId,
      status: props?.status,
    };
    fetchOrderSheetAllList(defaultParams)
      .then((response) => {
        return response.data;
      })
      .then((res) => {
        setPrintDataResult([...res.result]);
        props.setOpenBackdrop(false);
      })
      .catch((res) => props.setOpenBackdrop(false));
  };

  React.useEffect(() => {
    !props?.isPrintAll &&
      OrderSheetPrintData(props.orderSheetId)
        .then((response) => response.data)
        .then((res) => {
          setPrintDataResult([res.result]);
          props.setOpenBackdrop(false);
        })
        .catch((res) => props.setOpenBackdrop(false));

    props?.isPrintAll && handlePrintAllOrder();
  }, [props]);

  useEffect(() => {
    printDataResult && printRef && GeneratePrint(printRef);
  }, [printDataResult]);

  return (
    <div ref={printRef} className="ml-14">
      {printDataResult?.map((dataResult) => {
        return (
          <div className="text-sm">
            {printDataResult.map((dataResult, index) => (
              <div key={index}>
                <div className="border-b-[1px] border-black flex justify-end">
                  <InamdarHospiLogo />
                </div>
                <div className="text-center font-semibold text-[14px] border-b-[1px] border-black">
                  ORDER SHEET
                </div>
                <div className="border-b-[1px] p-[1px] border-black grid grid-cols-[15%_4%_31%_15%_4%_31%]">
                  {(dataResult?.MRNo || dataResult?.MrNo) && (
                    <>
                      <div className="text-[14px] font-semibold">MRNo</div>
                      <div className="text-[14px] font-semibold">:</div>
                      <div className="text-[14px]">
                        {dataResult?.MRNo || dataResult?.MrNo}
                      </div>
                    </>
                  )}
                  <div className="text-[14px] font-semibold">Patient Name</div>
                  <div className="text-[14px] font-semibold">:</div>
                  <div className="text-[14px]">
                    {dataResult?.PatientName || dataResult?.["Patient name"]}
                  </div>
                  {dataResult?.admDate && (
                    <>
                      <div className="text-[14px] font-semibold">Adm Date</div>
                      <div className="text-[14px] font-semibold">:</div>
                      <div className="text-[14px]">{dataResult?.admDate}</div>
                    </>
                  )}
                  {dataResult?.DoctorName && (
                    <>
                      <div className="text-[14px] font-semibold">
                        Consultant Name
                      </div>
                      <div className="text-[14px] font-semibold">:</div>
                      <div className="text-[14px]">
                        {dataResult?.DoctorName}
                      </div>
                    </>
                  )}
                  {dataResult?.BedNo && (
                    <>
                      <div className="text-[14px] font-semibold">Bed No.</div>
                      <div className="text-[14px] font-semibold">:</div>
                      <div className="text-[14px]">{dataResult?.BedNo}</div>
                    </>
                  )}
                </div>
                <div className="border-b-[1px] border-black p-[1px] flex justify-between">
                  <div className="font-semibold text-[14px]">
                    {dataResult?.dateTime}
                  </div>
                  <div className="font-semibold text-[14px]">
                    {dataResult?.PrintedOn}
                  </div>
                </div>
                {dataResult?.subjective && (
                  <div className="p-[1px] pt-5">
                    <div className="font-semibold w-20 text-[13px] border-black border-b-[1px]">
                      SUBJECTIVE
                    </div>
                    <p className="text-[14px] p-[1px]">
                      {dataResult?.subjective}
                    </p>
                  </div>
                )}

                {(dataResult?.temp ||
                  dataResult?.bp ||
                  dataResult?.pulse ||
                  dataResult?.rr ||
                  dataResult?.cvp ||
                  dataResult?.mews ||
                  dataResult?.SPO2 ||
                  dataResult?.PainScore) && (
                  <>
                    {" "}
                    <div className="p-[1px] pt-3">
                      <div className="font-semibold w-20 text-[13px] border-black border-b-[1px]">
                        OBJECTIVE
                      </div>

                      <div className="font-semibold w-9 mt-3 text-[13px] border-gray-400 border-b-[1px]">
                        Vitals
                      </div>
                    </div>
                    <div className="grid grid-cols-9">
                      {dataResult?.temp && (
                        <>
                          <div className="text-[14px] font-semibold">Temp</div>
                          <div className="text-[14px] font-semibold">:</div>
                          <div className="text-[14px]">{dataResult?.temp}</div>
                        </>
                      )}
                      {dataResult?.bp && (
                        <>
                          <div className="text-[14px] font-semibold">BP</div>
                          <div className="text-[14px] font-semibold">:</div>
                          <div className="text-[14px]">{dataResult?.bp}</div>
                        </>
                      )}
                      {dataResult?.pulse && (
                        <>
                          <div className="text-[14px] font-semibold">Pulse</div>
                          <div className="text-[14px] font-semibold">:</div>
                          <div className="text-[14px]">{dataResult?.pulse}</div>
                        </>
                      )}
                      {dataResult?.rr && (
                        <>
                          <div className="text-[14px] font-semibold">R.R.</div>
                          <div className="text-[14px] font-semibold">:</div>
                          <div className="text-[14px]">{dataResult?.rr}</div>
                        </>
                      )}
                      {dataResult?.cvp && (
                        <>
                          <div className="text-[14px] font-semibold">CVP</div>
                          <div className="text-[14px] font-semibold">:</div>
                          <div className="text-[14px]">{dataResult?.cvp}</div>
                        </>
                      )}
                      {dataResult?.mews && (
                        <>
                          <div className="text-[14px] font-semibold">MEWS</div>
                          <div className="text-[14px] font-semibold">:</div>
                          <div className="text-[14px]">{dataResult?.mews}</div>
                        </>
                      )}
                      {dataResult?.SPO2 && (
                        <>
                          <div className="text-[14px] font-semibold">SPO2</div>
                          <div className="text-[14px] font-semibold">:</div>
                          <div className="text-[14px]">{dataResult?.SPO2}</div>
                        </>
                      )}
                      {dataResult?.PainScore && (
                        <>
                          <div className="text-[14px] font-semibold">
                            PainScore
                          </div>
                          <div className="text-[14px] font-semibold">:</div>
                          <div className="text-[14px]">
                            {dataResult?.PainScore}
                          </div>
                        </>
                      )}
                    </div>
                  </>
                )}
                {dataResult?.localexamination && (
                  <div className="p-[1px] pt-5">
                    <div className="font-semibold w-44 text-[13px] border-black border-b-[1px]">
                      Local Examination
                    </div>
                    <p className="text-[14px] p-[1px]">
                      {dataResult?.localexamination}
                    </p>
                  </div>
                )}
                {(dataResult?.cvs ||
                  dataResult?.rs ||
                  dataResult?.pa ||
                  dataResult?.cns ||
                  dataResult?.pv ||
                  dataResult?.other) && (
                  <div>
                    <div className="font-semibold whitespace-nowrap w-44 mt-3 text-[13px] border-black border-b-[1px]">
                      SYSTEMIC EXAMINATION
                    </div>
                  </div>
                )}
                {dataResult?.cvs && (
                  <div className="grid grid-cols-[11%_5%_84%]">
                    <div className="text-[14px] font-semibold">CVS</div>
                    <div className="text-[14px] font-semibold">:</div>
                    <div className="text-[14px]">{dataResult?.cvs}</div>
                  </div>
                )}
                {dataResult?.rs && (
                  <div className="grid grid-cols-[11%_5%_84%]">
                    <div className="text-[14px] font-semibold">RS</div>
                    <div className="text-[14px] font-semibold">:</div>
                    <div className="text-[14px]">{dataResult?.rs}</div>
                  </div>
                )}
                {dataResult?.pa && (
                  <div className="grid grid-cols-[11%_5%_84%]">
                    <div className="text-[14px] font-semibold">PA</div>
                    <div className="text-[14px] font-semibold">:</div>
                    <div className="text-[14px]">{dataResult?.pa}</div>
                  </div>
                )}
                {dataResult?.cns && (
                  <div className="grid grid-cols-[11%_5%_84%]">
                    <div className="text-[14px] font-semibold">CNS</div>
                    <div className="text-[14px] font-semibold">:</div>
                    <div className="text-[14px]">{dataResult?.cns}</div>
                  </div>
                )}
                {dataResult?.pv && (
                  <div className="grid grid-cols-[11%_5%_84%]">
                    <div className="text-[14px] font-semibold">P/V</div>
                    <div className="text-[14px] font-semibold">:</div>
                    <div className="text-[14px]">{dataResult?.pv}</div>
                  </div>
                )}
                {dataResult?.other && (
                  <div className="my-2 font-semibold grid grid-cols-[11%_5%_84%]">
                    <div className="text-[14px] font-semibold">Other</div>
                    <div className="text-[14px] font-semibold">:</div>
                    <div className="text-[14px]">{dataResult?.other}</div>
                  </div>
                )}
                {dataResult?.assesment && (
                  <div className="p-[1px] pt-5">
                    <div className="font-semibold w-24 text-[13px] border-black border-b-[1px]">
                      ASSESSMENT
                    </div>
                    <p className="text-[14px] p-[1px]">
                      {dataResult?.assesment}
                    </p>
                  </div>
                )}
                {dataResult?.plan && (
                  <div className="p-[1px] pt-5 pb-5">
                    <div className="font-semibold w-20 text-[13px] border-black border-b-[1px]">
                      PLAN
                    </div>
                    <p className="text-[14px] p-[1px]">{dataResult?.plan}</p>
                  </div>
                )}
                {dataResult?.DietPlan && (
                  <div className="p-[1px] pt-5 pb-5">
                    <div className="font-semibold w-20 text-[13px] border-black border-b-[1px]">
                      DIET PLAN
                    </div>
                    <p className="text-[14px] p-[1px]">
                      {dataResult?.DietPlan}
                    </p>
                  </div>
                )}
                {dataResult?.stool !== null && dataResult?.urine !== null && (
                  <div className="flex items-center">
                    <div className="font-semibold text-[13px]">Stool</div>
                    <div>&nbsp;:&nbsp;{dataResult?.stool}</div>
                    <div className="font-semibold text-[13px] ml-20">Urine</div>
                    <div>&nbsp;:&nbsp;{dataResult?.stool}</div>
                  </div>
                )}
                <div className="flex justify-end border-t-[1px] border-black my-5 p-[1px]">
                  <div>
                    <div>{dataResult?.AddedBy}</div>
                    <div className="font-semibold text-[15px]">
                      RMO Name & Signature
                    </div>
                    <div className="font-semibold">
                      Date : ___/___/___ Time : ___
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        );
      })}
    </div>
  );
}
