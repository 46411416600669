import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import InputField from "../../../common/components/FormFields/InputField";
import {
  DesktopDatePicker,
  DesktopTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import CheckBoxField from "../../../common/components/FormFields/CheckBoxField";
import RadioField from "../../../common/components/FormFields/RadioField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import InputArea from "../../../common/components/FormFields/InputArea";
import TimePickerFieldNew from "../../../common/components/FormFields/TimePickerFieldNew";

const isCPR = [
  {
    id: 1,
    value: "Yes",
    label: "Yes",
  },
  {
    id: 0,
    value: "NO",
    label: "No",
  },
];

const isRocs = [
  {
    id: 1,
    value: "Yes",
    label: "Yes",
  },
  {
    id: 0,
    value: "NO",
    label: "No",
  },
];

const CBForm1 = () => {
  const {
    register,
    control,
    formState: { errors },
    watch,
  } = useFormContext();

  let IsCPRInitiated = Number(watch("isCprInitiated"));

  return (
    <>
      <div className="grid md:grid-cols-2 lg:grid-cols-5 gap-3">
        {/* date */}
        <div>
          <Controller
            control={control}
            name="cdfDate"
            render={({ field: { value, onChange } }) => (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label="Date"
                  inputFormat="dd-MM-yyyy"
                  value={value}
                  onChange={onChange}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      {...params}
                      error={errors.transferDate}
                      helperText={errors.transferDate?.message}
                      inputProps={{ ...params.inputProps, readOnly: true }}
                      InputLabelProps={{
                        style: {
                          fontSize: "14px",
                          position: "absolute",
                          top: "-2px",
                        },
                      }}
                      sx={{
                        svg: {
                          color: "#1e3a8a",
                          height: 22,
                          width: "100%",
                          marginRight: "16px",
                        },
                        backgroundColor: "white",
                        overflow: "visible",

                        "& .MuiOutlinedInput-root": {
                          "& .MuiOutlinedInput-input": {
                            // border: 0,
                            fontSize: 14,
                            height: "18px",
                            width: "100%",

                            borderColor: "  ",
                            overflow: "hidden",
                          },
                          "& .MuiFormLabel-root": {
                            fontSize: "14px",
                          },
                        },
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            )}
          />
        </div>
        {/* time */}
        <div>
          <Controller
            control={control}
            name="cdfTime"
            render={({ field: { value, onChange } }) => (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopTimePicker
                  label="Time"
                  value={value}
                  onChange={onChange}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      {...params}
                      error={errors.transferTime}
                      helperText={errors.transferTime?.message}
                      inputProps={{ ...params.inputProps, readOnly: true }}
                      InputLabelProps={{
                        style: {
                          fontSize: "14px",
                          position: "absolute",
                          top: "-2px",
                        },
                      }}
                      sx={{
                        svg: {
                          color: "#1e3a8a",
                          height: 22,
                          width: "100%",
                          marginRight: "16px",
                        },
                        backgroundColor: "white",
                        overflow: "visible",

                        "& .MuiOutlinedInput-root": {
                          "& .MuiOutlinedInput-input": {
                            // border: 0,
                            fontSize: 14,
                            height: "18px",
                            width: "100%",

                            borderColor: "  ",
                            overflow: "hidden",
                          },
                          "& .MuiFormLabel-root": {
                            fontSize: "14px",
                          },
                        },
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            )}
          />
        </div>

        <div className="">
          <InputField
            name="nameOfTheCaller"
            label="Name Of The Caller"
            variant="outlined"
            // error={errors.representativeAddress}
            control={control}
            disabled={false}
            inputProps={{ maxLength: 50 }}
          />
        </div>
        {/*
        <div className="">
          <InputField
            name="cBFormNo"
            label="Code Blue Form Number"
            variant="outlined"
            // error={errors.representativeAddress}
            control={control}
            disabled={false}
            inputProps={{ maxLength: 50 }}
          />
                    </div>*/}
        <div className="">
          <InputField
            name="callerLocation"
            label="Caller Location"
            variant="outlined"
            // error={errors.representativeAddress}
            control={control}
            disabled={false}
            inputProps={{ maxLength: 50 }}
          />
        </div>
        <div className="">
          <InputField
            name="nameOfOperator"
            label="Name Of Operator"
            variant="outlined"
            // error={errors.representativeAddress}
            control={control}
            disabled={false}
            inputProps={{ maxLength: 50 }}
          />
        </div>

        <hr className="border my-1 md:col-span-3 lg:col-span-5 divide-x-8 bg-slate-300 border-slate-300" />

        <div className="">
          <InputField
            name="victimName"
            label="Victim Name"
            variant="outlined"
            error={errors.victimName}
            control={control}
            disabled={false}
            inputProps={{ maxLength: 50 }}
          />
        </div>

        {/* time */}
        <div>
          <Controller
            control={control}
            name="teamArrivalTime"
            render={({ field: { value, onChange } }) => (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopTimePicker
                  label="Time Of Code Arrival Team"
                  value={value}
                  onChange={onChange}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      {...params}
                      error={errors.teamArrivalTime}
                      helperText={errors.teamArrivalTime?.message}
                      inputProps={{ ...params.inputProps, readOnly: true }}
                      InputLabelProps={{
                        style: {
                          fontSize: "14px",
                          position: "absolute",
                          top: "-2px",
                        },
                      }}
                      sx={{
                        svg: {
                          color: "#1e3a8a",
                          height: 22,
                          width: "100%",
                          marginRight: "16px",
                        },
                        backgroundColor: "white",
                        overflow: "visible",

                        "& .MuiOutlinedInput-root": {
                          "& .MuiOutlinedInput-input": {
                            // border: 0,
                            fontSize: 14,
                            height: "18px",
                            width: "100%",

                            borderColor: "  ",
                            overflow: "hidden",
                          },
                          "& .MuiFormLabel-root": {
                            fontSize: "14px",
                          },
                        },
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            )}
          />
        </div>

        <div className="flex space-x-2 col-span-3 ">
          <div className="col-span-1">
            <Controller
              control={control}
              name="codeAnnouncTime"
              render={({ field: { value, onChange } }) => (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopTimePicker
                    label="Announc. Time"
                    value={value}
                    onChange={onChange}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        {...params}
                        //error={errors.transferTime}
                        //helperText={errors.transferTime?.message}
                        inputProps={{ ...params.inputProps, readOnly: true }}
                        InputLabelProps={{
                          style: {
                            fontSize: "14px",
                            position: "absolute",
                            top: "-2px",
                          },
                        }}
                        sx={{
                          svg: {
                            color: "#1e3a8a",
                            height: 22,
                            width: "100%",
                            marginRight: "16px",
                          },
                          backgroundColor: "white",
                          overflow: "visible",

                          "& .MuiOutlinedInput-root": {
                            "& .MuiOutlinedInput-input": {
                              // border: 0,
                              fontSize: 14,
                              height: "18px",
                              width: "100%",

                              borderColor: "  ",
                              overflow: "hidden",
                            },
                            "& .MuiFormLabel-root": {
                              fontSize: "14px",
                            },
                          },
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              )}
            />
          </div>
          <div className="col-span-2 w-full">
            <InputArea
              control={control}
              name="dignosis"
              label="Diagnosis"
              placeholder="Diagnosis"
              minRows={2}
              maxRows={2}
            />
          </div>
        </div>

        {/* checkboxes 3*/}
        <div className="md:col-span-1 lg:col-span-1 ">
          <CheckBoxField
            control={control}
            name="medicalEmergency"
            label="Medical Emergency"
          />
        </div>

        <div className="md:col-span-1 lg:col-span-1 ">
          <CheckBoxField control={control} name="adult" label="Adult" />
        </div>

        <div className="md:col-span-1 lg:col-span-1 ">
          <CheckBoxField
            control={control}
            name="pediatric"
            label="Paediatrics"
          />
        </div>

        <hr className="border my-1 md:col-span-3 lg:col-span-5 divide-x-8 bg-slate-300 border-slate-300" />

        <div className="md:col-span-3 lg:col-span-5">
          <p className="mr-3 font-semibold">Indications For Code Blue</p>
        </div>

        <div className="flex my-3 md:col-span-3 lg:col-span-2">
          <p className="mr-3 font-bold tracking-wide text-sm font-Poppins">
            Was CPR Initiated In The Ward :
          </p>
          <RadioField
            label=""
            name="isCprInitiated"
            control={control}
            dataArray={isCPR}
          />
        </div>
        <div className="col-span-3 grid grid-cols-2">
          {IsCPRInitiated === 1 || IsCPRInitiated === "1" ? (
            <div className="">
              <InputField
                name="ifYesInitiatedBy"
                label="If Yes Initiated By"
                variant="outlined"
                // error={errors.representativeAddress}
                control={control}
                disabled={false}
                inputProps={{ maxLength: 50 }}
              />
            </div>
          ) : (
            <div className="col-span-2 ">
              <InputArea
                control={control}
                name="ifNoExplain"
                label="If No Explain "
                placeholder="If No Explain "
              />
            </div>
          )}
        </div>

        {IsCPRInitiated === 1 && (
          <div className="col-span-5 grid grid-cols-5 gap-2 items-center">
            <div className="flex col-span-2 xl:col-span-1 items-center">
              <p className="w-full font-semibold text-sm">CPR Started Time :</p>
              <TimePickerFieldNew
                control={control}
                name="cprStartTime"
                inputFormat="hh:mm"
              />
            </div>
            <div className="flex col-span-2 xl:col-span-1 items-center">
              <p className="w-full font-semibold text-sm">CPR End Time :</p>
              <TimePickerFieldNew
                control={control}
                name="cprEndTime"
                inputFormat="hh:mm"
              />
            </div>

            <div>
              <InputField
                name="cycleOfCprGiven"
                label="Cycle of CPR Given"
                variant="outlined"
                // error={errors.representativeAddress}
                control={control}
                disabled={false}
              />
            </div>

            <div className="flex pt-2 col-span-2">
              <p className="mr-3 font-bold tracking-wide text-sm font-Poppins">
                Rosc Achieved :
              </p>
              <RadioField
                label=""
                name="isRocsAchieved"
                control={control}
                dataArray={isRocs}
              />
            </div>
          </div>
        )}

        <div className="md:col-span-3 lg:col-span-5 flex md:flex-wrap">
          {/* checkboxes 3*/}
          <div className="md:col-span-1 lg:col-span-1 ">
            <CheckBoxField
              control={control}
              name="wasBasicLifeSupport"
              label="Was Basic Life Support"
            />
          </div>

          <div className="md:col-span-1 lg:col-span-1 ">
            <CheckBoxField
              control={control}
              name="wasAdvancedLifeSupport"
              label="Was Advanced Life Support"
            />
          </div>

          <div className="md:col-span-1 lg:col-span-1 ">
            <CheckBoxField
              control={control}
              name="codeBlueProceduresFollows"
              label="Code Blue Procedures Follows"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CBForm1;
