import React, { useEffect, useState } from "react";
import NewEmrTemplate from "./NewEmrTemplate";
import {
  DeleteIcon,
  EditIcon,
  ForceCloseIcon,
} from "../../../assets/CommonAssets";

import { Box, Modal, Tooltip } from "@mui/material";
import CommonDynamicTableNew from "../../../common/components/CommonTable/CommonDynamicTableNew";
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";

const ModalStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  // maxHeight: "90%",
  width: "97%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 20,
  borderRadius: "10px",
  overflowY: "auto",
  p: 1,
};

const templateObj = [
  {
    value: 37,
    label: "Dental Teamplate",
    id: 37,
  },
  {
    value: 53,
    label: "CardiologyTemp2",
    id: 53,
  },
  {
    label: "E N T Teample",
    value: 54,
    id: 54,
  },
  {
    id: 75,
    value: 75,
    label: "Cardiology1",
  },
  {
    label: "Cardiology",
    value: 52,
    id: 52,
  },
  {
    value: 44,
    label: "Teamplate",
    id: 44,
  },
  {
    id: 41,
    label: "fever",
    value: 41,
  },
  {
    label: "cardiology",
    value: 84,
    id: 84,
  },
];

const tempDetailsObj = {
  templateName: "Dental Template",
  diagnosis: [
    "Gastroesophageal Reflux Disease (GERD)",
    "Hypertension (High Blood Pressure)",
  ],
  investigation: [
    "Electrocardiogram (ECG/EKG)",
    "Blood Culture",
    "Ultrasound",
    "Liver Function Test (LFT)",
  ],
  medication: [
    {
      DrugName: "A TO Z GOLD TAB",
      Dosage: 2,
      Frequency: "1---0---1",
      Route: "EXTERNAL APPLICATION",
      Duration: "2 Days",
      Quantity: 4,
      Instruction: "Twice a day",
      Generic: "SCORBIC ACID(VIT C)+ELEMENTAL",
      Form: "TABLET / CAPSULE",
    },
    {
      DrugName: "B COMPLEX PLUS",
      Dosage: 1,
      Frequency: "0---1---0",
      Route: "ORAL",
      Duration: "5 Days",
      Quantity: 5,
      Instruction: "Once a day",
      Generic: "Folic Acid+Vitamin B12+Vitamin ",
      Form: "TABLET",
    },
    {
      DrugName: "VITAMIN C 500MG",
      Dosage: 1,
      Frequency: "1---0---0",
      Route: "ORAL",
      Duration: "7 Days",
      Quantity: 7,
      Instruction: "Once a day",
      Generic: "ASCORBIC ACID (VIT C)",
      Form: "TABLET",
    },
    {
      DrugName: "CALCIUM D3",
      Dosage: 1,
      Frequency: "0---1---0",
      Route: "ORAL",
      Duration: "10 Days",
      Quantity: 10,
      Instruction: "Once a day",
      Generic: "CALCIUM + VITAMIN D3",
      Form: "TABLET",
    },
    {
      DrugName: "IRON PLUS",
      Dosage: 1,
      Frequency: "1---0---0",
      Route: "ORAL",
      Duration: "15 Days",
      Quantity: 15,
      Instruction: "Once a day",
      Generic: "FERROUS SULFATE + FOLIC ACID",
      Form: "TABLET",
    },
    
  ],
};
function EmrTemplate({ open, close }) {
  const [selectedTab, setSelectedTab] = useState(0);
  const [templateNameList, setTemplateNameList] = useState(templateObj);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedTemplateDetails, setSelectedTemplateDetails] = useState(null);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (selectedTab === 0) {
        const currentIndex = templateNameList.findIndex(
          (temp) => temp.id === selectedRow
        );
        if (e.key === "ArrowUp" && currentIndex > 0) {
          setSelectedRow(templateNameList[currentIndex - 1].id);
        } else if (
          e.key === "ArrowDown" &&
          currentIndex < templateNameList.length - 1
        ) {
          setSelectedRow(templateNameList[currentIndex + 1].id);
        }
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [selectedRow, templateNameList, selectedTab]);

  const handleRowSelect = (tempId) => {
    setSelectedRow(tempId);
    setSelectedTemplateDetails(tempDetailsObj);
  };

  useEffect(()=>{
    handleRowSelect(selectedRow);
  },[])

  const handleDeleteTemplate = (row) => {
    console.log(row);
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={ModalStyles} className="h-[70%]">
        <div className="space-y-2">
          <div className="flex justify-between">
            <div className="flex justify-center font-bold text-xl px-3">
              Template Creation
            </div>
            <div className="flex border rounded my-2">
              <button
                className={`${
                  selectedTab === 0
                    ? "bg-customBlue text-white"
                    : "bg-white text-black"
                }  w-32 text-sm rounded`}
                onClick={() => {
                  setSelectedTab(0);
                }}
              >
                TEMPLATE
              </button>
              <button
                className={`${
                  selectedTab === 1
                    ? "bg-customBlue text-white"
                    : "bg-white text-black"
                }  w-32 text-sm rounded`}
                onClick={() => {
                  setSelectedTab(1);
                }}
              >
                NEW
              </button>
            </div>
            <div className="flex items-center">
              <button onClick={close} type="button">
                <CancelPresentationIconButton />
              </button>
            </div>
          </div>

          {selectedTab === 0 && (
            <div className="flex gap-2">
              <div className="w-[30%] border rounded-md shadow-md">
                <h2 className="bg-customBlue text-center p-2 font-semibold font-Poppins rounded-t text-white">
                  Template Name
                </h2>

                <div>
                  {templateNameList.map((temp, index) => (
                    <div
                      className={`flex p-[1px] items-center border-b px-2 cursor-pointer ${
                        selectedRow === temp.id ? "bg-yellow-400" : ""
                      }`}
                      key={index}
                      onClick={() => handleRowSelect(temp.id)}
                      tabIndex={0}
                    >
                      <div>
                        <Tooltip title="Edit">
                          <button
                            className="h-5"
                            type="button"
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedRow(temp?.id);
                              setSelectedTab(1);
                            }}
                          >
                            <EditIcon />
                          </button>
                        </Tooltip>
                      </div>
                      <div>
                        <DeleteIcon
                          type="button"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteTemplate(temp?.id);
                          }}
                        />
                      </div>

                      <div className="pl-2">
                        <h3 className="text-sm capitalize font-Poppins">
                          {temp?.label}
                        </h3>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="w-[70%] border rounded-md shadow-md px-4 p-2">
                {selectedTemplateDetails && selectedRow && (
                  <div className="space-y-2">
                    <h2 className="text-center text-xl font-semibold">
                      {selectedTemplateDetails?.templateName}
                    </h2>

                    <div>
                      {selectedTemplateDetails?.diagnosis && (
                        <div>
                          <h2 className="font-semibold">Diagnosis</h2>
                          {selectedTemplateDetails?.diagnosis.map(
                            (digno, index) => (
                              <ul key={index} className="px-12">
                                <li className="list-disc">{digno}</li>
                              </ul>
                            )
                          )}
                        </div>
                      )}
                    </div>

                    <div>
                      {selectedTemplateDetails?.investigation && (
                        <div>
                          <h2 className="font-semibold">Investigation</h2>
                          <div className="columns-3">
                            {selectedTemplateDetails?.investigation.map(
                              (inv, index) => (
                                <ul className="px-12" key={index}>
                                  <li className="list-disc">{inv}</li>
                                </ul>
                              )
                            )}
                          </div>
                        </div>
                      )}
                    </div>

                    <div>
                      {selectedTemplateDetails?.medication && (
                        <CommonDynamicTableNew
                          dataResult={selectedTemplateDetails?.medication}
                          highlightRow={false}
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}

          {selectedTab === 1 && (
            <div>
              <NewEmrTemplate
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
                setSelectedTab={setSelectedTab}
              />
            </div>
          )}
        </div>
      </Box>
    </Modal>
  );
}

export default EmrTemplate;


