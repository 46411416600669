import React, { useEffect, useRef } from "react";
import { InamdarHospiLogo } from "../../assets/printAssets/InmadarHospiLogo";
import { GeneratePrint } from "../../common/components/printModal/GeneratePrint";
import { OperationDetailsPrintData } from "../services/otDetailsServices/OtDetailsServices";

export default function OtDetailsPrint(props) {
  const printRef = useRef(null);
  const [dataResult, setDataResult] = React.useState(null);

  React.useEffect(() => {
    OperationDetailsPrintData(props?.otDetailsId?.OTHeaderID)
      .then((response) => response.data)
      .then((res) => {
        setDataResult(res.result);
      });
  }, [props?.otDetailsId]);

  useEffect(() => {
    dataResult && printRef && GeneratePrint(printRef);
  }, [dataResult]);

  return (
    <div ref={printRef}>
      <table className="w-full">
        <thead
          className=" w-full"
          style={{
            "-fs-table-paginate": "paginate",
          }}
        >
          <div className=" text-center -mt-11">
            <div className=" flex justify-end">
              <InamdarHospiLogo />
            </div>
            <div className="-mt-16">
              <h1 className=" font-bold text-[17px]">
                Inamdar Multispeciality Hospital
              </h1>
              <h1 className=" text-[14px] ">
                Sr. No 15, Fatimanagar, Behind Kpct Mall Pune - 411 040.
              </h1>
              <h1 className=" font-bold text-[17px] border-t-[1px] border-black">
                Operation Details
              </h1>
            </div>
          </div>
          <div>
            <div className=" p-1 border-t-[1px] border-black grid grid-cols-[18%_1%_31%_14%_1%_35%]">
              <div className=" font-semibold text-[14px]">Patient Name</div>
              <div className="font-semibold text-[14px]">:</div>
              <div className="text-[14px]">{dataResult?.PatientName}</div>
              <div className=" font-semibold text-[14px]">Age:</div>
              <div className="font-semibold text-[14px]">:</div>
              <div className="text-[14px]">{dataResult?.AgeYear}</div>
              <div className=" font-semibold text-[14px]">Date</div>
              <div className="font-semibold text-[14px]">:</div>
              <div className="text-[14px]">{dataResult?.Date}</div>
              <div className=" font-semibold text-[14px]">Operation No</div>
              <div className="font-semibold text-[14px]">:</div>
              <div className="text-[14px]">{dataResult?.OTSheetNo}</div>
              <div className=" font-semibold text-[14px]">Patient Category</div>
              <div className="font-semibold text-[14px]">:</div>
              <div className="text-[14px]">{dataResult?.PatientCategory}</div>
              <div className=" font-semibold text-[14px]">Bed No</div>
              <div className="font-semibold text-[14px]">:</div>
              <div className="text-[14px]">{dataResult?.BedNo}</div>
              <div className=" text-[14px] font-semibold">Reg. No</div>
              <div className=" text-[14px] font-semibold">:</div>
              <div className=" text-[14px]">{dataResult?.MRNo}</div>
            </div>
          </div>
        </thead>
        <tbody className="w-full">
          <div className="border-dashed border-t-[1px] border-b-[1px] border-black grid grid-cols-[14%_1%_35%_14%_1%_35%] p-1">
            <div className=" text-[14px] font-semibold">Theatre Name</div>
            <div className=" text-[14px] font-semibold">:</div>
            <div className=" text-[14px]">{dataResult?.TheatorName}</div>
            <div className=" text-[14px] font-semibold">Start Time</div>
            <div className=" text-[14px] font-semibold">:</div>
            <div className=" text-[14px]">{dataResult?.StartTime}</div>
            <div className=" text-[14px] font-semibold">Anaesthesia</div>
            <div className=" text-[14px] font-semibold">:</div>
            <div className=" text-[14px]">{dataResult?.AnesthesiaRecord}</div>
            <div className=" text-[14px] font-semibold">End Time</div>
            <div className=" text-[14px] font-semibold">:</div>
            <div className=" text-[14px]">{dataResult?.EndTime}</div>
            <div className=" text-[14px] font-semibold">Category</div>
            <div className=" text-[14px] font-semibold">:</div>
            <div className=" text-[14px]">{dataResult?.otCategory}</div>
            <div className=" grid grid-cols-[14%_1%_85%] text-[14px] col-span-6">
              <div className=" font-semibold">Remark</div>
              <div>:</div>
              <div>{dataResult?.Remark}</div>
            </div>
          </div>
          <div>
            <h1 className=" text-[14px] font-semibold">Doctors</h1>
            <div className="grid grid-cols-[14%_1%_85%] my-2 col-span-6 text-[14px]">
              <h1 className=" font-semibold">Surgeon</h1>
              <h1>:</h1>
              <h1>
                {dataResult?.docData?.map((data) => {
                  return <h1>{data?.["DoctorName"]}</h1>;
                })}
              </h1>
            </div>
            <div className="grid grid-cols-[14%_1%_85%] col-span-6 text-[14px]">
              <h1 className=" font-semibold">Anesthestist</h1>
              <h1>:</h1>
              <h1>Anesthestist</h1>
            </div>
            <div className="grid grid-cols-[14%_1%_85%] col-span-6 text-[14px]">
              <h1 className=" font-semibold">Surgeon</h1>
              <h1>:</h1>
              <h1>Surgeon</h1>
            </div>
            <div className="grid grid-cols-[14%_1%_85%] col-span-6 text-[14px]">
              <h1 className=" font-semibold">Asst. Surgeon Charges</h1>
              <h1>:</h1>
              <h1>Asst. Surgeon Charges</h1>
            </div>
            <div className=" mx-5 my-5 font-semibold text-[14px]">
              <h1>Surgery Notes :</h1>
            </div>
            <div className=" text-[14px] pl-5 border-black border-b-[1px]">
              <div>
                {dataResult?.AnesthesiaRecord.split(",").map((data) => (
                  <div>{data}</div>
                ))}
              </div>

              {/*  */}
            </div>
          </div>
        </tbody>
      </table>
    </div>
  );
}
