import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import * as React from "react";
import CreateClinicalModal from "./CreateClinicalModal";
// import { getSavedClinicalFindings } from "./Services";
import { TextareaAutosize } from "@mui/material";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import { ForceCloseIcon } from "../../../assets/CommonAssets";

const ModalStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  maxHeight: "90%",
  width: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 20,
  borderRadius: "10px",
  overflowY: "visible",
  p: 1,
};

export default function ClinicalModal(props) {
  const { open, close, setSelectedClinical, selectedClinical } = props;
  const [findings, setFindings] = React.useState([]);
  const [openAddNew, setOpenAddNew] = React.useState(false);
  const [templates, setTemplates] = React.useState([]);

  const handleCloseAddNew = () => {
    if (openAddNew === true) {
      setOpenAddNew(false);
    }
  };
  const [selectedTemplate, setSelectedTemplate] = React.useState(null);

  const populateTable = () => {
    // getSavedClinicalFindings()
    //   .then((response) => response.data)
    //   .then((res) => {
    //     console.log("The result of saved findings are :", res?.result);
    //     let findingsArray = res?.result;
    //     let newFindings = [];

    //     if (findingsArray?.length > 0) {
    //       for (let object of findingsArray) {
    //         let findingObject = {
    //           templateDescription: object?.templateDescription,
    //           Template: object?.template,
    //           isChecked: false,
    //           id: object?.id,
    //         };
    //         newFindings.push(findingObject);
    //         setFindings(newFindings);
    //       }
    //     }
    //   })
    //   .catch((err) => err);
  };

  React.useEffect(() => {
    let templatesArray = [];
    if (findings?.length > 0) {
      let filtered = findings.filter((finding) => finding?.isChecked === true);
      if (filtered?.length > 0) {
        for (let template of filtered) {
          templatesArray.push(template.templateDescription);
          setTemplates(templatesArray);
        }
      } else {
        setTemplates([]);
      }
    }
  }, [findings]);

  React.useEffect(() => {
    let templateString = "";
    if (templates?.length > 0) {
      for (let temp of templates) {
        templateString = templateString + temp + "\n";
        setSelectedTemplate(templateString);
      }
    } else {
      setSelectedTemplate("");
    }
  }, [templates]);

  React.useEffect(() => {
    populateTable();
  }, [open]);

  const handleAddFinding = () => {
    let dataArray = [...findings];
    let filtered = dataArray.filter((item) => item.isChecked === true);
    setSelectedClinical([...filtered]);
    close();
  };


  return (
    <div>
      <div>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={ModalStyles}>
            <div className="flex justify-between">
              <div className=" ">
                <div className="flex justify-center font-bold text-xl my-2 px-3">
                  Clinical Findings
                </div>
              </div>
              <div className="flex items-center">
                <CommonButton
                  label={"Add New"}
                  type={"button"}
                  className={
                    "border border-customBlue text-customBlue px-3 h-9 rounded mr-5"
                  }
                  onClick={() => {
                    setOpenAddNew(true);
                  }}
                />
                <button
                  onClick={() => {
                    close();
                  }}
                  type="button"
                >
                  <ForceCloseIcon />
                </button>
              </div>
            </div>
            {findings?.length > 0 ? (
              <>
                <div className="my-2 flex gap-2 items-center">
                  <div className="w-[35%] flex justify-center items-center font-semibold px-2 py-1 border border-gray-200 rounded">
                    Template Name
                  </div>
                  <div className="w-[65%] flex justify-center items-center font-semibold px-2 py-1 border border-gray-200 rounded">
                    Template Desc.
                  </div>
                </div>
                <div className="flex gap-2 ">
                  <div className="w-[35%] border rounded ">
                    {findings?.length > 0
                      ? findings.map((template, index) => {
                          return (
                            <div
                              onClick={() => {
                                let dataArray = [...findings];
                                dataArray[index].isChecked =
                                  !dataArray[index].isChecked;
                                setFindings(dataArray);
                              }}
                              className={`font-semibold border border-gray-200 px-2 py-1 ${
                                template.isChecked === true
                                  ? `bg-green-200`
                                  : ``
                              }`}
                            >
                              {template?.Template}
                            </div>
                          );
                        })
                      : ""}
                  </div>
                  <div className="w-[65%] border rounded ">
                    <TextareaAutosize
                      value={selectedTemplate}
                      // minRows={2}
                      className="w-full px-2"
                      disabled
                    />
                  </div>
                  <div>{}</div>
                </div>
              </>
            ) : (
              ""
            )}
            {findings?.length > 0 && (
              <div className="flex justify-end my-2 px-2">
                <CommonButton
                  label={"Add"}
                  type={"button"}
                  className={"px-3 bg-[#368505] text-white h-9 rounded"}
                  onClick={() => {
                    handleAddFinding();
                  }}
                />
              </div>
            )}
          </Box>
        </Modal>
        <CreateClinicalModal
          open={openAddNew}
          close={handleCloseAddNew}
          findings={findings}
          setFindings={setFindings}
          populateTable={populateTable}
        />
      </div>
    </div>
  );
}
