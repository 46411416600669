import { Modal, TextField } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";
import InputField from "../../../common/components/FormFields/InputField";
import { yupResolver } from "@hookform/resolvers/yup";
import DropdownField from "../../../common/components/FormFields/DropdownField";
import { DesktopTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import {
  fetchDepartment,
  fetchEmoloyeeName,
} from "../../../commonServices/miscellaneousServices/MiscServices";
import { format, isValid } from "date-fns";
import {
  fetchIncidentCategory,
  fetchIncidentDepartmentDetails,
  fetchIncidentReportingDetails,
  fetchStaffData,
  saveEmployeeIncidentDetails,
  saveIncidentReport,
} from "../../services/incidentReportingServices/IncidentReportingServices";
import {
  errorAlert,
  successAlert,
} from "../../../common/components/Toasts/CustomToasts";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import InputArea from "../../../common/components/FormFields/InputArea";
import CreateableSelect from "../../../common/components/FormFields/CreateableSelect";
import TimePickerFieldNew from "../../../common/components/FormFields/TimePickerFieldNew";
import { RemoveIcon, UploadIcon } from "../../../assets/CommonAssets";
import { baseUrl } from "../../../http-common";
const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95%",
  minHeight: "44%",
  maxHeight: "95%",
  bgcolor: "background.paper",
  borderRadius: 2,
  overflowY: "auto",
  border: "none",
  "&:focus": {
    outline: "none",
  },
};

// const validationSchema = yup.object().shape({
//   nameOfVictim: yup.string().required("Required"),
//   reportingPersonName: yup.string().required("Required"),
//   placeOfOccurrence: yup.string().required("Required"),
//   eventDescriptionDtls: yup.string().required("Required"),
// });

const incidentStatus = [
  {
    id: 0,
    label: "0 Forward to HR for action",
    value: "0 Forward to HR for action",
  },
  { id: 1, label: "Hold", value: "Hold" },
  { id: 2, label: "Incident not clear", value: "Incident not clear" },
  { id: 3, label: "Resolved", value: "Resolved" },
  {
    id: 4,
    label: "To meet and discuss with concerned",
    value: "To meet and discuss with concerned",
  },
  {
    id: 5,
    label: "Response pending from HOD",
    value: "Response pending from HOD",
  },
];

export default function ResponsibleDeptModal(props) {
  const { handleClose, setSelectedRow, selectedRow, patchCAPA, populateTable } =
    props;
  const token = JSON.parse(localStorage.getItem("userInfo"));
  let isQuality = false;

  const [patchData, setPatchData] = useState(null);
  const [incidentCategoryArr, setIncidentCategoryArr] = useState([]);
  const [staffOptions, setStaffOptions] = useState([]);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [allFormData, setAllFormData] = useState(null);
  const [resDeptArr, setResDeptArr] = useState([]);
  const [images, setImages] = useState([]);
  const fileUploadRef = useRef();
  const [base64, setBase64] = useState("");

  const [capaPatchData, setCapaPatchData] = useState([]);

  const methods = useForm({
    mode: "onChange",
    // resolver: yupResolver(validationSchema),
    defaultValues: {
      rca: "",
      correctiveActions: "",
      preventiveActions: "",
      capaDate: new Date(),
      capaTime: new Date(),
      staffName: null,
    },
  });
  const {
    formState: { errors },
    control,
    setValue,
    register,
    watch,
    resetField,
  } = methods;

  let getCapaDate = watch("capaDate");
  let getCapaTime = watch("capaTime");


  useEffect(() => {
    getIncidentCategory();
    getStaffData();
  }, []);


  useEffect(() => {
    !!selectedRow?.IncidentId &&
      getIncidentReportingDetails(selectedRow.IncidentId);
  }, [selectedRow]);

  const getIncidentReportingDetails = (incidentId) => {
    fetchIncidentReportingDetails(incidentId)
      .then((response) => response.data)
      .then((res) => {
        setPatchData(res.result);
      });
  };

  const getIncidentCategory = () => {
    fetchIncidentCategory()
      .then((response) => response.data)
      .then((res) => {
        setIncidentCategoryArr(res.result);
      });
  };

  const getStaffData = () => {
    fetchStaffData()
      .then((response) => response.data)
      .then((res) => {
        setStaffOptions(res.result);
      });
  };

  useEffect(() => {
    setValue("correctiveActions", patchCAPA?.ca);
    setValue("preventiveActions", patchCAPA?.ca);
    // setValue("correctiveActions", patchCAPA?.ca);
  }, [patchCAPA]);

  const handleImageUpload = (e) => {
    const file = e?.target?.files[0];
    if (file) {
      // if (images.length >= 4) {
      //     warningAlert("You can only upload up to 4 images")
      //     return
      // }
      const reader = new FileReader();
      reader.onloadend = (event) => {
        const fileName = file.name;
        const base64 = event.target.result.split(",")[1];
        const showImage = event.target.result;
        let imgObj = {
          documentName: fileName,
          base64: base64,
          showImage: showImage,
        };
        setImages((prevImages) => [...prevImages, imgObj]);
      };

      reader.readAsDataURL(file);
    } else {
      console.log("No file selected");
    }
  };

  const fetchAndConvertToBase64 = async (documentPath) => {
    try {
      const fullUrl = `${baseUrl}/file/${documentPath}`;
      const response = await fetch(fullUrl, {
        // headers: { Accept: "image/*" },
      });
      const blob = await response.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error("Error converting to base64:", error);
      throw error;
    }
  };

  const handleDelete = (indexToDelete) => {
    setImages((prevImages) =>
      prevImages.filter((_, index) => index !== indexToDelete)
    );
  };
  const onSubmit = (data) => {
    setAllFormData(data);
    setOpenConfirmationModal(true);
  };

  const handleFormSubmit = (data) => {
    // let incidentTimeValid = isValid(data.incidentTime);
    setOpenConfirmationModal(false);

    setOpenBackdrop(true);

    const uploadDocuments = images
      ? images.map((doc) => ({
          documentBase64: doc?.base64,
          documentName: doc?.documentName,
        }))
      : [];

    const finalObj = {
      ca: data.correctiveActions ? data.correctiveActions : "",
      capaReceivedDate: data.capaDate
        ? format(data.capaDate, "yyyy-MM-dd'T'HH:mm:ss.sss'Z'")
        : null,
      capaReceivedTime: data.capaTime
        ? format(data.capaTime, "yyyy-MM-dd'T'HH:mm:ss.sss'Z'")
        : null,
      employeeId: token?.empId,
      incidentId: selectedRow?.IncidentId || 0,
      pa: data.preventiveActions,
      departmentId: token.departmentId,
      rca: data?.rca,
      staffId: data.staffName ? data.staffName.id : 0,
      staffName: data.staffName ? data?.staffName.label : "",
      incidentDocuments: uploadDocuments,
      incidentDeptId: capaPatchData?.incidentDeptId,
    };
    saveEmployeeIncidentDetails(finalObj)
      .then((response) => response.data)
      .then((res) => {
        if (res.statusCode === 200) {
          successAlert(res.message);
          setOpenBackdrop(false);
          methods.reset();
          setValue("incidentTime", new Date());
          setSelectedRow(null);
          handleClose();
          populateTable();
        }
      })
      .catch((res) => {
        errorAlert(res.message);
        setOpenConfirmationModal(false);
        setOpenBackdrop(false);
      });
  };

  const getIncidentDepartmentDetails = () => {
    // isQuality = token.userRoles.some((item) => item === "Quality");

    if (selectedRow !== null) {
      isQuality = token.department === "Quality";

      selectedRow?.IncidentId &&
        fetchIncidentDepartmentDetails(
          selectedRow?.IncidentId,
          isQuality ? 0 : token?.departmentId
          // 0
        )
          .then((response) => response.data)
          .then((res) => {
            setCapaPatchData(res.result[0]);
          });
    }
  };

  useEffect(() => {
    getIncidentDepartmentDetails();
  }, [selectedRow]);

  useEffect(() => {
    setValue("rca", capaPatchData.RCA ? capaPatchData?.RCA : "");
    setValue("correctiveActions", capaPatchData.CA ? capaPatchData?.CA : "");
    setValue("preventiveActions", capaPatchData.PA ? capaPatchData?.PA : "");
    setValue(
      "capaDate",
      capaPatchData.capaReceiveDate
        ? new Date(
            capaPatchData["capaReceiveDate"].split("/").reverse().join("-")
          )
        : null
    );
    setValue(
      "capaTime",
      capaPatchData?.capaReceiveTime
        ? new Date(capaPatchData.capaReceiveTime)
        : null
    );

    // setValue("incidentTime", `1900-01-01T${capaPatchData["capaReceiveTime"]}.000`);

    setValue(
      "staffName",
      capaPatchData.StaffName
        ? {
            id: capaPatchData?.StaffId,
            label: capaPatchData?.StaffName,
            value: capaPatchData?.StaffId,
          }
        : null
    );
    if (capaPatchData?.documents !== undefined) {
      let documents = JSON.parse(capaPatchData?.documents);

      const base64Results = [];
      const convertToBase64 = (filePath) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();

          reader.onloadend = () => {
            resolve(reader.result);
          };

          reader.onerror = (error) => {
            reject(error);
          };

          fetch(`${baseUrl}/file/${filePath}`)
            .then((response) => response.blob())
            .then((blob) => {
              reader.readAsDataURL(blob);
            })
            .catch(reject);
        });
      };

      const processDocuments = async () => {
        for (const doc of documents) {
          try {
            const base64 = await convertToBase64(doc.DocumentPath);

            base64Results.push({
              id: doc.IncidentDeptDtlId,
              showImage: `data:image/png;base64,${base64?.split(",")[1]}`,
              base64: base64?.split(",")[1],
              documentName: doc.DocumentPath?.split("/")[1],
            });

          } catch (error) {
            console.error("Error reading document:", error);
          }
        }
        setImages(base64Results);
      };

      // Call the async function
      processDocuments();
    }
  }, [capaPatchData, setValue]);

  return (
    <div>
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[100%]">
          <CancelPresentationIconButton
            onClick={() => {
              handleClose();
            }}
          />

          <div className="mx-3 mb-3 mt-2 items-center align-middle">
            <div className=" flex flex-wrap p-2 rounded  text-black font-semibold">
              Incident Details
            </div>

            <hr className="border mx-1  border-customBlue mb-2" />
            <div>
              <fieldset
                className="border border-gray-300 col-span-3 w-full
               text-left lg:px-2 md:px-5 rounded bg-gray-100 p-2  "
              >
                <div className="py-2 grid  grid-cols-2 2xl:grid-cols-4 gap-2 p-3 ">
                  <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
                    <span className=" font-semibold w-44">
                      Incident Created Dept.
                    </span>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="text-gray-700 font-normal">
                        {patchData && patchData.EmployeeDepartment}
                      </span>
                    </div>
                  </div>

                  <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
                    <span className=" font-semibold w-44">
                      Incident Created Person
                    </span>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="text-gray-700 font-normal">
                        {patchData && patchData.ReportPersonName}
                      </span>
                    </div>
                  </div>

                  <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
                    <span className=" font-semibold w-44">
                      Place Of Occurance
                    </span>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="text-gray-700 font-normal">
                        {patchData && patchData.OccurencePlace}
                      </span>
                    </div>
                  </div>

                  <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
                    <span className=" font-semibold w-44">
                      Incident Date/Time
                    </span>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="text-gray-700 font-normal">
                        {patchData && patchData.IncidentDateTime}
                      </span>
                    </div>
                  </div>

                  <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
                    <span className=" font-semibold w-44">Victim Name</span>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="text-gray-700 font-normal">
                        {patchData &&  patchData.VictimName}
                      </span>
                    </div>
                  </div>

                  <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
                    <span className=" font-semibold w-44">Description</span>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="text-gray-700 font-normal">
                        {patchData && patchData.EventDescription}
                      </span>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <form>
              <div className="grid md:grid-cols-3 lg:grid-cols-6 gap-4 mt-3">
                {/* <div className="md:col-span-2 lg:col-span-5">
                  <SearchDropdown
                    control={control}
                    placeholder="Responsible Departments"
                    dataArray={resDeptArr}
                    name="responsibleDepts"
                    searchIcon={true}
                    isClearable={true}
                    handleInputChange={(e) => getResponsibleDepts(e)}
                    isMulti={true}
                    inputRef={{
                      ...register("responsibleDepts", {
                        onChange: (e) => {
                          if (e.target.value !== null) {
                            //setSelectedPatient(e.target.value);
                          } else {
                            //setSelectedPatient("");
                            //methods.reset();
                          }
                        },
                      }),
                    }}
                  />
                </div>
                <div className="md:col-span-2 lg:col-span-6">
                  <InputField
                    name="reportingPersonName"
                    label="Reporting Person Name *"
                    variant="outlined"
                    error={errors.reportingPersonName}
                    control={control}
                    disabled={false}
                    inputProps={{ maxLength: 50 }}
                  />
                </div> */}

                <div className="md:col-span-3 lg:col-span-6">
                  <InputArea
                    control={control}
                    //{...register("otherDtls")}
                    name="rca"
                    label="Root Cause Analysis (RCA)"
                    placeholder="Root Cause Analysis (RCA)"
                    // disabled={patchCAPA !== null ? true : false}
                  />
                </div>

                <div className="md:col-span-3 lg:col-span-6">
                  <InputArea
                    control={control}
                    //{...register("otherDtls")}
                    name="correctiveActions"
                    label="Corrective Actions"
                    placeholder="Corrective Actions"
                    // disabled={patchCAPA !== null ? true : false}
                  />
                </div>
                <div className="md:col-span-3 lg:col-span-6">
                  <InputArea
                    control={control}
                    //{...register("otherDtls")}
                    name="preventiveActions"
                    label="Preventive Actions"
                    placeholder="Preventive Actions"
                    // disabled={patchCAPA !== null ? true : false}
                  />
                </div>
                <div>
                  <CreateableSelect
                    control={control}
                    name={"staffName"}
                    placeholder={"Staff Name"}
                    dataArray={staffOptions}
                  />
                </div>
                <div className="md:col-span-1 lg:col-span-1">
                  <DatePickerFieldNew
                    control={control}
                    name="capaDate"
                    label="CAPA Received Date"
                    // value={new Date()}
                    // disableFuture={true}
                    // disablePast={false}
                    inputFormat="dd-MM-yyyy"
                  />
                </div>

                <div className="md:col-span-1 lg:col-span-1">
                  <TimePickerFieldNew
                    control={control}
                    name="capaTime"
                    label="CAPA Received Time"
                    disableFuture={true}
                    disablePast={false}
                    inputFormat="hh:mm"
                  />
                </div>
                <div key={images} className="flex col-span-4  gap-x-2">
                  <input
                    ref={fileUploadRef}
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleImageUpload(e)}
                    style={{ display: "none" }}
                    id="file-input"
                  />

                  <div
                    className="flex gap-x-1 items-center justify-between font-bold  cursor-pointer"
                    onClick={() => fileUploadRef.current.click()}
                  >
                    <p className="text-customBlue underline pl-1">
                      Upload Image
                    </p>
                    {/* <UploadIcon type={"button"} /> */}
                  </div>

                  {images.length > 0 && (
                    <div className="flex gap-3">
                      {images.map((img, index) => {
                        return (
                          <div key={index} className="relative">
                            <img
                              src={
                                img.showImage !== undefined
                                  ? img.showImage
                                  : `${baseUrl}/file/${img?.DocumentPath}`
                              }
                              className="h-12 w-20 border-4 border-double"
                              alt=""
                            />
                            <div className="absolute -top-3 -right-3 p-1">
                              <button
                                type="button"
                                onClick={() => handleDelete(index)}
                              >
                                <RemoveIcon />
                              </button>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>

                <div className="md:col-span-2 lg:col-span-6 justify-end gap-3  flex ">
                  <CommonButton
                    label="Cancel"
                    className="border border-customRed text-customRed"
                    onClick={() => {
                      methods.reset();
                      setSelectedRow(null);
                      handleClose();
                    }}
                  />

                  <CommonButton
                    label="Save"
                    className="bg-customGreen text-white"
                    onClick={methods.handleSubmit(onSubmit)}
                  />
                </div>
              </div>
            </form>
          </div>
        </Box>
      </Modal>

      <CommonBackDrop openBackdrop={openBackdrop} />
      {/* //Confirmation Modal// */}
      <ConfirmationModal
        confirmationOpen={openConfirmationModal}
        confirmationHandleClose={() => {
          setOpenConfirmationModal(false);
        }}
        confirmationSubmitFunc={() => {
          handleFormSubmit(allFormData);
        }}
        confirmationLabel="Confirmation "
        confirmationMsg="Are You Sure ?"
        confirmationButtonMsg="Proceed"
      />
    </div>
  );
}

