import {
  Box,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import CheckBoxField from "../../common/components/FormFields/CheckBoxField";
import { getSalesItemDetails } from "./Services";
import { useForm } from "react-hook-form";
import { warningAlert } from "../../common/components/Toasts/CustomToasts";

function SalesDetailsListTable(props) {
  const { control, watch, setValue } = useForm();

  const removeHeaders = (headers, fieldToRemove) => {
    return headers.filter((v) => {
      return !fieldToRemove.includes(v);
    });
  };

  //set rows object to table
  const allHeaders = Object.keys(props.data.result[0]);

  const headers = removeHeaders(allHeaders, [
    "Id",
    "isSelected",
    "Net Amt",
    "totalAmount",
    "billId",
    "isSelected",
    "Expiry Date",
  ]);

  const handleSelectItems = (row, index, isChecked) => {
    let rowData = row;
    let arr = props.data.result;

    rowData.isSelected = isChecked;
    arr[index] = rowData;
  };

  const handleGetReturnItems = () => {
    console.log("aaaaaaaaaaaaaaaaaaaaaaaaa", props.data.result);
    let arr = [];
    props.setReturnItems([]);
    for (let object of props.data.result) {
      if (object?.["isSelected"] === true) {
        object.returnQuantity = 0;
        arr.push(object);
        props.setReturnItems(arr);
      }
    }
    props.close();
    props.setCounterSalesList({ statusCode: 300, result: [] });
    props.setSelectedSalesId({ statusCode: 300, result: [] });
    props.setSelectedFromDate(new Date());
    props.setSelectedToDate(new Date());
    props.handleFilter();
    props.setResetCount(props.resetCount + 1);
  };

  console.log("props.data?.result", props.data?.result);

  return (
    <>
      <div className="w-auto grid">
        <Box sx={{ width: "100%", overflow: "hidden" }}>
          <Paper sx={{ width: "100%", my: 2 }}>
            <TableContainer
              sx={{
                "&::-webkit-scrollbar": {
                  width: 7,
                  height: 10,
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#7393B3",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "lightBlue",
                },
              }}
              className="rounded max-h-60"
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow
                    sx={{
                      "& th": {
                        paddingY: 0.5,
                        backgroundColor: "#F1F1F1",
                      },
                    }}
                  >
                    {props.component === "return" ? (
                      <TableCell>
                        <span className="text-gray-600 font-bold">Select</span>
                      </TableCell>
                    ) : (
                      ""
                    )}
                    {headers?.map((header, index) => (
                      <TableCell className="whitespace-nowrap" key={index}>
                        <span className="text-gray-600 font-bold">
                          {header}
                        </span>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {props.data?.result?.map((row, index) => {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          "& td": {
                            paddingY: props.component === "return" ? 0.1 : 1,
                          },
                        }}
                        onClick={() => {
                          if (row["Qty Available For Return"] === 0) {
                            warningAlert("No Quantity Available For Return.");
                          }
                        }}
                      >
                        {props.component === "return" ? (
                          <TableCell>
                            <fieldset
                              onChange={(e) => {
                                handleSelectItems(row, index, e.target.checked);
                              }}
                              disabled={row["Qty Available For Return"] === 0}
                            >
                              <CheckBoxField
                                control={control}
                                name={`select${row["SalesDetailId"]}`}
                                size="small"
                              />
                            </fieldset>
                          </TableCell>
                        ) : (
                          ""
                        )}
                        {headers &&
                          headers.map((header, i) => (
                            <TableCell
                              className="whitespace-nowrap capitalize"
                              key={i}
                            >
                              {row[header]}
                            </TableCell>
                          ))}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
        {props.component === "return" ? (
          <div className="flex justify-end">
            <button
              className="h-9 px-2 bg-customBlue text-white rounded"
              onClick={handleGetReturnItems}
              // disabled={
              //   props.data?.result?.filter((item) => item?.isSelected === true)
              //     ?.length === 0
              // }
            >
              Get Return Items
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default SalesDetailsListTable;
