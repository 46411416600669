import authHeader from "../../../authentication/authservices/auth-header";
// import { mastersApi, opdApi } from "../../../http-common";

import apiClient from "../../../http-common";

// export const getInvestigations = (searchString, unitId, departmentId) => {
//   return mastersApi.get(
//     `/tests/opdinvestigations?departmentId=${departmentId}&searchString=${searchString}&testTypeId=3&unitId=${unitId}`,
//     {
//       headers: authHeader(),
//     }
//   );
// };

export const getMedicines = (searchObject) => {
  return apiClient.get(`/drugAdministrator/drugName/${searchObject}`, {
    headers: authHeader(),
  });
};

export const getGeneralFrequencies = (adminAs) => {
  return apiClient.get(`drugAdministrator/getFrequency/${adminAs}`, {
    headers: authHeader(),
  });
};

export const getCommonRoutes = () => {
  return apiClient.get(`/dischargeSummary/drugRoute`, {
    headers: authHeader(),
  });
};

export const getOtherServices = (searchString) => {
  return apiClient.get(`/billEstimation/procedureService/${searchString}`, {
    headers: authHeader(),
  });
};

export const saveEmrLite = (postObject) => {
  return apiClient.post(`/emr/saveEmr`, postObject, {
    headers: authHeader(),
  });
};

// export const updateEmrLite = (updateObject) => {
//   return opdApi.post(`/patientemr`, updateObject, {
//     headers: authHeader(),
//   });
// };

// export const getCommonDiagnosis = () => {
//   return mastersApi.get(`/icdCode/getCommonIcdCode`, {
//     headers: authHeader(),
//   });
// };

// export const getAssessmentDetails = (searchObject) => {
//   return opdApi.post(`/patientemr/getPatientAssessmentDetails`, searchObject, {
//     headers: authHeader(),
//   });
// };

// export const getPreviousPrescriptions = (patientId) => {
//   return opdApi.get(
//     `/patientemr/getPreviousPrescription?patientId=${patientId}`,
//     {
//       headers: authHeader(),
//     }
//   );
// };

// export const saveAdvice = (postObject) => {
//   return mastersApi.post(`/emrAdvice`, postObject, {
//     headers: authHeader(),
//   });
// };

// export const getSavedAdvices = () => {
//   return mastersApi.get(`/emrAdvice`, {
//     headers: authHeader(),
//   });
// };

// export const postClinicalFindings = (postObject) => {
//   return mastersApi.post(`/clinicalFindings`, postObject, {
//     headers: authHeader(),
//   });
// };

// export const getSavedClinicalFindings = () => {
//   return mastersApi.get(`/clinicalFindings`, {
//     headers: authHeader(),
//   });
// };

// export const getLocalAdvices = () => {
//   return mastersApi.get(`/generalInstruction/getLocalInstructions`, {
//     headers: authHeader(),
//   });
// };

// export const getCommonComplaints = () => {
//   return mastersApi.get(`/complaints/getCommonComplaint`, {
//     headers: authHeader(),
//   });
// };

export const getGenericDrugs = (searchObject) => {
  return apiClient.get(`/dischargeSummary/drugGenericName/${searchObject}`, {
    headers: authHeader(),
  });
};

export const getMedicationInstructions = () => {
  return apiClient.get(`/dischargeSummary/drugInstruction`, {
    headers: authHeader(),
  });
};

export const getInvestigations = (searchObject, tariffId) => {
  return apiClient.get(
    `/diagnosis/getInvestigations/${searchObject}/${tariffId}`,
    {
      headers: authHeader(),
    }
  );
};

export const getDiagnosis = (searchObject) => {
  return apiClient.get(`/diagnosis/getDiagnosis/${searchObject}`, {
    headers: authHeader(),
  });
};

export const getReferenceDoctor = (searchObject) => {
  return apiClient.get(`/misc/referenceDoctor/${searchObject}`, {
    headers: authHeader(),
  });
};

export const getDrugForm = () => {
  return apiClient.get(`dischargeSummary/drugForm`, {
    headers: authHeader(),
  });
};

export const getPatientEmrDetails = (
  opdIpd,
  opdIpdId,
  doctorId,
  isPreAssessment
) => {
  return apiClient.get(
    `/emr/getPatientEmrDetails/${opdIpd}/${opdIpdId}/${doctorId}/${isPreAssessment}`,{
      headers: authHeader(),
    }
  );
};

export const getPrevEmrList = (opdIpd, patientId) => {
  return apiClient.get(`/emr/getPatientEmrDetailsList/${opdIpd}/${patientId}`, {
    headers: authHeader(),
  });
};

export const getAdviceData = () => {
  return apiClient.get("/dischargeSummary/dischargeAdvice", {
    headers: authHeader(),
  });
};

export const getComplaintsData = (searchString) => {
  return apiClient.get(`/complaints_master/getComplaints/${searchString}`, {
    headers: authHeader(),
  });
};

export const getAllergiesData = (searchString) => {
  return apiClient.get(`/allergies_master/getAllergies/${searchString}`, {
    headers: authHeader(),
  });
};
