import React, { useEffect, useRef } from "react";
import { InamdarHospiLogo } from "../../assets/printAssets/InmadarHospiLogo";
import { GeneratePrint } from "../../common/components/printModal/GeneratePrint";
import { OtConsentPrintData } from "../services/otConsentServices/OtConsentServices ";

export default function OtConsentPrint(props) {
  const { consentCode } = props;
  let printRef = useRef(null);

  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    OtConsentPrintData(props?.otConsentId)
      .then((response) => response.data)
      .then((res) => {
        console.log("resresres", res);

        setData(res.result);
      });
  }, [props?.otConsentId]);

  const DynamicHTMLRenderer = ({ htmlContent }) => {
    return (
      <div
        className="break-words"
        dangerouslySetInnerHTML={{ __html: htmlContent }}
      />
    );
  };

  useEffect(() => {
    data !== null && printRef && GeneratePrint(printRef);
  }, [data]);

  console.log("datadatadata321", data);

  return (
    <div ref={printRef} id="getPrint">
      <table className="w-full">
        <thead
          style={{
            "-fs-table-paginate": "paginate",
          }}
        >
          <div className="flex items-center relative">
            <div className="text-center w-full ">
              <h1 className="text-lg font-semibold">
                Inamdar Multispeciality Hospital
              </h1>
              <h1 className="">
                Sr. No 15, Fatimanagar, Behind Kpct Mall Pune - 411 040
              </h1>
            </div>
            <div className="absolute top-0 right-0 -mt-11">
              <InamdarHospiLogo />
            </div>
          </div>
          <div className=" border-t-[1px] border-b-[1px] font-semibold text-[15px] border-black text-center">
            OT CONSENT
          </div>
          <div className="grid grid-cols-2 text-[13px]">
            <div className="flex items-center">
              <label className="font-semibold w-28">Patient Name</label>
              <label>&nbsp;:&nbsp;{data?.PatientName}</label>
            </div>
            <div className="flex items-center">
              <label className="font-semibold w-24">MR No.</label>
              <label>&nbsp;:&nbsp;{data?.MRNo}</label>
            </div>
            <div className="flex items-center">
              <label className="font-semibold w-28">Gender</label>
              <label>&nbsp;:&nbsp;{data?.Gender}</label>
            </div>
            <div className="flex items-center">
              <label className="font-semibold w-24">Consent Date</label>
              <label>&nbsp;:&nbsp;{data?.ConsentDate}</label>
            </div>
            <div className="flex items-center">
              <label className="font-semibold w-28">Admission Date</label>
              <label>&nbsp;:&nbsp;{data?.AdmissionDate}</label>
            </div>
            <div className="flex items-center">
              <label className="font-semibold w-24">Adviced By</label>
              <label>
                &nbsp;:&nbsp;{data?.AdvicedBy ? data?.AdvicedBy : "-"}
              </label>
            </div>
            <div className="flex items-center">
              <label className="font-semibold w-28">Age</label>
              <label>&nbsp;:&nbsp;{data?.AgeYear}</label>
            </div>
          </div>
          <div className="border-t-[1px] border-b-[1px] font-semibold p-1 text-[13px] border-black text-center">
            {data?.TemplateName}
          </div>
        </thead>
        <tbody>
          <tr>
            <DynamicHTMLRenderer htmlContent={data?.TempalteDescription} />
          </tr>
        </tbody>
        <tfoot
          style={{
            "-fs-table-paginate": "paginate",
          }}
        >
          {consentCode !== null && (
            <div>Consent Code :&nbsp;{consentCode} </div>
          )}
        </tfoot>
      </table>
    </div>
  );
}
