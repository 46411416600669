import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import InputField from "../../../common/components/FormFields/InputField";
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";
import { Box, Modal } from "@mui/material";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import { getPatientEmrDetails, saveEmrLite } from "../EmrLite/Services";
import {
  errorAlert,
  successAlert,
} from "../../../common/components/Toasts/CustomToasts";

const ModalStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  maxHeight: "90%",
  width: "60%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 20,
  borderRadius: "10px",
  overflowY: "auto",
  p: 1,
};

function PreAssessmentModal({ open, close, selectedPreAssessment }) {
  let token = JSON.parse(localStorage.getItem("userInfo"));
  const [formData, setFormData] = useState([]);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [patchData, setPatchData] = useState(null);
  const defaultValues = {
    height: "",
    weight: "",
    bmi: "",
    bp: "",
    pulse: "",
    bsl: "",
    respiration: "",
    temperature: "",
    spo2: "",
  };

  const {
    watch,
    setValue,
    control,
    trigger,
    register,
    setError,
    clearErrors,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
  });

  const weightWatch = watch("weight");
  const heightWatch = watch("height");


  React.useEffect(() => {
    const weightValue = parseFloat(Number(weightWatch));
    const heightValue = parseFloat(Number(heightWatch));
    let heightINCM = heightValue * 0.01;
    if (Number(heightWatch) > 0 && Number(weightWatch) > 0) {
      const bmiValue = weightValue / (heightINCM * heightINCM);
      setValue("bmi", bmiValue.toFixed(2));
    } else {
      setValue("bmi", "");
    }
  }, [heightWatch, weightWatch]);

  const onSubmit = (data) => {
    const tempObj = {
      id: patchData ? patchData.id : null,
      doctorId: selectedPreAssessment?.["Doctor Id"],
      opdIpd: 0,
      opdIpdId: selectedPreAssessment?.VisitId,
      temp: data?.temperature,
      bp: data?.bp,
      pulse: Number(data?.pulse),
      respiration: data?.respiration,
      spo2: data?.spo2,
      bsl: data?.bsl,
      height: data?.height,
      weight: data?.weight,
      bmi: Number(data?.bmi),
      importantNotes: "",
      smoking: null,
      tobacco: null,
      alcohol: null,
      pastHistory: [],
      familyHistory: [],
      emrComplaints: [],
      allergies: [],
      investigations: [],
      emrDiagnosis: [],
      emrMedicationsRequestDtoList: [],
      advices: [],
      otherReferral: [],
      otherServices: [],
      newFollowupDate: null,
    };
    setFormData(tempObj);
    setOpenConfirmationModal(true);
  };

  const handleFormSubmit = () => {
    setOpenBackdrop(true);
    saveEmrLite(formData)
      .then((response) => response.data)
      .then((res) => {
        setOpenBackdrop(false);
        successAlert("Assessment Added Successfully");
        close();
      })
      .catch((err) => {
        setOpenBackdrop(false);
        errorAlert(err?.message);
      });
  };

  useEffect(() => {
    if (
      selectedPreAssessment.VisitId !== null ||
      selectedPreAssessment?.VisitId !== undefined
    ) {
      getPatientEmrDetails(
        0,
        selectedPreAssessment?.VisitId,
        selectedPreAssessment?.["Doctor Id"],
        true
      )
        .then((response) => response.data)
        .then((res) => {
          setPatchData(res?.result[0]);
        })
        .catch((err) => err);
    }
  }, []);

  useEffect(() => {
    if (patchData !== null) {
      setValue("temperature", patchData?.temp);
      setValue("bp", patchData?.bp);
      setValue("pulse", patchData?.pulse);
      setValue("respiration", patchData?.respiration);
      setValue("spo2", patchData?.spo2);
      setValue("bsl", patchData?.bsl);
      setValue("height", patchData?.height);
      setValue("weight", patchData?.weight);
      setValue("bmi", patchData?.bmi);
    }
  }, [patchData]);

  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyles} className="space-y-2">
          <div className="flex justify-between">
            <h2 className="font-bold text-xl my-2 px-3">Pre Assessment</h2>
            <button
              type="button"
              onClick={() => {
                close();
              }}
            >
              <CancelPresentationIconButton />
            </button>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className="space-y-3">
            <div className="px-2 col-span-2 grid md:grid-cols-3 xl:grid-cols-9 gap-2">
              <div>
                <InputField
                  control={control}
                  name="temperature"
                  label="Temp °F"
                  type="number"
                  error={errors.temperature}
                  inputRef={{
                    ...register("temperature", {
                      onChange: (e) => {
                        if (Number(e.target.value) > 200) {
                          setError("temperature", {
                            type: "custom",
                            message: "Required",
                          });
                        } else {
                          clearErrors("temperature");
                        }
                      },
                    }),
                  }}
                />
              </div>

              <div>
                <InputField
                  control={control}
                  name="bp"
                  error={errors?.bp}
                  // type="number"
                  label="BP mmHg"
                  inputRef={{
                    ...register("bp", {
                      onChange: (e) => {
                        if (Number(e.target.value) > 500) {
                          setError("bp", {
                            type: "custom",
                            message: "Required",
                          });
                        } else {
                          clearErrors("bp ");
                        }
                      },
                    }),
                  }}
                />
              </div>

              <div>
                <InputField
                  control={control}
                  name="pulse"
                  label="Pulse/min"
                  error={errors.pulse}
                  type="number"
                  inputRef={{
                    ...register("pulse", {
                      onChange: (e) => {
                        if (Number(e.target.value) > 220) {
                          setError("pulse", {
                            type: "custom",
                            message: "Required",
                          });
                        } else {
                          clearErrors("pulse");
                        }
                      },
                    }),
                  }}
                />
              </div>

              <div>
                <InputField
                  control={control}
                  name="respiration"
                  label={"Respiration"}
                  type={"number"}
                  error={errors.respiration}
                  inputRef={{
                    ...register("respiration", {
                      onChange: (e) => {
                        if (Number(e.target.value) > 100) {
                          setError("respiration", {
                            type: "custom",
                            message: "Required",
                          });
                        } else {
                          clearErrors("respiration");
                        }
                      },
                    }),
                  }}
                />
              </div>

              <div>
                <InputField
                  control={control}
                  name="spo2"
                  label="SpO2 %"
                  type="number"
                  error={errors.spo2}
                  inputRef={{
                    ...register("spo2", {
                      onChange: (e) => {
                        if (Number(e.target.value) > 200) {
                          setError("spo2", {
                            type: "custom",
                            message: "Required",
                          });
                        } else {
                          clearErrors("spo2");
                        }
                      },
                    }),
                  }}
                />
              </div>

              <div>
                <InputField
                  control={control}
                  name="bsl"
                  label={"BSL"}
                  type="number"
                  error={errors?.bsl}
                  inputRef={{
                    ...register("bsl", {
                      onChange: (e) => {
                        if (Number(e.target.value) > 300) {
                          setError("bsl", {
                            type: "custom",
                            message: "Required",
                          });
                        } else {
                          clearErrors("bsl");
                        }
                      },
                    }),
                  }}
                />
              </div>

              <div>
                <InputField
                  control={control}
                  name="height"
                  label="Height (cms)"
                  error={errors.height}
                  type="number"
                  inputRef={{
                    ...register("height", {
                      onChange: (e) => {
                        if (Number(e.target.value) > 300) {
                          setError("height", {
                            type: "custom",
                            message: "Required",
                          });
                        } else {
                          clearErrors("height");
                        }
                      },
                    }),
                  }}
                />
              </div>
              <div>
                <InputField
                  control={control}
                  name="weight"
                  label="Weight (kg)"
                  type="number"
                  error={errors.weight}
                  inputRef={{
                    ...register("weight", {
                      onChange: (e) => {
                        if (Number(e.target.value) > 300) {
                          setError("weight", {
                            type: "custom",
                            message: "Required",
                          });
                        } else {
                          clearErrors("weight ");
                        }
                      },
                    }),
                  }}
                />
              </div>
              <div>
                <InputField
                  control={control}
                  name="bmi"
                  label="BMI"
                  disabled={true}
                  type="number"
                />
              </div>
            </div>

            <div className="flex px-2 justify-end gap-2">
              <CommonButton
                type={"button"}
                label={"Reset"}
                className={"text-customRed border border-customRed "}
              />

              <CommonButton
                type={"submit"}
                label={`${patchData === null || patchData === undefined  ? "Save" : "Update"}`}
                className={"bg-customGreen text-white"}
              />
            </div>
          </form>
        </Box>
      </Modal>

      <CommonBackDrop openBackdrop={openBackdrop} />
      {/* //Confirmation Modal// */}

      {openConfirmationModal && (
        <ConfirmationModal
          confirmationOpen={openConfirmationModal}
          confirmationHandleClose={() => {
            setOpenConfirmationModal(false);
          }}
          confirmationSubmitFunc={() => {
            handleFormSubmit();
          }}
          confirmationLabel="Confirmation "
          confirmationMsg="Are You Sure ?"
          confirmationButtonMsg="Proceed"
        />
      )}
    </div>
  );
}

export default PreAssessmentModal;
