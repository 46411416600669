import authHeader from "../../../authentication/authservices/auth-header";
import apiClient from "../../../http-common";

//autocomplete
export const searchPatientInformation = (searchString, opdIpd) => {
  return apiClient.get(
    `/patientInfo/opdIpdAutoComplete/${searchString}/${opdIpd}`,
    {
      headers: authHeader(),
    }
  );
};

//patient Information
export const fetchPatientInformationById = (patientId, opdIpd) => {
  //
  return apiClient.get(`/patientInfo/bill/${patientId}/${opdIpd}`, {
    headers: authHeader(),
  });
};

//Templete List
export const fetchTempleteList = (patientId, opdIpd) => {
  //
  return apiClient.get(`/otConsent/otConsentList/${patientId}/${opdIpd}`, {
    headers: authHeader(),
  });
};

//Department For Consent
export const fetchDepartmentForConsent = () => {
  //
  return apiClient.get(`/otConsent/departmentForConsent`, {
    headers: authHeader(),
  });
};

//Department Consent Templates
export const fetchDepartmentConsentTemplates = (departmentId) => {
  //
  return apiClient.get(`/otConsent/consentTemplates/${departmentId}`, {
    headers: authHeader(),
  });
};

//Department Consent Templates
export const postSaveOTConsent = (finalObj) => {
  //
  return apiClient.post(`/otConsent/saveOTConsent`, finalObj, {
    headers: authHeader(),
  });
};

export const OtConsentPrintData = (consentId) => {
  return apiClient.get(`/reports/ot/otConsent/${consentId}`, {
    headers: authHeader(),
    // responseType: "blob",
  });
};
