import { TextField } from "@mui/material";

import { differenceInMinutes } from "date-fns";
import React, { useContext, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import DatePickerFieldNew from "../../../../common/components/FormFields/DatePickerFieldNew";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import InputArea from "../../../../common/components/FormFields/InputArea";
import InputField from "../../../../common/components/FormFields/InputField";
import RadioField from "../../../../common/components/FormFields/RadioField";
import SearchDropdown from "../../../../common/components/FormFields/searchDropdown";
import TimePickerFieldNew from "../../../../common/components/FormFields/TimePickerFieldNew";
import { fetchDepartment } from "../../../../commonServices/miscellaneousServices/MiscServices";
import {
  fetchAneas,
  fetchOTCategory,
  fetchOtNurse,
  fetchOtPhysician,
  fetchOtResult,
  fetchOtStatus,
  fetchProcedureType,
  fetchTheater,
} from "../../../services/otDetailsServices/OtDetailsServices";
import AntibioticGiven from "./AntibioticGiven";
import { OtDetailsContext } from "./OtDetails";

const otCaseTypeArr = [
  { id: 0, label: "Scheduled", value: "Scheduled" },
  { id: 1, label: "Emergency", value: "Emergency" },
];

const OtSheetDetails = () => {
  const { employeeNameData } = useContext(OtDetailsContext);

  const {
    control,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const [theater, setTheater] = useState(null);
  const [aneas, setAneas] = useState();
  const [procedureType, setProcedureType] = useState();
  const [otResult, setOtResult] = useState();
  const [otNurse, setOtNurse] = useState();
  const [otPhysician, setOtPhysician] = useState();
  const [oTCategory, setOTCategory] = useState();
  const [otType, setOtType] = useState();
  const [otTypetSearchString, setOtTypeSearchString] = useState();
  const [otStatus, setOtStatus] = useState();

  let caseType = watch("otCaseType");
  let start = watch("startTime");
  let end = watch("endTime");
  let actual_start = watch("actualStartTime");
  let actual_end = watch("actualEndTime");
  let totalTime = watch("totalTime");
  let actual_totalTime = watch("actualTotalTime");

  useEffect(() => {
    // Theater
    fetchTheater()
      .then((response) => response.data)
      .then((res) => {
        //
        setTheater(res.result);
      });
    // Aneas
    fetchAneas()
      .then((response) => response.data)
      .then((res) => {
        //
        setAneas(res.result);
      });
    // ProcedureType
    fetchProcedureType()
      .then((response) => response.data)
      .then((res) => {
        //
        setProcedureType(res.result);
      });
    // OtResult
    fetchOtResult()
      .then((response) => response.data)
      .then((res) => {
        //
        setOtResult(res.result);
      });
    // OtResult
    fetchOtStatus()
      .then((response) => response.data)
      .then((res) => {
        //
        setOtStatus(res.result);
      });
    // OtNurse
    fetchOtNurse()
      .then((response) => response.data)
      .then((res) => {
        //
        setOtNurse(res.result);
      });
    // OtPhysician
    fetchOtPhysician()
      .then((response) => response.data)
      .then((res) => {
        //
        setOtPhysician(res.result);
      });
    // OTCategory
    fetchOTCategory()
      .then((response) => response.data)
      .then((res) => {
        //
        setOTCategory(res.result);
      });
  }, []);

  // department as otType
  useEffect(() => {
    otTypetSearchString &&
      fetchDepartment(otTypetSearchString).then((response) => {
        setOtType(response?.data?.result);
      });
  }, [otTypetSearchString]);

  useEffect(() => {
    let minutes = differenceInMinutes(new Date(end), new Date(start));
    let actual_minutes = differenceInMinutes(
      new Date(actual_end),
      new Date(actual_start)
    );

    setValue("totalTime", minutes);
    setValue("actualTotalTime", actual_minutes);
  }, [start, end, actual_start, actual_end]);

  return (
    <>
      <div className="grid  gap-3 mt-2">
        {Number(caseType) < 1 && (
          <>
            <div className="lg:col-span-5 md:col-span-3   grid lg:grid-cols-6 md:grid-cols-3 gap-2">
              <div className="font-semibold text-center">
                Scheduled OT Duration
              </div>
              <div>
                <DatePickerFieldNew
                  control={control}
                  name="operationDate"
                  label="Date"
                  value={new Date()}
                  size="small"
                  disabled={true}
                  inputFormat="dd-MM-yyyy"
                />
              </div>
              <div>
                <TimePickerFieldNew
                  control={control}
                  name="startTime"
                  label="Start Time"
                  value={new Date()}
                  disabled={true}
                />
              </div>
              <div>
                <TimePickerFieldNew
                  control={control}
                  name="endTime"
                  label="End Time"
                  value={new Date()}
                  disabled={true}
                />
              </div>

              <div>
                <InputField
                  name="totalTime"
                  variant="outlined"
                  label="Total Minutes"
                  error={errors.email}
                  control={control}
                  inputProps={{ maxLength: 100 }}
                  disabled={true}
                />
              </div>
            </div>
            <hr className="lg:col-span-5 md:col-span-6 border my-1 divide-x-8 bg-slate-300 border-slate-300" />
          </>
        )}
        <div className="lg:col-span-5 md:col-span-3   grid lg:grid-cols-6 md:grid-cols-3 gap-2">
          <div className="font-semibold text-center">Actual OT Duration</div>
          <div>
            <DatePickerFieldNew
              control={control}
              name="actualOperationDate"
              label="Date"
              value={new Date()}
              size="small"
              // disablePast={true}
              inputFormat="dd-MM-yyyy"
            />
          </div>
          <div>
            <TimePickerFieldNew
              control={control}
              name="actualStartTime"
              label="Start Time"
              value={new Date()}
            />
          </div>
          <div>
            <TimePickerFieldNew
              control={control}
              name="actualEndTime"
              label="End Time"
              value={new Date()}
            />
          </div>

          <div>
            <InputField
              name="actualTotalTime"
              variant="outlined"
              label="Total Minutes"
              error={errors.email}
              control={control}
              inputProps={{ maxLength: 100 }}
              disabled={true}
            />
          </div>

          {/* {totalTime !== actual_totalTime && ( */}
          <div>
            <InputArea
              name={"reasonOfOtDelay"}
              label={"Reason"}
              placeholder={"Reason"}
            />
          </div>
          {/* )} */}
        </div>

        <hr className="lg:col-span-5 md:col-span-3 border my-1 divide-x-8 bg-slate-300 border-slate-300" />

        <div className="flex space-x-3 lg:col-span-1 md:col-span-2">
          <label className="font-semibold whitespace-nowrap">
            Ot Case Type :
          </label>
          <RadioField
            dataArray={otCaseTypeArr}
            name="otCaseType"
            control={control}
          />
        </div>
        <div>
          <DropdownField
            control={control}
            error={errors.theater}
            name="theater"
            placeholder="Theater *"
            isSearchable={false}
            dataArray={theater}
          />
        </div>
        <div>
          <DropdownField
            control={control}
            name="anaes"
            placeholder="Anaes"
            isSearchable={false}
            dataArray={aneas}
          />
        </div>
        <div>
          <DropdownField
            control={control}
            name="procedureType"
            placeholder="Procedure Type"
            isSearchable={false}
            dataArray={procedureType}
          />
        </div>
        <div>
          <DropdownField
            control={control}
            name="otResule"
            placeholder="Ot Result"
            isSearchable={false}
            dataArray={otResult}
          />
        </div>
        <div>
          <DropdownField
            control={control}
            name="otStatus"
            placeholder="OT Status"
            isSearchable={false}
            dataArray={otStatus}
          />
        </div>
        <div>
          <DropdownField
            control={control}
            name="nurse"
            placeholder="Nurse"
            isSearchable={false}
            dataArray={otNurse}
          />
        </div>
        <div>
          <DropdownField
            control={control}
            name="physicion"
            placeholder="Physicion"
            isSearchable={false}
            dataArray={otPhysician}
          />
        </div>
        <div>
          <SearchDropdown
            control={control}
            error={errors.otType}
            name="otType"
            dataArray={otType}
            handleInputChange={(e) => {
              //
              setOtTypeSearchString(e);
            }}
            // inputRef={{
            //   ...register("department", {
            //     onChange: (e) => {
            //       if (e.target.value !== null) {
            //         setDepartmentId(e.target.value.id);
            //       }

            //       //
            //       resetField("doctor");
            //     },
            //   }),
            // }}
            isSearchable={true}
            placeholder="OT Type *"
            isClearable={true}
            searchIcon={true}
          />
        </div>
        <div>
          <DropdownField
            control={control}
            error={errors.otCategory}
            name="otCategory"
            placeholder="OT Category *"
            isSearchable={false}
            dataArray={oTCategory}
          />
        </div>
        <div>
          <InputField
            name="manPower"
            variant="outlined"
            label="Man Power"
            error={errors.email}
            control={control}
            inputProps={{ maxLength: 100 }}
          />
        </div>
        <div className="lg:col-span-4 md:col-span-3">
          <InputArea
            name="remark"
            label="Remark "
            placeholder="Remark"
            rows={1}
          />
        </div>
      </div>
      <hr className="border my-1 divide-x-8 bg-slate-300 border-slate-300" />
      <div>
        <div className="mt-2 text-gray-700 font-semibold whitespace-nowrap">
          <h1>Operator Checklist</h1>
        </div>
        <div className="grid lg:grid-cols-6 md:grid-cols-3 gap-x-3 gap-y-4 mt-3">
          <div>
            <TimePickerFieldNew
              control={control}
              name="patientArrivalTime"
              label="Pre OP Patient Arrival Time"
              value={new Date()}
            />
          </div>
          <div>
            <TimePickerFieldNew
              control={control}
              name="wheelIn"
              label="Wheel In"
              value={new Date()}
            />
          </div>
          <div>
            <TimePickerFieldNew
              control={control}
              name="signInTime"
              label="Sign In Time"
              value={new Date()}
            />
          </div>
          <div>
            <TimePickerFieldNew
              control={control}
              name="inductionTime"
              label="Induction Of Anaesthesia"
              value={new Date()}
            />
          </div>
          <div>
            <TimePickerFieldNew
              control={control}
              name="timeOutTime"
              label="Time Out Time"
              value={new Date()}
            />
          </div>
          <div>
            <TimePickerFieldNew
              control={control}
              name="incisionTime"
              label="Incision Time"
              value={new Date()}
            />
          </div>
          <div>
            <TimePickerFieldNew
              control={control}
              name="signOutTime"
              label="Sign Out Time"
              value={new Date()}
            />
          </div>
          <div>
            <TimePickerFieldNew
              control={control}
              name="sutureTime"
              label="Suture Time"
              value={new Date()}
            />
          </div>
          <div>
            <TimePickerFieldNew
              control={control}
              name="extrubutionOfAnaesthesiaTime"
              label="Extrubution Of Anaesthesia Time"
              value={new Date()}
            />
          </div>
          <div>
            <TimePickerFieldNew
              control={control}
              name="wheelOut"
              label="Wheel Out"
              value={new Date()}
            />
          </div>
          <div>
            <TimePickerFieldNew
              control={control}
              name="postOPArrivalTime"
              label="Post OP Arrival Time"
              value={new Date()}
            />
          </div>
          <div>
            <TimePickerFieldNew
              control={control}
              name="wardShiftTime"
              label="Ward Shift Time"
              value={new Date()}
            />
          </div>
          <div className="">
            <DropdownField
              control={control}
              name="wardShiftedBy"
              searchIcon={true}
              dataArray={employeeNameData}
              isSearchable={true}
              isClearable={true}
              placeholder="Ward Shifted By"
            />
          </div>
          <div>
            <TimePickerFieldNew
              control={control}
              name="otCleaningStarTime"
              label="Ot Cleaning Start Time"
              value={new Date()}
            />
          </div>
          <div>
            <TimePickerFieldNew
              control={control}
              name="otCleaningEndTime"
              label="Ot Cleaning End Time"
              value={new Date()}
            />
          </div>

          <div className="col-span-3">
            <InputArea
              name="extrubation"
              placeholder="Extrubation"
              label="Extrubation"
            />
          </div>
        </div>
      </div>
      <hr className="border my-2 divide-x-8 bg-slate-300 border-slate-300" />

      <AntibioticGiven />
    </>
  );
};

export default OtSheetDetails;
