import React, { useRef, useEffect } from "react";
import { InamdarHospiLogo } from "../../assets/printAssets/InmadarHospiLogo";
import { GeneratePrint } from "../../common/components/printModal/GeneratePrint";

export default function OtPostOPOrder(props) {
  const printRef = useRef(null);

  useEffect(() => {
    printRef && GeneratePrint(printRef);
  }, [props.otDetailsId]);

  return (
    <div ref={printRef}>
      <span className="flex justify-end -mt-11">
        <InamdarHospiLogo />
      </span>
      <h1 className=" text-center font-semibold border-t-[1px] border-black text-[17px]">
        Post Op Orders
      </h1>
      <div className=" border-t-[1px] text-[14px] border-b-[1px] border-black grid grid-cols-[14%_1%_35%_14%_1%_35%]">
        <div className="font-semibold">Mr.No</div>
        <div className="font-semibold">:</div>
        <div>{props.otDetailsId?.MRNo}</div>
        <div className="font-semibold">Patient Name</div>
        <div className="font-semibold">:</div>
        <div>{props.otDetailsId?.["Patient Name"]}</div>
        <div className="font-semibold">OT Sheet.No</div>
        <div className="font-semibold">:</div>
        <div>{props.otDetailsId?.OTSheetNo}</div>
        <div className="font-semibold">Bed No</div>
        <div className="font-semibold">:</div>
        <div>{props.otDetailsId?.BedNo}</div>
        <div className="font-semibold">Adm Date</div>
        <div className="font-semibold">:</div>
        <div>{props.otDetailsId?.AdmissionDate}</div>
        <div className="font-semibold">OT Date</div>
        <div className="font-semibold">:</div>
        <div>{props.otDetailsId?.OTDate}</div>
      </div>
      <div className=" my-2 flex mt-5 text-[14px]">
        <div className=" space-x-2  flex">
          <span>1) NBM till</span>
          <p className=" border-black border-b-[1px] w-28" />
        </div>
        <div className=" space-x-2  flex">
          <span>then</span>
          <p className=" border-black border-b-[1px] w-28" />
          <span>diet</span>
        </div>
      </div>
      <div className=" text-[14px] ">
        <span className="text-[12px]">2) Medication</span>
        <span className="text-[12px]">:</span>
        <div className=" ml-5 space-y-1">
          <div className=" space-x-2  flex">
            <span>Inj Supacef </span>
            <p className=" border-black border-b-[1px] w-28" />
            <span>gm at</span>
          </div>
          <div className=" space-x-2  flex">
            <span>Inj Pause / Inj Cyklokapron</span>
            <p className=" border-black border-b-[1px] w-28" />
            <span>mg/gm at</span>
          </div>
          <div className=" space-x-2  flex">
            <span>Inj Emeset</span>
            <p className=" border-black border-b-[1px] w-28" />
            <span>mg at</span>
          </div>
          <div className=" space-x-2  flex">
            <span>Inj Dynapar + 100 ml NS at</span>
          </div>
        </div>
      </div>
      <div className=" my-2 text-[14px]">
        <span className=" text-[12px]">After NBM Out</span>
        <div className="space-y-1 ml-7">
          <div className=" grid grid-cols-2">
            <span>Tab Pacimol 500mg/650mg</span>
            <span>1----1----1</span>
          </div>
          <div className=" grid grid-cols-2">
            <span>Tab VIT C 500mg</span>
            <span>0----1----0</span>
          </div>
          <div className=" grid grid-cols-2">
            <span>Tab Xarelto 10mg</span>
            <span>0----1----0</span>
          </div>
          <div className=" grid grid-cols-2">
            <span>Tab Supradyn</span>
            <span>0----0----1</span>
          </div>
        </div>
      </div>
      <div className=" flex space-x-20">
        <div className=" text-[14px] my-5">
          <h1>3) IV Fluid</h1>
          <div className=" mx-10">
            <h1>1 RL</h1>
            <h1>1 NS</h1>
            <h1>1 RL</h1>
            <h1>1 RS</h1>
          </div>
        </div>
        <div>
          <p className=" w-[10px] border-black border-b-[1px] h-[120px] mt-5 border-r-[1px] border-t-[1px] "></p>
          <div className=" items-center -mt-[70px] flex">
            <p className=" w-10 mx-[10px] border-black border-b-[1px]" />
            <p className=" font-semibold text-[14px] ">Each Over 4Hrs</p>
          </div>
        </div>
      </div>
      <div className=" mt-7 space-y-1">
        <div className=" space-x-2 text-[14px]">
          4) TPR / BP / I/O Chart / SPO2 Charting 2 hourly
        </div>
        <div className=" space-x-2 text-[14px]">
          5) Give O2 3lit/min by nasal prong only if SPO2&#60;&#61;85%
        </div>
        <div className=" space-x-2 text-[14px]">
          6) ECG at ____________________
        </div>
        <div className=" space-x-2 text-[14px] my-3">
          7) Post op X-ray __________________________ at
        </div>
        <div className=" space-x-2 text-[14px]">
          8) Patient to walk with wheeled walker____________________________
        </div>
      </div>
      <p className=" my-5 border-b-[1px] border-black" />
      <div className=" mt-14 text-end">
        <h1 className=" text-[14px] font-semibold ">
          Consultant Name & Signature
        </h1>
        <h1 className=" text-[14px] font-semibold mx-[85px] ">Date & Time</h1>
      </div>
    </div>
  );
}
