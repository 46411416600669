import React, { useRef, useState } from "react";
import InputField from "../../../common/components/FormFields/InputField";
import { useForm } from "react-hook-form";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CustomCreateable from "../EmrLite/CustomCreateable";
import { IconButton, Tooltip } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  getAdviceData,
  getCommonRoutes,
  getComplaintsData,
  getDiagnosis,
  getDrugForm,
  getGeneralFrequencies,
  getGenericDrugs,
  getInvestigations,
  getMedicines,
  getOtherServices,
  getReferenceDoctor,
} from "../EmrLite/Services";
import { EditIcon } from "../../../assets/CommonAssets";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import MedicinesTable from "../EmrLite/MedicinesTable";
import { UseCheckConsecutiveCharacters } from "../../../common/components/Custom Hooks/UseCheckConsecutiveCharacters";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import { errorAlert } from "../../../common/components/Toasts/CustomToasts";
import { useLocation } from "react-router-dom";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";

const medicinesData = [
  {
    Medicine: "",
    Dosage: "",
    Frequency: "",
    Route: "",
    Duration: "",
    Quantity: "",
    Instructions: "",
    Generic: "",
    Form: "",
    since: "",
    isDeleted: false,
  },
  {
    Medicine: "",
    Dosage: "",
    Frequency: "",
    Route: "",
    Duration: "",
    Quantity: "",
    Instructions: "",
    Generic: "",
    Form: "",
    since: "",
    isDeleted: false,
  },
  {
    Medicine: "",
    Dosage: "",
    Frequency: "",
    Route: "",
    Duration: "",
    Quantity: "",
    Instructions: "",
    Generic: "",
    Form: "",
    since: "",
    isDeleted: false,
  },
  {
    Medicine: "",
    Dosage: "",
    Frequency: "",
    Route: "",
    Duration: "",
    Quantity: "",
    Instructions: "",
    Generic: "",
    Form: "",
    since: "",
    isDeleted: false,
  },
  {
    Medicine: "",
    Dosage: "",
    Frequency: "",
    Route: "",
    Duration: "",
    Quantity: "",
    Instructions: "",
    Generic: "",
    Form: "",
    since: "",
    isDeleted: false,
  },
  {
    Medicine: "",
    Dosage: "",
    Frequency: "",
    Route: "",
    Duration: "",
    Quantity: "",
    Instructions: "",
    Generic: "",
    Form: "",
    since: "",
    isDeleted: false,
  },
  {
    Medicine: "",
    Dosage: "",
    Frequency: "",
    Route: "",
    Duration: "",
    Quantity: "",
    Instructions: "",
    Generic: "",
    Form: "",
    since: "",
    isDeleted: false,
  },
  {
    Medicine: "",
    Dosage: "",
    Frequency: "",
    Route: "",
    Duration: "",
    Quantity: "",
    Instructions: "",
    Generic: "",
    Form: "",
    since: "",
    isDeleted: false,
  },
  {
    Medicine: "",
    Dosage: "",
    Frequency: "",
    Route: "",
    Duration: "",
    Quantity: "",
    Instructions: "",
    Generic: "",
    Form: "",
    since: "",
    isDeleted: false,
  },
  {
    Medicine: "",
    Dosage: "",
    Frequency: "",
    Route: "",
    Duration: "",
    Quantity: "",
    Instructions: "",
    Generic: "",
    Form: "",
    since: "",
    isDeleted: false,
  },
  {
    Medicine: "",
    Dosage: "",
    Frequency: "",
    Route: "",
    Duration: "",
    Quantity: "",
    Instructions: "",
    Generic: "",
    Form: "",
    since: "",
    isDeleted: false,
  },
  {
    Medicine: "",
    Dosage: "",
    Frequency: "",
    Route: "",
    Duration: "",
    Quantity: "",
    Instructions: "",
    Generic: "",
    Form: "",
    since: "",
    isDeleted: false,
  },
  {
    Medicine: "",
    Dosage: "",
    Frequency: "",
    Route: "",
    Duration: "",
    Quantity: "",
    Instructions: "",
    Generic: "",
    Form: "",
    since: "",
    isDeleted: false,
  },
  {
    Medicine: "",
    Dosage: "",
    Frequency: "",
    Route: "",
    Duration: "",
    Quantity: "",
    Instructions: "",
    Generic: "",
    Form: "",
    since: "",
    isDeleted: false,
  },
  {
    Medicine: "",
    Dosage: "",
    Frequency: "",
    Route: "",
    Duration: "",
    Quantity: "",
    Instructions: "",
    Generic: "",
    Form: "",
    since: "",
    isDeleted: false,
  },
  {
    Medicine: "",
    Dosage: "",
    Frequency: "",
    Route: "",
    Duration: "",
    Quantity: "",
    Instructions: "",
    Generic: "",
    Form: "",
    since: "",
    isDeleted: false,
  },
  {
    Medicine: "",
    Dosage: "",
    Frequency: "",
    Route: "",
    Duration: "",
    Quantity: "",
    Instructions: "",
    Generic: "",
    Form: "",
    since: "",
    isDeleted: false,
  },
  {
    Medicine: "",
    Dosage: "",
    Frequency: "",
    Route: "",
    Duration: "",
    Quantity: "",
    Instructions: "",
    Generic: "",
    Form: "",
    since: "",
    isDeleted: false,
  },
  {
    Medicine: "",
    Dosage: "",
    Frequency: "",
    Route: "",
    Duration: "",
    Quantity: "",
    Instructions: "",
    Generic: "",
    Form: "",
    since: "",
    isDeleted: false,
  },
  {
    Medicine: "",
    Dosage: "",
    Frequency: "",
    Route: "",
    Duration: "",
    Quantity: "",
    Instructions: "",
    Generic: "",
    Form: "",
    since: "",
    isDeleted: false,
  },
  {
    Medicine: "",
    Dosage: "",
    Frequency: "",
    Route: "",
    Duration: "",
    Quantity: "",
    Instructions: "",
    Generic: "",
    Form: "",
    since: "",
    isDeleted: false,
  },
  {
    Medicine: "",
    Dosage: "",
    Frequency: "",
    Route: "",
    Duration: "",
    Quantity: "",
    Instructions: "",
    Generic: "",
    Form: "",
    since: "",
    isDeleted: false,
  },
];
function NewEmrTemplate({ selectedRow, setSelectedTab, setSelectedRow }) {
  const loggedUser = JSON.parse(localStorage.getItem("loggedUser"));
  const location = useLocation();
  const [formData, setFormData] = useState([]);
  const [complaintsOptions, setComplaintsOptions] = useState([]);
  const [selectedComplaints, setSelectedComplaints] = useState([]);
  const [editComplaintIndex, setEditComplaintIndex] = useState(null);
  const [complaints, setComplaints] = useState([]);
  const [resetComplaints, setResetComplaints] = React.useState(0);
  const [medicineInstructions, setMedicineInstructions] = React.useState([]);

  const [selectedDiagnosis, setSelectedDiagnosis] = useState([]);
  const [diagnoOptions, setDiagnoOptions] = useState([]);
  const [diagnosis, setDiagnosis] = useState([]);

  const [invOptions, setInvOptions] = useState([]);
  const [selectedInvestigations, setSelectedInvestigations] = useState([]);

  const [resetInv, setResetInv] = useState(0);

  const [selectedMedicines, setSelectedMedicines] = useState([
    ...medicinesData,
  ]);
  const [searchMedicines, setSearchMedicines] = useState([]);
  const [frequencyOptions, setFrequencyOptions] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [genericDrugs, setGenericDrugs] = useState([]);
  const [formOpt, setFormOpt] = useState([]);

  const [advicesOptions, setAdvicesOptions] = useState([]);
  const [selectedAdvices, setSelectedAdvices] = useState([]);

  const [referralDocOptions, setReferralDocOptions] = useState([]);
  const [selectedOtherReferral, setSelectedOtherReferral] = useState([]);
  const [servicesOptions, setServicesOptions] = useState([]);
  const [otherServices, setOtherServices] = useState([]);
  const [resetServices, setResetServices] = useState(0);

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const complaintRef = useRef(null);

  const defaultValues = {
    searchDiagnosis: null,
    referralDoctor: null,
    nextFollowupDate: new Date(),
    searchOtherServices: null,
  };
  const {
    control,
    formState: { errors },
    watch,
    register,
    setValue,
    setError,
    clearErrors,
    resetField,
    handleSubmit,
  } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
  });

  let complaintWatch = watch("createComplaint");
  let investigationWatch = watch("searchInvestigations");
  let diagnosisWatch = watch("searchDiagnosis");
  let nextFollowup = watch("nextFollowupDate");
  let allergyWatch = watch("searchAllergy");
  let otherReferralWatch = watch("referralDoctor");
  let advicesWatch = watch("searchAdvices");
  let watchOtherServices = watch("searchOtherServices");

  React.useEffect(() => {
    getGeneralFrequencies(0)
      .then((response) => response.data)
      .then((res) => {
        setFrequencyOptions(res?.result);
      })
      .catch((res) => {});

    getCommonRoutes()
      .then((response) => response.data)
      .then((res) => {
        setRoutes(res?.result);
      })
      .catch((err) => err);

    getDrugForm()
      .then((response) => response.data)
      .then((res) => {
        setFormOpt(res.result);
      })
      .catch((err) => err);

    getAdviceData()
      .then((response) => response.data)
      .then((res) => {
        setAdvicesOptions(res?.result);
      })
      .catch((err) => err);
  }, []);

  const handleSearchComplaints = (search) => {
    if (search !== "") {
      getComplaintsData(search)
        .then((response) => response.data)
        .then((res) => {
          if (res?.statusCode === 200) {
            setComplaintsOptions(res?.result);
          }
        })
        .catch((er) => er);
    }
  };

  const handleEditComplaint = (complaintObject, complaintIndex) => {
    let patchComplaint = {
      id: complaintObject?.value,
      label: complaintObject?.label,
      value: complaintObject?.value,
    };
    setValue("createComplaint", patchComplaint);
    setEditComplaintIndex(complaintIndex);
  };

  const handleUpdateComplaint = () => {
    let newComplaint = {
      id: complaintWatch?.__isNew__ === true ? null : complaintWatch.id,
      label: complaintWatch.label,
      value: complaintWatch.value || null,
      since: "",
      duration: "D",
      isChecked: true,
    };
    let dataArray = [...selectedComplaints];
    let filtered = dataArray.filter(
      (complaint) => complaint?.label === complaintWatch?.label
    );
    if (filtered?.length === 0) {
      dataArray.splice(editComplaintIndex, 1, newComplaint);
      setSelectedComplaints(dataArray);
      setValue("createComplaint", null);
      setEditComplaintIndex(null);
    } else {
      errorAlert("Complaint already exists.");
      setEditComplaintIndex(null);
      setValue("createComplaint", null);
    }
  };

  const handleDeleteComplaints = (complaint) => {
    const combinedArray = [...complaints];
    const uniqueArray = Array.from(
      new Map(combinedArray.map((item) => [item.label, item])).values()
    );

    uniqueArray.sort((a, b) => {
      if (a.value === null) return 1;
      if (b.value === null) return -1;
      return a.value - b.value;
    });
    let dataArrayTwo = [...uniqueArray];
    for (let object of dataArrayTwo) {
      if (object?.label === complaint?.label) {
        object.isChecked = !object.isChecked;
      }
    }

    setComplaints(dataArrayTwo);

    let dataArray = [...selectedComplaints];
    let filtered = dataArray.filter((item) => item !== complaint);
    setSelectedComplaints(filtered);
  };

  const handleAddComplaintsDuration = (duration, complaintIndex) => {
    let dataArray = [...complaints];

    dataArray[complaintIndex].duration = duration;
    setComplaints(dataArray);
  };

  //diagnosis

  const handelSearchDiagnosis = (search) => {
    if (search !== "") {
      getDiagnosis(
        search,
        loggedUser?.loginUnitId,
        location?.state?.departmentId
      )
        .then((response) => response.data)
        .then((res) => {
          setDiagnoOptions(res?.result);
        })
        .catch((er) => er);
    }
  };

  const handleDeleteDiagnosis = (diagno) => {
    const combinedArray = [...diagnosis];
    const uniqueArray = Array.from(
      new Map(combinedArray.map((item) => [item.label, item])).values()
    );

    uniqueArray.sort((a, b) => {
      if (a.value === null) return 1;
      if (b.value === null) return -1;
      return a.value - b.value;
    });
    let dataArrayTwo = [...uniqueArray];
    for (let object of dataArrayTwo) {
      if (object?.label === diagno?.label) {
        object.isChecked = !object.isChecked;
      }
    }

    setDiagnosis(dataArrayTwo);

    let dataArray = [...selectedDiagnosis];
    let filtered = dataArray.filter((item) => item !== diagno);
    setSelectedDiagnosis(filtered);
  };

  //Investigation

  const handleSearchInvestigations = (search) => {
    if (search !== "") {
      getInvestigations(search, 2)
        .then((response) => response.data)
        .then((res) => {
          if (res?.statusCode === 200) {
            setInvOptions(res?.result);
          }
        })
        .catch((er) => er);
    }
  };

  const handleDeleteInv = (inv) => {
    let dataArray = [...selectedInvestigations];
    let filtered = dataArray.filter((item) => item !== inv);
    setSelectedInvestigations(filtered);
    setResetInv(resetInv + 1);
  };

  //medication

  const handleGetMedicine = (search) => {
    if (search !== "" && UseCheckConsecutiveCharacters(search)) {
      getMedicines(search)
        .then((response) => {
          setSearchMedicines(response.data.result);
        })
        .catch((error) => {
          console.log("error", error);
        });
    } else {
      setSearchMedicines([]);
    }
  };

  const handleGetGenerics = (searchGenericName) => {
    if (
      searchGenericName &&
      searchGenericName !== undefined &&
      UseCheckConsecutiveCharacters(searchGenericName)
    ) {
      getGenericDrugs(searchGenericName)
        .then((response) => response.data)
        .then((res) => {
          setGenericDrugs(res.result);
        });
    }
  };

  const handleAllDurations = (isApply) => {
    let dataArray = [...selectedMedicines];
    let firstMedicine = dataArray[0];
    if (isApply === true) {
      for (let medicine of dataArray) {
        if (
          firstMedicine?.Medicine !== null &&
          Number(firstMedicine?.since) > 0
        ) {
          if (
            medicine?.Medicine !== "" &&
            medicine?.Medicine?.id !== firstMedicine?.Medicine?.id
          ) {
            medicine.since = firstMedicine?.since;
          }
        }
      }
      setSelectedMedicines(dataArray);
    } else if (isApply === false) {
      for (let medicine of dataArray) {
        if (
          medicine?.Medicine !== "" &&
          medicine?.Medicine?.id !== firstMedicine?.Medicine?.id
        ) {
          medicine.since = "";
        }
      }
      setSelectedMedicines(dataArray);
    }
  };

  const renderInput = (row, index, header) => {
    if (row?.Medicine === "") {
      setValue(`medicine[${index}].medicine`, null);
    } else {
      setValue(`medicine[${index}].medicine`, row?.Medicine);
    }

    if (row?.Medicine === "") {
      setValue(`medicine[${index}].generic`, null);
      setValue(`medicine[${index}].form`, null);
    }

    if (row?.Dosage === "") {
      setValue(`medicine[${index}].dosage`, "");
    } else {
      setValue(`medicine[${index}].dosage`, row?.Dosage);
    }

    if (row?.Frequency?.label !== "") {
      setValue(`medicine[${index}].frequency`, row?.Frequency);
    } else {
      setValue(`medicine[${index}].frequency`, null);
    }

    setValue(`duration[${index}].since`, row?.since);

    if (row?.Route === "") {
      setValue(`medicine[${index}].route`, null);
    } else {
      setValue(`medicine[${index}].route`, row?.Route);
    }

    if (row?.Duration === "") {
      setValue(`medicine[${index}].duration`, "");
      setValue(`medicine[${index}].quantity`, "");
    } else {
      setValue(`medicine[${index}].duration`, row?.Duration);
    }

    const calculateQuantity = (frequency, duration) => {
      if (!frequency || !duration) return 0;

      let frequencyCountValue = 0;
      let freqArray = frequency.label.split("-");

      freqArray.forEach((element) => {
        let value = element.trim();

        if (value === "1/2" || value === "½") {
          frequencyCountValue += 0.5;
        } else if (value === "¼") {
          frequencyCountValue += 0.25;
        } else if (value === "1") {
          frequencyCountValue += 1;
        } else if (value === "1½") {
          frequencyCountValue += 1.5;
        } else if (value === "1/8") {
          frequencyCountValue += 0.125;
        } else if (value === "2") {
          frequencyCountValue += 2;
        } else if (value === "2½") {
          frequencyCountValue += 2.5;
        } else if (value === "3") {
          frequencyCountValue += 3;
        } else if (value === "3½") {
          frequencyCountValue += 3.5;
        } else if (value === "4") {
          frequencyCountValue += 4;
        } else if (value === "4½") {
          frequencyCountValue += 4.5;
        } else if (value === "STAT") {
          frequencyCountValue += 1;
        } else if (value === "Stat/sos") {
          frequencyCountValue += 1;
        } else if (value === "SOS") {
          frequencyCountValue += 1;
        }
      });
      const quantityCount = Number(duration) * frequencyCountValue;
      return Math.ceil(quantityCount);
    };

    return (
      <div
        className={`flex justify-start z-50 ${
          index !== 0 && selectedMedicines[index - 1]?.Medicine === ""
            ? "hidden"
            : ``
        } `}
      >
        {header === "Medicine" ? (
          <div className="flex justify-start w-[25vw] lg:w-full">
            <CustomCreateable
              isDisabled={
                index !== 0 && selectedMedicines[index - 1]?.Medicine === ""
                  ? true
                  : false
              }
              menuPlacement={"bottom"}
              width={"250px"}
              control={control}
              name={`medicine[${index}].medicine`}
              placeholder={"Medicine"}
              handleInputChange={handleGetMedicine}
              inputRef={{
                ...register(`medicine[${index}].medicine`, {
                  onChange: (e) => {
                    let dataArray = [...selectedMedicines];
                    if (e.target.value !== null) {
                      let genericNameObj = {
                        id: e.target.value.genericId,
                        value: e.target.value.genericName,
                        label: e.target.value.genericName,
                      };

                      let formObj = {
                        Id: e.target.value.formId,
                        value: e.target.value.form,
                        label: e.target.value.form,
                      };

                      let filtered = dataArray.filter(
                        (item) =>
                          item?.Medicine?.label === e.target.value?.label
                      );

                      if (filtered.length === 0) {
                        let newMedicine = {
                          id:
                            e.target.value?.__isNew__ === true
                              ? null
                              : e.target.value?.id,
                          label: e.target.value?.label,
                          value:
                            e.target.value?.__isNew__ === true
                              ? null
                              : e.target.value?.id,
                        };

                        dataArray[index] = {
                          ...dataArray[index],
                          Medicine: newMedicine,
                          Generic: e.target.value.genericId
                            ? genericNameObj
                            : null,
                          Form: e.target.value.formId ? formObj : null,
                        };

                        if (
                          e.target.value.genericId !== null &&
                          e.target.value.genericId !== undefined
                        ) {
                          setValue(
                            `medicine[${index}].generic`,
                            genericNameObj
                          );
                          clearErrors([`medicine[${index}].generic`]);
                        }
                        if (
                          e.target.value.formId !== null &&
                          e.target.value.formId !== undefined
                        ) {
                          setValue(`medicine[${index}].form`, formObj);
                          clearErrors([`medicine[${index}].form`]);
                        }
                        setSelectedMedicines(dataArray);
                        const nextField = document.querySelector(
                          `input[name="medicine[${index}].dosage"]`
                        );
                        nextField?.focus();
                      } else {
                        setValue(`medicine[${index}].medicine`, null);
                        errorAlert("Medicine / Drug already exists");
                      }
                    } else {
                      setValue(`medicine[${index}].generic`, null);
                      setValue(`medicine[${index}].form`, null);
                      dataArray[index] = {
                        ...dataArray[index],
                        Medicine: "",
                        Generic: null,
                        Form: null,
                      };
                      setSelectedMedicines(dataArray);
                    }
                  },
                }),
              }}
              dataArray={searchMedicines}
              isSearchable={true}
            />
          </div>
        ) : null}

        {header === "Dosage" ? (
          <div className="flex justify-start w-full ml-2">
            <input
              className="h-7 w-32 border border-gray-400 rounded px-2"
              style={{ outline: "none" }}
              placeholder="Dosage"
              name={`medicine[${index}].dosage`}
              {...register(`medicine[${index}].dosage`)}
              onChange={(e) => {
                let dataArray = [...selectedMedicines];
                dataArray[index].Dosage = e.target.value;
                setSelectedMedicines(dataArray);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  const nextField = document
                    .querySelector(`[name="medicine[${index}].frequency"]`)
                    ?.closest(".w-full");
                  if (nextField) {
                    const selectInput = nextField.querySelector("input");
                    if (selectInput) {
                      selectInput.focus();
                      const selectDiv = nextField.querySelector(
                        ".css-1s2u09g-control"
                      );
                      if (selectDiv) {
                        selectDiv.click();
                      }
                    }
                  }
                }
              }}
            />
          </div>
        ) : null}

        {header === "Frequency" ? (
          <div className="flex justify-start ml-2 w-[20vw] xl:w-full">
            <CustomCreateable
              isDisabled={
                index !== 0 && selectedMedicines[index - 1]?.Medicine === ""
                  ? true
                  : false
              }
              width={"170px"}
              control={control}
              name={`medicine[${index}].frequency`}
              placeholder={"Frequency"}
              handleInputChange={() => {}}
              isClearable={true}
              inputRef={{
                ...register(`medicine[${index}].frequency`, {
                  onChange: (e) => {
                    if (
                      e.target.value !== null &&
                      e.target.value !== undefined
                    ) {
                      let dataArray = [...selectedMedicines];
                      dataArray[index].Frequency = e.target.value;

                      const duration = dataArray[index].Duration;
                      if (duration) {
                        const quantity = calculateQuantity(
                          e.target.value,
                          duration
                        );
                        setValue(`medicine[${index}].quantity`, quantity);
                        dataArray[index].Quantity = quantity;
                      }

                      setSelectedMedicines(dataArray);
                      const nextField = document
                        .querySelector(`[name="medicine[${index}].route"]`)
                        ?.closest(".w-full");

                      if (nextField) {
                        const routeInput = nextField.querySelector("input");
                        if (routeInput) {
                          routeInput.focus();
                        }
                      }
                    } else {
                      let dataArray = [...selectedMedicines];
                      dataArray[index].Frequency = "";
                      setValue(`medicine[${index}].quantity`, 0);
                      dataArray[index].Quantity = 0;
                      setSelectedMedicines(dataArray);
                    }
                  },
                }),
              }}
              dataArray={frequencyOptions}
              isSearchable={true}
            />
          </div>
        ) : null}

        {header === "Route" ? (
          <div className="flex justify-start w-[17vw] xl:w-full ml-2">
            <CustomCreateable
              isDisabled={
                index !== 0 && selectedMedicines[index - 1]?.Medicine === ""
                  ? true
                  : false
              }
              width={"170px"}
              control={control}
              name={`medicine[${index}].route`}
              placeholder={"Route"}
              handleInputChange={() => {}}
              inputRef={{
                ...register(`medicine[${index}].route`, {
                  onChange: (e) => {
                    let dataArray = [...selectedMedicines];
                    dataArray[index].Route = e.target.value;
                    setSelectedMedicines(dataArray);
                    const nextField = document.querySelector(
                      `input[name="medicine[${index}].duration"]`
                    );
                    nextField?.focus();
                  },
                }),
              }}
              dataArray={routes}
              isSearchable={true}
              isClearable={true}
            />
          </div>
        ) : null}

        {header === "Duration" ? (
          <div className="flex gap-2 items-center ml-2">
            <input
              disabled={
                index !== 0 && selectedMedicines[index - 1]?.Medicine === ""
                  ? true
                  : false
              }
              name={`medicine[${index}].duration`}
              {...register(`medicine[${index}].duration`)}
              style={{ outline: "none" }}
              placeholder="Duration"
              className="text-center w-24 border border-gray-400 rounded"
              onChange={(e) => {
                let dataArray = [...selectedMedicines];
                dataArray[index].Duration = e.target.value;
                const frequency = dataArray[index].Frequency;
                if (frequency) {
                  const quantity = calculateQuantity(frequency, e.target.value);
                  setValue(`medicine[${index}].quantity`, quantity);
                  dataArray[index].Quantity = quantity;
                }

                setSelectedMedicines(dataArray);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  const nextField = document
                    .querySelector(`[name="medicine[${index}].instructions"]`)
                    ?.closest(".w-full");
                  if (nextField) {
                    const selectInput = nextField.querySelector("input");
                    if (selectInput) {
                      selectInput.focus();
                      const selectDiv = nextField.querySelector(
                        ".css-1s2u09g-control"
                      );
                      if (selectDiv) {
                        selectDiv.click();
                      }
                    }
                  }
                }
              }}
            />
            <div className="px-3 rounded-full bg-[#073763] text-sm py-0.5 text-white">
              Days
            </div>
          </div>
        ) : null}

        {header === "Quantity" ? (
          <div className="flex justify-start w-full ml-2">
            <input
              className="h-7 w-20 border border-gray-400 rounded px-2"
              style={{ outline: "none" }}
              placeholder="Qty"
              name={`medicine[${index}].quantity`}
              {...register(`medicine[${index}].quantity`)}
              disabled={true}
            />
          </div>
        ) : null}

        {header === "Instructions" ? (
          <div className="flex justify-start ml-2 w-[20vw] xl:w-full">
            <CustomCreateable
              isDisabled={
                index !== 0 && selectedMedicines[index - 1]?.Medicine === ""
                  ? true
                  : false
              }
              control={control}
              name={`medicine[${index}].instructions`}
              placeholder={"Instructions"}
              handleInputChange={() => {}}
              inputRef={{
                ...register(`medicine[${index}].instructions`, {
                  onChange: (e) => {
                    let dataArray = [...selectedMedicines];
                    dataArray[index].Instructions = e.target.value;
                    setSelectedMedicines(dataArray);
                    const nextField = document
                      .querySelector(`[name="medicine[${index}].generic"]`)
                      ?.closest(".w-full");

                    if (nextField) {
                      const routeInput = nextField.querySelector("input");
                      if (routeInput) {
                        routeInput.focus();
                      }
                    }
                  },
                }),
              }}
              dataArray={medicineInstructions}
              isSearchable={true}
              isClearable={true}
            />
          </div>
        ) : null}

        {header === "Generic" ? (
          <div className="flex justify-start pl-2 w-[20vw] xl:w-full">
            <CustomCreateable
              control={control}
              name={`medicine[${index}].generic`}
              isSearchable={true}
              placeholder={"Generic"}
              isClearable={true}
              handleInputChange={handleGetGenerics}
              inputRef={{
                ...register(`medicine[${index}].generic`, {
                  onChange: (e) => {
                    let dataArray = [...selectedMedicines];
                    dataArray[index].Generic = e.target.value;
                    setSelectedMedicines(dataArray);
                    const nextField = document
                      .querySelector(`[name="medicine[${index}].form"]`)
                      ?.closest(".w-full");

                    if (nextField) {
                      const routeInput = nextField.querySelector("input");
                      if (routeInput) {
                        routeInput.focus();
                      }
                    }
                  },
                }),
              }}
              dataArray={genericDrugs}
            />
          </div>
        ) : null}

        {header === "Form" ? (
          <div className="flex justify-start w-[18vw] xl:w-full ml-2">
            <CustomCreateable
              isDisabled={
                index !== 0 && selectedMedicines[index - 1]?.Medicine === ""
                  ? true
                  : false
              }
              width={"170px"}
              control={control}
              name={`medicine[${index}].form`}
              placeholder={"Form"}
              handleInputChange={() => {}}
              inputRef={{
                ...register(`medicine[${index}].form`, {
                  onChange: (e) => {
                    let dataArray = [...selectedMedicines];
                    dataArray[index].Form = e.target.value;
                    setSelectedMedicines(dataArray);
                  },
                }),
              }}
              dataArray={formOpt}
              isSearchable={true}
              isClearable={true}
            />
          </div>
        ) : null}
      </div>
    );
  };

  const renderActions = (row, index) => {
    return (
      <div className="flex items-center">
        <>
          {row?.Medicine !== "" ? (
            <div className="flex gap-2 cursor-pointer">
              <Tooltip title="Delete">
                <IconButton
                  onClick={() => {
                    let dataArray = [...selectedMedicines];
                    dataArray[index].isDeleted = true;
                    setSelectedMedicines(dataArray);
                  }}
                  sx={{ padding: 0, margin: 0 }}
                >
                  <DeleteOutlineIcon fontSize="small" sx={{ color: "red" }} />
                </IconButton>
              </Tooltip>
            </div>
          ) : null}
        </>
      </div>
    );
  };

  const handleDeleteAdvice = (advice) => {
    let dataArray = [...selectedAdvices];
    let filtered = dataArray.filter((item) => item.value !== advice.value);
    setSelectedAdvices(filtered);
  };

  const handleOtherReferral = (search) => {
    if (search !== "") {
      getReferenceDoctor(
        search,
        loggedUser?.loginUnitId,
        location?.state?.departmentId
      )
        .then((response) => response.data)
        .then((res) => {
          setReferralDocOptions(res?.result);
        })
        .catch((er) => er);
    }
  };

  const handleDeleteOtherReferral = (row) => {
    let dataArray = [...selectedOtherReferral];
    let filtered = dataArray.filter((item) => item !== row);
    setSelectedOtherReferral(filtered);
  };

  const handleSearchServices = (search) => {
    if (search !== "") {
      getOtherServices(search)
        .then((response) => response.data)
        .then((res) => {
          setServicesOptions(res?.result);
        })
        .catch((err) => err);
    }
  };

  const handleDeleteOtherService = (row) => {
    let dataArray = [...otherServices];
    let filtered = dataArray.filter((item) => item !== row);
    setOtherServices(filtered);
    setResetServices(resetServices + 1);
  };

  const onSubmit = () => {
    let complaints = [];
    for (let complaint of selectedComplaints) {
      let complaintDuration =
        complaint?.duration === "D"
          ? "Days"
          : complaint?.Duration === "M"
          ? "Months"
          : complaint?.duration === "Y"
          ? "Years"
          : "";

      let complaintObj = {
        complaint: complaint?.label,
        since: Number(complaint?.since) > 0 ? Number(complaint.since) : null,
        sinceIn: complaint?.since ? complaintDuration : null,
      };
      complaints.push(complaintObj);
    }

    let diagnosis = [];
    for (let diagno of selectedDiagnosis) {
      let diagnoObj = {
        diagnosis: diagno?.label,
        since: diagno?.since ? diagno.since : null,
      };
      diagnosis.push(diagnoObj);
    }

    let investigation = [];
    for (let inv of selectedInvestigations) {
      let invObj = {
        serviceId: inv?.testId ? inv?.testId : null,
        serviceName: inv?.label,
      };
      investigation.push(invObj);
    }

    let medications = [];
    let filteredMedicines = selectedMedicines.filter(
      (medicine) => medicine?.Medicine !== ""
    );

    if (filteredMedicines.length > 0) {
      for (let object of filteredMedicines) {
        let medicationObj = {
          id: object?.id ? object.id : null,
          drugId: object?.Medicine?.id,
          drugName: object?.Medicine?.label,
          dosage: object?.Dosage,
          duration: Number(object?.Duration) > 0 ? Number(object) : null,
          DurationsIn: "Days",
          formId: object.Form.Id ? object.Form.Id : null,
          frequency: object?.Frequency?.label,
          frequencyId: object?.Frequency?.id,
          genericId: object?.Generic?.id,
          instruction: object?.Instructions?.label,
          instructionId: object?.Instructions?.Id,
          quantity: object?.Quantity,
          routeId: object?.Route.Id,
          isDelete: object?.isDeleted,
        };
        medications.push(medicationObj);
      }
    }

    let advices = [];
    for (let adv of selectedAdvices) {
      advices.push(adv.label);
    }

    let otherRefferal = [];
    for (let otherRef of selectedOtherReferral) {
      let otherRefObj = {
        doctorId: otherRef?.id,
        doctorName: otherRef?.label,
      };
      otherRefferal.push(otherRefObj);
    }

    let otherService = [];
    for (let othService of otherServices) {
      let otherServiceObj = {
        serviceId: othService?.id,
        serviceName: othService?.label,
      };
      otherService.push(otherServiceObj);
    }

    const formObj = {
      id: "",
      complaints: complaints,
      investigation: investigation,
      diagnosis: diagnosis,
      medicationDto: medications,
      advices: advices,
      otherRefferal: otherRefferal,
      otherServices: otherService,
    };

    setFormData(formObj);
    setOpenConfirmationModal(true);
  };

  const handleOnSubmit = () => {};

  function findNextComplaint() {
    var universe = document.querySelectorAll("input");

    var list = Array.prototype.filter.call(universe, function (item) {
      return item.tabIndex >= "0";
    });

    let filtered = list.filter(
      (field) => field.id === "react-select-3-input" && field.name === ""
    );

    console.log();

    return filtered[0]?.focus();
  }

  React.useEffect(() => {
    if (complaintWatch !== null && complaintWatch !== undefined) {
      findNextComplaint();
    }

    let filterdComplaints = selectedComplaints.filter(
      (list) => list.label === complaintWatch?.label
    );

    if (editComplaintIndex === null) {
      if (
        complaintWatch !== null &&
        complaintWatch !== undefined &&
        filterdComplaints?.length > 0
      ) {
        errorAlert("Complaint Already Exist..!");
        setValue("createComplaint", null);
      } else {
        if (complaintWatch !== null && complaintWatch !== undefined) {
          let combinedComplaints = [...selectedComplaints];

          if (complaintWatch && complaintWatch.label) {
            combinedComplaints.push({
              id: complaintWatch?.__isNew__ === true ? null : complaintWatch.id,
              label: complaintWatch.label,
              value: complaintWatch.value || null,
              since: "",
              duration: "D",
              isChecked: true,
            });
          }

          combinedComplaints = combinedComplaints.filter(
            (item) => item.label && item.isChecked
          );

          const uniqueArray = Array.from(
            new Map(
              combinedComplaints.map((item) => [item.label, item])
            ).values()
          ).sort((a, b) => {
            if (a.value === null) return 1;
            if (b.value === null) return -1;
            return a.value - b.value;
          });

          setComplaints(uniqueArray);
          setSelectedComplaints(uniqueArray);
          setValue("createComplaint", null);
        } else {
          let dataArray = [...selectedComplaints, ...complaints];
          for (let object of dataArray) {
            if (object?.label === complaintWatch?.label) {
              object.isChecked = true;
            }
            setComplaints(dataArray);
            setValue("createComplaint", null);
          }
        }

        setResetComplaints(resetComplaints + 1);
      }
    }
  }, [complaintWatch]);

  function findNextDiagnosis() {
    var universe = document.querySelectorAll("input");
    var list = Array.prototype.filter.call(universe, function (item) {
      return item.tabIndex >= "0";
    });

    let filtered = list.filter(
      (field) => field.id === "react-select-4-input" && field.name === ""
    );
    console.log("filteredfilteredD", filtered);

    return filtered[0]?.focus();
  }

  React.useEffect(() => {
    if (diagnosisWatch !== null && diagnosisWatch !== undefined) {
      findNextDiagnosis();
    }
    if (diagnosisWatch) {
      let dataArray = [...selectedDiagnosis];
      let diagno = diagnosisWatch;

      const filterdDiagnosis = dataArray.filter(
        (list) => list.value === diagno.value
      );

      if (filterdDiagnosis.length > 0) {
        errorAlert("Diagnosis Already Exist!");
      } else {
        diagno.isChecked = true;
        dataArray.push(diagno);

        const combinedComplaints = dataArray.filter(
          (item) => item.label && item.isChecked
        );

        const uniqueDiagnosis = Array.from(
          new Map(combinedComplaints.map((item) => [item.label, item])).values()
        ).sort((a, b) => {
          if (a.value === null) return 1;
          if (b.value === null) return -1;
          return a.value - b.value;
        });

        setSelectedDiagnosis(uniqueDiagnosis);

        const previousDiagnosis = [...diagnosis];
        const mergedDiagnosis = [
          ...new Map(
            [...previousDiagnosis, ...combinedComplaints].map((item) => [
              item.label,
              item,
            ])
          ).values(),
        ];

        setDiagnosis(mergedDiagnosis);
      }

      setValue("searchDiagnosis", null);
    }
  }, [diagnosisWatch, selectedDiagnosis]);

  function findNextInvestigation() {
    var universe = document.querySelectorAll("input");
    var list = Array.prototype.filter.call(universe, function (item) {
      return item.tabIndex >= "0";
    });

    let filtered = list.filter(
      (field) => field.id === "react-select-5-input" && field.name === ""
    );
    console.log("filteredfilteredD", filtered);

    return filtered[0]?.focus();
  }

  React.useEffect(() => {
    if (investigationWatch?.label !== "") {
      findNextInvestigation();
    }
    let dataArray = [...selectedInvestigations];
    let filtered = dataArray.filter(
      (inv) => inv.label === investigationWatch?.label
    );
    if (filtered.length > 0 && investigationWatch !== null) {
      errorAlert("Investigation Already Exist");
      setValue("searchInvestigations", null);
    } else if (investigationWatch?.label !== undefined) {
      let investigationObject = {
        testId: investigationWatch?.id,
        label: investigationWatch?.label,
        value: investigationWatch?.value,
      };

      dataArray.push(investigationObject);

      let tempArr = selectedInvestigations.filter(
        (list) => list.testId !== undefined && list.serviceId !== undefined
      );

      setValue("searchInvestigations", null);
      setSelectedInvestigations(dataArray);
    }
  }, [investigationWatch]);

  function findNextAdvices() {
    var universe = document.querySelectorAll("input");
    var list = Array.prototype.filter.call(universe, function (item) {
      return item.tabIndex >= "0";
    });

    let filtered = list.filter(
      (field) => field.id === "react-select-138-input" && field.name === ""
    );

    return filtered[0]?.focus();
  }

  React.useEffect(() => {
    if (advicesWatch !== null && advicesWatch !== undefined) {
      findNextAdvices();
    }
    if (advicesWatch) {
      let dataArray = [...selectedAdvices];
      let advices = advicesWatch;

      const filteredAllergy = dataArray.filter(
        (list) => list.value === advices.value
      );

      if (filteredAllergy.length > 0) {
        errorAlert("Advice Already Exist!");
        setValue("searchAdvices", null);
      } else if (advicesWatch !== undefined) {
        let otherReferralObject = {
          label: advicesWatch.label,
          value: advicesWatch.value,
        };

        dataArray.push(otherReferralObject);
        setValue("searchAdvices", null);
        setSelectedAdvices(dataArray);
      }
    }
  }, [advicesWatch]);

  function findNextOtherReferral() {
    var universe = document.querySelectorAll("input");
    var list = Array.prototype.filter.call(universe, function (item) {
      return item.tabIndex >= "0";
    });

    let filtered = list.filter(
      (field) => field.id === "react-select-139-input" && field.name === ""
    );

    return filtered[0]?.focus();
  }

  React.useEffect(() => {
    if (otherReferralWatch !== null && otherReferralWatch !== undefined) {
      findNextOtherReferral();
    }
    if (otherReferralWatch) {
      let dataArray = [...selectedOtherReferral];
      let otherReferral = otherReferralWatch;

      const filteredAllergy = dataArray.filter(
        (list) => list.value === otherReferral.value
      );

      if (filteredAllergy.length > 0) {
        errorAlert("Doctor Already Exist!");
        setValue("referralDoctor", null);
      } else if (otherReferralWatch !== undefined) {
        let otherReferralObject = {
          id: otherReferralWatch.id,
          label: otherReferralWatch.label,
          value: otherReferralWatch.value,
        };

        dataArray.push(otherReferralObject);
        setValue("referralDoctor", null);
        setSelectedOtherReferral(dataArray);
      }
    }
  }, [otherReferralWatch]);

  function findNextOtherServices() {
    var universe = document.querySelectorAll("input");
    var list = Array.prototype.filter.call(universe, function (item) {
      return item.tabIndex >= "0";
    });

    let filtered = list.filter(
      (field) => field.id === "react-select-140-input" && field.name === ""
    );

    return filtered[0]?.focus();
  }

  React.useEffect(() => {
    if (watchOtherServices !== null && watchOtherServices !== undefined) {
      findNextOtherServices();
    }
    if (watchOtherServices) {
      let dataArray = [...otherServices];
      let otherService = watchOtherServices;

      const filteredAllergy = dataArray.filter(
        (list) => list.value === otherService.value
      );

      if (filteredAllergy.length > 0) {
        errorAlert("Service Already Exist!");
        setValue("searchOtherServices", null);
      } else if (watchOtherServices !== undefined) {
        let otherServicesObj = {
          label: watchOtherServices.label,
          value: watchOtherServices.value,
        };

        dataArray.push(otherServicesObj);
        setValue("searchOtherServices", null);
        setOtherServices(dataArray);
      }
    }
  }, [watchOtherServices]);

  // React.useEffect(() => {
  //   if (selectedRow !== null) {
  //     let complaints = JSON.parse();
  //     let editComplaints = [];
  //     if (complaints.length > 0) {
  //       for (let comp of complaints) {
  //         let complaintObj = {
  //           label: comp?.complaint,
  //           since: comp?.since,
  //           duration: comp?.duration,
  //           isChecked: true,
  //         };
  //         editComplaints.push(complaintObj);
  //         setSelectedComplaints(editComplaints);
  //       }
  //     }

  //     let diagnosis = JSON.parse();
  //     let editDiagnosis = [];
  //     if (diagnosis.length > 0) {
  //       for (let diagno of diagnosis) {
  //         let diagnosisObj = {
  //           label: diagno?.diagnosis,
  //           since: diagno?.since,
  //           isChecked: true,
  //         };

  //         console.log("EditDiagnosis", diagnosisObj);
  //         editDiagnosis.push(diagnosisObj);
  //         setSelectedDiagnosis(editDiagnosis);
  //       }
  //     }

  //     let investigations = JSON.parse();
  //     let editInvestigation = [];
  //     if (investigations.length > 0) {
  //       for (let inv of investigations) {
  //         let invObj = {
  //           label: inv?.serviceName,
  //           id: inv?.serviceId,
  //           value: inv?.serviceId,
  //         };
  //         editInvestigation.push(invObj);
  //         setSelectedInvestigations(editInvestigation);
  //       }
  //     }

  //     let advices = JSON.parse();
  //     let editAdvices = [];
  //     if (advices.length > 0) {
  //       for (let adv of advices) {
  //         let adviceObj = {
  //           label: adv,
  //         };
  //         editAdvices.push(adviceObj);
  //         setSelectedAdvices(editAdvices);
  //       }
  //     }

  //     let otherRefferal = JSON.parse();
  //     let editOtherRefferal = [];
  //     if (otherRefferal.length > 0) {
  //       for (let otherReff of otherRefferal) {
  //         let otherRefferralObj = {
  //           label: otherReff?.doctorName,
  //           id: otherReff?.doctorId,
  //           value: otherReff?.doctorId,
  //         };

  //         editOtherRefferal.push(otherRefferralObj);
  //         setSelectedOtherReferral(editOtherRefferal);
  //       }
  //     }

  //     let otherServices = JSON.parse();
  //     let editOtherServices = [];
  //     if (otherServices.length > 0) {
  //       for (let otherServ of otherServices) {
  //         let otherServicesObj = {
  //           id: otherServ?.serviceId,
  //           value: otherServ?.serviceId,
  //           label: otherServ?.serviceName,
  //         };

  //         editOtherServices.push(otherServicesObj);
  //         setOtherServices(editOtherServices);
  //       }
  //     }

  //     let medications = JSON.parse();

  //     if (medications.length > 0) {
  //       for (let i = 0; i < medications.lenght; i++) {
  //         let medicationObj = {
  //           id: medications[i]?.id,
  //           Medicine: {
  //             id: medications[i]?.drug_id,
  //             label: medications[i]?.drug_name,
  //             value: medications[i]?.drug_id,
  //             // genericDesc: medications[i]?.genericDescription,
  //             // code: "",
  //             Medicine: medications[i]?.drug_name,
  //           },
  //           Dosage: medications[i]?.dosage,
  //           Frequency: {
  //             id: medications[i]?.frequency_id,
  //             label: medications[i]?.frequency,
  //             value: medications[i]?.frequency_id,
  //           },
  //           Route: {
  //             Id: medications[i]?.route_id,
  //             label: medications[i]?.route_name,
  //             value: medications[i]?.route_id,
  //           },
  //           Duration: medications[i]?.duration,
  //           Quantity: medications[i]?.quantity,
  //           Instructions: {
  //             Id: medications[i]?.instruction_id,
  //             label: medications[i]?.instruction,
  //             value: medications[i]?.instruction_id,
  //           },
  //           Generic: {
  //             id: medications[i]?.generic_id,
  //             label: medications[i]?.generic_name,
  //             value: medications[i]?.generic_id,
  //           },
  //           Form: {
  //             Id: medications[i]?.form_id,
  //             label: medications[i]?.drug_form,
  //             value: medications[i]?.form_id,
  //           },
  //           isDeleted: false,
  //         };
  //         if (medicationObj?.Medicine?.Medicine !== "") {
  //           let dataArray = selectedMedicines;
  //           dataArray.splice(i, 1, medicationObj);
  //           setSelectedMedicines(dataArray);
  //         }

  //         setValue(`medicine[${i}].quantity`, medications[i]?.quantity);
  //         setValue(`medicine[${i}].instructions`, {
  //           Id: medications[i]?.instruction_id,
  //           label: medications[i]?.instruction,
  //           value: medications[i]?.instruction_id,
  //         });

  //         setValue(`medicine[${i}].form`, {
  //           Id: medications[i]?.form_id,
  //           label: medications[i]?.drug_form,
  //           value: medications[i]?.form_id,
  //         });

  //         setValue(`medicine[${i}].generic`, {
  //           id: medications[i]?.generic_id,
  //           label: medications[i]?.generic_name,
  //           value: medications[i]?.generic_id,
  //         });
  //       }
  //     }
  //   }
  // }, [selectedRow]);

  return (
    <div>
      <form
        className="grid grid-cols-6 gap-2 items-center justify-center"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="col-span-2">
          <InputField
            control={control}
            name="templateName"
            label={"Template Name"}
          />
        </div>
        <div className="col-span-2">
          <InputField
            control={control}
            name="description"
            label="Description"
          />
        </div>
        <div className="col-span-2 flex justify-end">
          <CommonButton
            label={"Complete"}
            type={"button"}
            className={"text-white bg-customGreen"}
          />
        </div>

        <div className="shadow-lg shadow-slate-300 col-span-2 rounded h-40">
          <div className=" bg-[#DCFCE7] text-md font-semibold flex justify-between px-2">
            <div
              className="whitespace-nowrap"
              //   onClick={() => {
              //     setOpenComplaints(true);
              //   }}
            >
              Complaints
            </div>
            <fieldset
              className="w-[75%] ml-2 flex gap-2 items-center"
              ref={complaintRef}
            >
              <CustomCreateable
                width={"170px"}
                control={control}
                name={"createComplaint"}
                ref={complaintRef}
                placeholder={"Complaint"}
                dataArray={complaintsOptions}
                isSearchable={true}
                showSearch={true}
                handleInputChange={handleSearchComplaints}
              />
              {editComplaintIndex !== null && (
                <Tooltip title="Update">
                  <button
                    onClick={() => {
                      handleUpdateComplaint();
                    }}
                    className=" rounded-full bg-customBlue text-white"
                  >
                    <NavigateNextIcon />
                  </button>
                </Tooltip>
              )}
            </fieldset>
          </div>
          <div className=" h-[82%] overflow-y-scroll">
            {selectedComplaints?.length > 0
              ? selectedComplaints?.map((obj, index) => {
                  return (
                    <div
                      className={`flex gap-2 justify-between items-center my-1  px-2 border border-gray-200 h-[26px]
                       
                          `}
                    >
                      <div className="flex ">
                        <div>
                          <Tooltip title="Edit Complaint">
                            <button
                              className="h-5 "
                              type="button"
                              onClick={() => {
                                handleEditComplaint(obj, index);
                              }}
                            >
                              <EditIcon />
                            </button>
                          </Tooltip>
                        </div>
                        <div>
                          <button
                            className="h-5 "
                            type="button"
                            onClick={() => {
                              handleDeleteComplaints(obj);
                            }}
                          >
                            <DeleteOutlineIcon
                              fontSize="small"
                              sx={{ color: "red" }}
                            />
                          </button>
                        </div>
                        <div className="w-full flex items-center whitespace-nowrap text-start justify-start text-sm">
                          {obj?.["label"]}
                        </div>
                      </div>
                      <div className="flex gap-2 items-center py-[5px]">
                        <input
                          name={`duration${obj.id}`}
                          style={{ outline: "none" }}
                          disabled={obj?.isChecked === false ? true : false}
                          defaultValue={obj?.since}
                          placeholder="Since"
                          className="text-center h-6 w-[60px] border border-gray-400 rounded"
                          onChange={(e) => {
                            if (Number(e.target.value) > 0) {
                              let dataArray = [
                                ...selectedComplaints,
                                ...complaints,
                              ];
                              dataArray[index].since = e.target.value;
                              dataArray[index].duration = "D";
                              setComplaints(dataArray);
                            } else {
                              let dataArray = [
                                ...selectedComplaints,
                                ...complaints,
                              ];
                              dataArray[index].since = "";
                              dataArray[index].duration = "";
                              setComplaints(dataArray);
                            }
                          }}
                        />
                        <div
                          onClick={() => {
                            handleAddComplaintsDuration("D", index);
                          }}
                          className={`h-5 text-md w-5 rounded-full 
                                 ${
                                   obj.duration === "D" && obj.since !== ""
                                     ? `bg-[#073763] text-white`
                                     : `border border-slate-600`
                                 } flex justify-center items-center text-md cursor-pointer pt-[1px]`}
                        >
                          D
                        </div>
                        <div
                          onClick={() => {
                            handleAddComplaintsDuration("M", index);
                          }}
                          className={`h-5 text-md w-5 rounded-full 
                                ${
                                  obj.duration === "M" && obj.since !== ""
                                    ? `bg-[#073763] text-white`
                                    : `border border-slate-600`
                                } flex justify-center items-center text-md cursor-pointer pt-[1px]`}
                        >
                          M
                        </div>
                        <div
                          onClick={() => {
                            handleAddComplaintsDuration("Y", index);
                          }}
                          className={`h-5 text-md w-5 rounded-full 
                                ${
                                  obj.duration === "Y" && obj.since !== ""
                                    ? `bg-[#073763] text-white`
                                    : `border border-slate-600`
                                } flex justify-center items-center text-md cursor-pointer pt-[1px]`}
                        >
                          Y
                        </div>
                      </div>
                    </div>
                  );
                })
              : ""}
          </div>
        </div>

        <div className="shadow-lg col-span-2 shadow-slate-300 rounded h-40">
          <div className=" bg-[#ECFCCB] text-md font-semibold flex gap-2 px-2 cursor-pointer">
            <div
              className="w-[]"
              //   onClick={() => {
              //     setOpenDiagnosis(true);
              //   }}
            >
              Diagnosis
            </div>
            <div className="flex justify-end w-full">
              <CustomCreateable
                control={control}
                name={"searchDiagnosis"}
                dataArray={diagnoOptions}
                placeholder={"Diagnosis"}
                handleInputChange={handelSearchDiagnosis}
                width={"170px"}
                isSearchable={true}
              />
            </div>
          </div>
          <div className=" h-[82%] overflow-y-scroll">
            {selectedDiagnosis?.length > 0
              ? selectedDiagnosis.map((diagno) => {
                  // if (diagno?.isChecked === true) {
                  console.log(diagno.duration, "selectedDiagnosis");

                  return (
                    <div className="flex gap-2 font-Poppins items-center justify-start border border-gray-200 py">
                      <div>
                        <button
                          // className="h-5 "
                          type="button"
                          onClick={() => {
                            handleDeleteDiagnosis(diagno);
                          }}
                        >
                          <DeleteOutlineIcon
                            fontSize="small"
                            sx={{ color: "red" }}
                          />
                        </button>
                      </div>
                      <div className="mt-[1px]">
                        <div className="w-[70%] font-medium truncate flex justify-start ">
                          {diagno?.label}
                        </div>

                        {diagno.since !== undefined && diagno.duration && (
                          <div>{diagno.since + " " + diagno?.duration}</div>
                        )}
                      </div>
                    </div>
                  );
                  // }
                })
              : ""}
          </div>
        </div>

        <div className="shadow-lg col-span-2 shadow-slate-300 rounded h-40">
          <div className="cursor-pointer bg-[#FFE6C9] text-md font-semibold flex justify-between px-2">
            <div
            //   onClick={() => {
            //     setOpenInvestigation(true);
            //   }}
            >
              Investigations
            </div>
            <div className="w-[65%] z-50">
              <CustomCreateable
                width={"170px"}
                control={control}
                name={"searchInvestigations"}
                placeholder={"Investigations"}
                dataArray={invOptions}
                handleInputChange={handleSearchInvestigations}
                isSearchable={true}
                showSearch={true}
              />
            </div>
          </div>
          <div className="h-[82%] overflow-y-scroll">
            {selectedInvestigations?.length > 0
              ? selectedInvestigations.map((inv) => {
                  setValue(`isUrgent${inv?.id}`, inv?.isUrgent);
                  return (
                    <div className="flex gap-2 items-center border border-gray-200 py h-6">
                      {/* {inv?.isUrgent === false ? (  by Abhijeet sir */}
                      <div>
                        <button
                          className="h-5 "
                          type="button"
                          onClick={() => {
                            handleDeleteInv(inv);
                          }}
                        >
                          <DeleteOutlineIcon
                            fontSize="small"
                            sx={{ color: "red" }}
                          />
                        </button>
                      </div>
                      {/* ) : (
                            ""
                          )} */}

                      <div className="w-[90%] h-5 flex justify-start px-2  whitespace-nowrap truncate">
                        {inv?.label}
                      </div>
                    </div>
                  );
                })
              : ""}
          </div>
        </div>

        <div className="my-2 rounded col-span-6 shadow-lg shadow-slate-300">
          <div>
            <MedicinesTable
              highlightRow={false}
              tableClass="h-[270px]"
              renderInput={renderInput}
              renderActions={renderActions}
              handleAllDurations={handleAllDurations}
              editableColumns={[
                "Medicine",
                "Dosage",
                "Generic",
                "Frequency",
                "Duration",
                "Instructions",
                "Route",
                "Quantity",
                "Form",
                "isDeleted",
              ]}
              removeHeaders={["since", "Since", "", "Days", "Id", "id"]}
              dataResult={selectedMedicines}
            />
          </div>
        </div>

        <div className="rounded shadow-lg col-span-2 shadow-slate-300">
          <div className="col-span-2 h-36 ">
            <div className="  h-9 flex justify-between px-2 pt-1 bg-[#F1F1F1]">
              <div className="text-md font-semibold flex gap-2">Advices</div>
              <div className="w-[65%]">
                <CustomCreateable
                  width={"170px"}
                  control={control}
                  name={"searchAdvices"}
                  placeholder={"Advices"}
                  dataArray={advicesOptions}
                  handleInputChange={() => {}}
                  isSearchable={true}
                  showSearch={true}
                />
              </div>
            </div>
            <div className="font-semibold rounded h-[75%] overflow-y-scroll">
              {selectedAdvices?.length > 0
                ? selectedAdvices.map((advice) => (
                    <div className="flex gap-2 items-center border border-gray-200 h-6">
                      <div>
                        <button
                          className="h-5 "
                          type="button"
                          onClick={() => {
                            handleDeleteAdvice(advice);
                          }}
                        >
                          <DeleteOutlineIcon
                            fontSize="small"
                            sx={{ color: "red" }}
                          />
                        </button>
                      </div>
                      <div className="w-[90%] h-5 flex justify-start px-2 font-normal whitespace-nowrap truncate">
                        {advice?.label}
                      </div>
                    </div>
                  ))
                : ""}
            </div>
          </div>
        </div>

        <div className="shadow-lg col-span-2 shadow-slate-300 rounded">
          <div className="col-span-2 h-36 ">
            <div className="flex justify-between px-2 pt-1 bg-[#F1F1F1]">
              <div className="text-sm lg:text-md font-semibold flex gap-2">
                <div
                //   onClick={() => {
                //     setOpenOtherRefferal(true);
                //   }}
                >
                  Other Referral
                </div>
              </div>

              <div className=" w-[65%]">
                <SearchDropdown
                  control={control}
                  name="referralDoctor"
                  dataArray={referralDocOptions}
                  placeholder={"Referral Doctor"}
                  isClearable={true}
                  searchIcon={true}
                  isSearchable={true}
                  handleInputChange={handleOtherReferral}
                />
              </div>
            </div>
            <div className="h-[75%] overflow-y-scroll">
              {selectedOtherReferral?.length > 0
                ? selectedOtherReferral.map((doc) => (
                    <div className="flex gap-2 items-center border border-gray-200 h-6">
                      <div>
                        <button
                          className="h-5 "
                          type="button"
                          onClick={() => {
                            handleDeleteOtherReferral(doc);
                          }}
                        >
                          <DeleteOutlineIcon
                            fontSize="small"
                            sx={{ color: "red" }}
                          />
                        </button>
                      </div>
                      <div className="w-[90%] h-5 flex justify-start px-2  whitespace-nowrap truncate">
                        {doc?.label}
                      </div>
                    </div>
                  ))
                : ""}
            </div>
          </div>
        </div>

        <div className="shadow-lg col-span-2 shadow-slate-300 rounded">
          <div className=" rounded h-36">
            <div className="flex justify-between bg-[#FFE6C9]">
              <div
                // onClick={() => {
                //   setOpenServices(true);
                // }}
                className="cursor-pointer bg-[#FFE6C9] text-md font-semibold flex justify-start px-2"
              >
                Other Services
              </div>
              <div className="w-[65%]">
                <CustomCreateable
                  width={"170px"}
                  control={control}
                  name={"searchOtherServices"}
                  placeholder={"Other Services"}
                  dataArray={servicesOptions}
                  handleInputChange={handleSearchServices}
                  isSearchable={true}
                  showSearch={true}
                />
              </div>
            </div>

            <div className="h-[75%] overflow-y-scroll">
              {otherServices?.length > 0
                ? otherServices.map((service) => {
                    return (
                      <div className="flex gap-2 items-center border border-gray-200 py h-6">
                        <div>
                          <button
                            className="h-5 "
                            type="button"
                            onClick={() => {
                              handleDeleteOtherService(service);
                            }}
                          >
                            <DeleteOutlineIcon
                              fontSize="small"
                              sx={{ color: "red" }}
                            />
                          </button>
                        </div>

                        <div className="w-[90%] h-5 flex justify-start px-2  whitespace-nowrap truncate">
                          {service?.label}
                        </div>
                      </div>
                    );
                  })
                : ""}
            </div>
          </div>
        </div>
      </form>
      {openConfirmationModal && (
        <ConfirmationModal
          confirmationOpen={openConfirmationModal}
          confirmationHandleClose={() => {
            setOpenConfirmationModal(false);
          }}
          confirmationSubmitFunc={() => {
            handleOnSubmit();
          }}
          confirmationLabel="Confirmation "
          confirmationMsg="Are You Sure ?"
          confirmationButtonMsg="Proceed"
        />
      )}
    </div>
  );
}

export default NewEmrTemplate;

// const renderInput = (row, index, header) => {
//   if (row?.Medicine === "") {
//       setValue(`medicine[${index}].medicine`, null);
//   } else {
//       setValue(`medicine[${index}].medicine`, row?.Medicine);
//   }

//   if (row?.Medicine === "") {
//       setValue(`medicine[${index}].generic`, null);
//       setValue(`medicine[${index}].form`, null);
//   }

//   if (row?.Dosage === "") {
//       setValue(`medicine[${index}].dosage`, "");
//   } else {
//       setValue(`medicine[${index}].dosage`, row?.Dosage);
//   }

//   if (row?.Frequency?.label !== "") {
//       setValue(`medicine[${index}].frequency`, row?.Frequency);
//   } else {
//       setValue(`medicine[${index}].frequency`, null);
//   }

//   setValue(`duration[${index}].since`, row?.since);

//   if (row?.Route === "") {
//       setValue(`medicine[${index}].route`, null);
//   } else {
//       setValue(`medicine[${index}].route`, row?.Route);
//   }

//   if (row?.Duration === "") {
//       setValue(`medicine[${index}].duration`, "");
//       setValue(`medicine[${index}].quantity`, "");
//   } else {
//       setValue(`medicine[${index}].duration`, row?.Duration);
//   }

//   const calculateQuantity = (frequency, duration) => {
//       if (!frequency || !duration) return 0;

//       let frequencyCountValue = 0;
//       let freqArray = frequency.label.split("-");

//       freqArray.forEach((element) => {
//           let value = element.trim();

//           if (value === "1/2" || value === "½") {
//               frequencyCountValue += 0.5;
//           } else if (value === "¼") {
//               frequencyCountValue += 0.25;
//           } else if (value === "1") {
//               frequencyCountValue += 1;
//           } else if (value === "1½") {
//               frequencyCountValue += 1.5;
//           } else if (value === "1/8") {
//               frequencyCountValue += 0.125;
//           } else if (value === "2") {
//               frequencyCountValue += 2;
//           } else if (value === "2½") {
//               frequencyCountValue += 2.5;
//           } else if (value === "3") {
//               frequencyCountValue += 3;
//           } else if (value === "3½") {
//               frequencyCountValue += 3.5;
//           } else if (value === "4") {
//               frequencyCountValue += 4;
//           } else if (value === "4½") {
//               frequencyCountValue += 4.5;
//           } else if (value === "STAT") {
//               frequencyCountValue += 1;
//           } else if (value === "Stat/sos") {
//               frequencyCountValue += 1;
//           } else if (value === "SOS") {
//               frequencyCountValue += 1;
//           }
//       });
//       const quantityCount = Number(duration) * frequencyCountValue;
//       return Math.ceil(quantityCount);
//   };

//   return (
//       <div
//           className={`flex justify-start z-50 ${index !== 0 && selectedMedicines[index - 1]?.Medicine === ""
//               ? "hidden"
//               : ``
//               } `}
//       >
//           {header === "Medicine" ? (
//               <div className="flex justify-start w-[25vw] lg:w-full">
//                   <CustomCreateable
//                       isDisabled={
//                           index !== 0 && selectedMedicines[index - 1]?.Medicine === ""
//                               ? true
//                               : false
//                       }
//                       menuPlacement={"bottom"}
//                       width={"250px"}
//                       control={control}
//                       name={`medicine[${index}].medicine`}
//                       placeholder={"Medicine"}
//                       handleInputChange={handleGetMedicine}
//                       inputRef={{
//                           ...register(`medicine[${index}].medicine`, {
//                               onChange: (e) => {
//                                   let dataArray = [...selectedMedicines];
//                                   if (e.target.value !== null) {
//                                       let genericNameObj = {
//                                           id: e.target.value.genericId,
//                                           value: e.target.value.genericName,
//                                           label: e.target.value.genericName,
//                                       };

//                                       let formObj = {
//                                           Id: e.target.value.formId,
//                                           value: e.target.value.form,
//                                           label: e.target.value.form,
//                                       };

//                                       let filtered = dataArray.filter(
//                                           (item) =>
//                                               item?.Medicine?.label === e.target.value?.label
//                                       );

//                                       if (filtered.length === 0) {
//                                           let newMedicine = {
//                                               id:
//                                                   e.target.value?.__isNew__ === true
//                                                       ? null
//                                                       : e.target.value?.id,
//                                               label: e.target.value?.label,
//                                               value:
//                                                   e.target.value?.__isNew__ === true
//                                                       ? null
//                                                       : e.target.value?.id,
//                                           };

//                                           dataArray[index] = {
//                                               ...dataArray[index],
//                                               Medicine: newMedicine,
//                                               Generic: e.target.value.genericId
//                                                   ? genericNameObj
//                                                   : null,
//                                               Form: e.target.value.formId ? formObj : null,
//                                           };

//                                           if (
//                                               e.target.value.genericId !== null &&
//                                               e.target.value.genericId !== undefined
//                                           ) {
//                                               setValue(
//                                                   `medicine[${index}].generic`,
//                                                   genericNameObj
//                                               );
//                                               clearErrors([`medicine[${index}].generic`]);
//                                           }
//                                           if (
//                                               e.target.value.formId !== null &&
//                                               e.target.value.formId !== undefined
//                                           ) {
//                                               setValue(`medicine[${index}].form`, formObj);
//                                               clearErrors([`medicine[${index}].form`]);
//                                           }
//                                           setSelectedMedicines(dataArray);
//                                           const nextField = document.querySelector(
//                                               `input[name="medicine[${index}].dosage"]`
//                                           );
//                                           nextField?.focus();
//                                       } else {
//                                           setValue(`medicine[${index}].medicine`, null);
//                                           errorAlert("Medicine / Drug already exists");
//                                       }
//                                   } else {
//                                       setValue(`medicine[${index}].generic`, null);
//                                       setValue(`medicine[${index}].form`, null);
//                                       dataArray[index] = {
//                                           ...dataArray[index],
//                                           Medicine: "",
//                                           Generic: null,
//                                           Form: null,
//                                       };
//                                       setSelectedMedicines(dataArray);
//                                   }
//                               },
//                           }),
//                       }}
//                       dataArray={searchMedicines}
//                       isSearchable={true}
//                   />
//               </div>
//           ) : null}

//           {header === "Dosage" ? (
//               <div className="flex justify-start w-full ml-2">
//                   <input
//                       className="h-7 w-32 border border-gray-400 rounded px-2"
//                       style={{ outline: "none" }}
//                       placeholder="Dosage"
//                       name={`medicine[${index}].dosage`}
//                       {...register(`medicine[${index}].dosage`)}
//                       onChange={(e) => {
//                           let dataArray = [...selectedMedicines];
//                           dataArray[index].Dosage = e.target.value;
//                           setSelectedMedicines(dataArray);
//                       }}
//                       onKeyDown={(e) => {
//                           if (e.key === "Enter") {
//                               e.preventDefault();
//                               const nextField = document.querySelector(
//                                   `[name="medicine[${index}].frequency"]`
//                               )?.closest('.w-full');
//                               if (nextField) {
//                                   const selectInput = nextField.querySelector('input');
//                                   if (selectInput) {
//                                       selectInput.focus();

//                                       const selectDiv = nextField.querySelector('.css-1s2u09g-control');
//                                       if (selectDiv) {
//                                           selectDiv.click();
//                                       }
//                                   }
//                               }
//                           }
//                       }}
//                   />
//               </div>
//           ) : null}

//           {header === "Frequency" ? (
//               <div className="flex justify-start ml-2 w-[20vw] xl:w-full">
//                   <CustomCreateable
//                       isDisabled={
//                           index !== 0 && selectedMedicines[index - 1]?.Medicine === ""
//                               ? true
//                               : false
//                       }
//                       width={"170px"}
//                       control={control}
//                       name={`medicine[${index}].frequency`}
//                       placeholder={"Frequency"}
//                       handleInputChange={() => { }}
//                       isClearable={true}
//                       inputRef={{
//                           ...register(`medicine[${index}].frequency`, {
//                               onChange: (e) => {
//                                   if (
//                                       e.target.value !== null &&
//                                       e.target.value !== undefined
//                                   ) {
//                                       let dataArray = [...selectedMedicines];
//                                       dataArray[index].Frequency = e.target.value;

//                                       const duration = dataArray[index].Duration;
//                                       if (duration) {
//                                           const quantity = calculateQuantity(
//                                               e.target.value,
//                                               duration
//                                           );
//                                           setValue(`medicine[${index}].quantity`, quantity);
//                                           dataArray[index].Quantity = quantity;
//                                       }

//                                       setSelectedMedicines(dataArray);
//                                       const nextField = document.querySelector(
//                                           `[name="medicine[${index}].route"]`
//                                       )?.closest('.w-full');

//                                       if (nextField) {
//                                           const routeInput = nextField.querySelector('input');
//                                           if (routeInput) {
//                                               routeInput.focus();
//                                           }
//                                       }
//                                   } else {
//                                       let dataArray = [...selectedMedicines];
//                                       dataArray[index].Frequency = "";
//                                       setValue(`medicine[${index}].quantity`, 0);
//                                       dataArray[index].Quantity = 0;
//                                       setSelectedMedicines(dataArray);
//                                   }
//                               },
//                           }),
//                       }}
//                       dataArray={frequencyOptions}
//                       isSearchable={true}
//                   />
//               </div>
//           ) : null}

//           {header === "Route" ? (
//               <div className="flex justify-start w-[17vw] xl:w-full ml-2">
//                   <CustomCreateable
//                       isDisabled={
//                           index !== 0 && selectedMedicines[index - 1]?.Medicine === ""
//                               ? true
//                               : false
//                       }
//                       width={"170px"}
//                       control={control}
//                       name={`medicine[${index}].route`}
//                       placeholder={"Route"}
//                       handleInputChange={() => { }}
//                       inputRef={{
//                           ...register(`medicine[${index}].route`, {
//                               onChange: (e) => {
//                                   let dataArray = [...selectedMedicines];
//                                   dataArray[index].Route = e.target.value;
//                                   setSelectedMedicines(dataArray);
//                                   const nextField = document.querySelector(
//                                       `input[name="medicine[${index}].duration"]`
//                                   );
//                                   nextField?.focus();
//                               },
//                           }),
//                       }}
//                       dataArray={routes}
//                       isSearchable={true}
//                       isClearable={true}
//                   />
//               </div>
//           ) : null}

//           {header === "Duration" ? (
//               <div className="flex gap-2 items-center ml-2">
//                   <input
//                       disabled={
//                           index !== 0 && selectedMedicines[index - 1]?.Medicine === ""
//                               ? true
//                               : false
//                       }
//                       name={`medicine[${index}].duration`}
//                       {...register(`medicine[${index}].duration`)}
//                       style={{ outline: "none" }}
//                       placeholder="Duration"
//                       className="text-center w-24 border border-gray-400 rounded"
//                       onChange={(e) => {
//                           let dataArray = [...selectedMedicines];
//                           dataArray[index].Duration = e.target.value;
//                           const frequency = dataArray[index].Frequency;
//                           if (frequency) {
//                               const quantity = calculateQuantity(frequency, e.target.value);
//                               setValue(`medicine[${index}].quantity`, quantity);
//                               dataArray[index].Quantity = quantity;
//                           }

//                           setSelectedMedicines(dataArray);
//                       }}

//                       onKeyDown={(e) => {
//                           if (e.key === "Enter") {
//                               e.preventDefault();
//                               const nextField = document.querySelector(
//                                   `[name="medicine[${index}].instructions"]`
//                               )?.closest('.w-full');
//                               if (nextField) {
//                                   const selectInput = nextField.querySelector('input');
//                                   if (selectInput) {
//                                       selectInput.focus();

//                                       const selectDiv = nextField.querySelector('.css-1s2u09g-control');
//                                       if (selectDiv) {
//                                           selectDiv.click();
//                                       }
//                                   }
//                               }
//                           }
//                       }}
//                   />
//                   <div className="px-3 rounded-full bg-[#073763] text-sm py-0.5 text-white">
//                       Days
//                   </div>
//               </div>
//           ) : null}

//           {header === "Quantity" ? (
//               <div className="flex justify-start ml-2">
//                   <input
//                       className="h-7 w-20 border border-gray-400 rounded px-2"
//                       style={{ outline: "none" }}
//                       placeholder="Qty"
//                       name={`medicine[${index}].quantity`}
//                       {...register(`medicine[${index}].quantity`)}
//                       disabled={true}
//                   />
//               </div>
//           ) : null}

//           {header === "Instructions" ? (
//               <div className="flex justify-start ml-2 w-[20vw] xl:w-full">
//                   <CustomCreateable
//                       isDisabled={
//                           index !== 0 && selectedMedicines[index - 1]?.Medicine === ""
//                               ? true
//                               : false
//                       }
//                       control={control}
//                       name={`medicine[${index}].instructions`}
//                       placeholder={"Instructions"}
//                       handleInputChange={() => { }}
//                       inputRef={{
//                           ...register(`medicine[${index}].instructions`, {
//                               onChange: (e) => {
//                                   let dataArray = [...selectedMedicines];
//                                   dataArray[index].Instructions = e.target.value;
//                                   setSelectedMedicines(dataArray);
//                                   const nextField = document.querySelector(
//                                       `[name="medicine[${index}].generic"]`
//                                   )?.closest('.w-full');

//                                   if (nextField) {
//                                       const routeInput = nextField.querySelector('input');
//                                       if (routeInput) {
//                                           routeInput.focus();
//                                       }
//                                   }
//                               },
//                           }),
//                       }}
//                       dataArray={medicineInstructions}
//                       isSearchable={true}
//                       isClearable={true}
//                   />
//               </div>
//           ) : null}

//           {header === "Generic" ? (
//               <div className="flex justify-start pl-2 w-[20vw] xl:w-full">
//                   <CustomCreateable
//                       control={control}
//                       name={`medicine[${index}].generic`}
//                       isSearchable={true}
//                       placeholder={"Generic"}
//                       isClearable={true}
//                       handleInputChange={handleGetGenerics}
//                       inputRef={{
//                           ...register(`medicine[${index}].generic`, {
//                               onChange: (e) => {
//                                   let dataArray = [...selectedMedicines];
//                                   dataArray[index].Generic = e.target.value;
//                                   setSelectedMedicines(dataArray);
//                                   const nextField = document.querySelector(
//                                       `[name="medicine[${index}].form"]`
//                                   )?.closest('.w-full');

//                                   if (nextField) {
//                                       const routeInput = nextField.querySelector('input');
//                                       if (routeInput) {
//                                           routeInput.focus();
//                                       }
//                                   }
//                               },
//                           }),
//                       }}
//                       dataArray={genericDrugs}
//                   />
//               </div>
//           ) : null}

//           {header === "Form" ? (
//               <div className="flex justify-start w-[18vw] xl:w-full ml-2">
//                   <CustomCreateable
//                       isDisabled={
//                           index !== 0 && selectedMedicines[index - 1]?.Medicine === ""
//                               ? true
//                               : false
//                       }
//                       width={"170px"}
//                       control={control}
//                       name={`medicine[${index}].form`}
//                       placeholder={"Form"}
//                       handleInputChange={() => { }}
//                       inputRef={{
//                           ...register(`medicine[${index}].form`, {
//                               onChange: (e) => {
//                                   let dataArray = [...selectedMedicines];
//                                   dataArray[index].Form = e.target.value;
//                                   setSelectedMedicines(dataArray);
//                               },
//                           }),
//                       }}
//                       dataArray={formOpt}
//                       isSearchable={true}
//                       isClearable={true}
//                   />
//               </div>
//           ) : null}
//       </div>
//   );
// };
