import React, { useEffect, useRef } from "react";
import { GeneratePrint } from "../../common/components/printModal/GeneratePrint";
import { InamdarHospiLogo } from "../../assets/printAssets/InmadarHospiLogo";
import { Checkbox } from "@mui/material";

export default function OtNotePrint(props) {
  const printRef = useRef(null);

  useEffect(() => {
    printRef && GeneratePrint(printRef);
  }, [props.otDetailsId]);

  return (
    <div ref={printRef}>
      <table className="w-full">
        <thead
          className=" w-full"
          style={{
            "-fs-table-paginate": "paginate",
          }}
        >
          <div className=" text-center -mt-11">
            <div className=" flex justify-end">
              <InamdarHospiLogo />
            </div>
            <div className=" border-t-[1px] border-black">
              <h1 className=" font-bold text-[17px] ">OPERATION NOTES</h1>
            </div>
          </div>
          <div>
            <div className=" border-t-[1px] text-[14px] border-b-[1px] border-black grid grid-cols-[14%_1%_35%_14%_1%_35%] ">
              <div className="font-semibold">Mr.No</div>
              <div className="font-semibold">:</div>
              <div>{props.otDetailsId?.MRNo}</div>
              <div className="font-semibold">Patient Name</div>
              <div className="font-semibold">:</div>
              <div>{props.otDetailsId?.["Patient Name"]}</div>
              <div className="font-semibold">OT Sheet.No</div>
              <div className="font-semibold">:</div>
              <div>{props.otDetailsId?.OTSheetNo}</div>
              <div className="font-semibold">Bed No</div>
              <div className="font-semibold">:</div>
              <div>{props.otDetailsId?.BedNo}</div>
              <div className="font-semibold">Adm Date</div>
              <div className="font-semibold">:</div>
              <div>{props.otDetailsId?.AdmissionDate}</div>
              <div className="font-semibold">OT Date</div>
              <div className="font-semibold">:</div>
              <div>{props.otDetailsId?.OTDate}</div>
            </div>
          </div>
        </thead>
        <tbody className="w-full">
          <div className="text-[14px] flex w-full">
            <div className="mt-2 w-36">Procedure Name&nbsp;:&nbsp;</div>
            <div className="w-full">
              <p className=" border-b-[1px] border-black pt-8" />
              <p className=" border-b-[1px] border-black pt-8" />
              <p className=" border-b-[1px] border-black pt-8" />
            </div>
          </div>
          <div className="flex space-x-2 w-full">
            <div className="text-[14px] flex w-full">
              <div className="mt-2 w-40">Surgeon Name&nbsp;:&nbsp;</div>
              <div className="w-full">
                <p className=" border-b-[1px] border-black pt-8" />
              </div>
            </div>
            <div className="text-[14px] flex w-full">
              <div className="mt-2 w-40 flex justify-end">
                Assistant&nbsp;:&nbsp;
              </div>
              <div className="w-full">
                <p className=" border-b-[1px] border-black pt-8" />
              </div>
            </div>
          </div>
          <div className="flex space-x-3 w-full">
            <div className="text-[14px] flex w-full">
              <div className="mt-2 w-40">Anaesthetist 1)&nbsp;:&nbsp;</div>
              <div className="w-full">
                <p className=" border-b-[1px] border-black pt-8" />
              </div>
            </div>
            <div className="text-[14px] flex w-full">
              <div className="mt-2 w-40">Anaesthetist 2)&nbsp;:&nbsp;</div>
              <div className="w-full">
                <p className=" border-b-[1px] border-black pt-8" />
              </div>
            </div>
          </div>
          <div className="text-[14px] flex w-full">
            <div className="mt-2 w-32">Anaesthesia&nbsp;:&nbsp;</div>
            <div className="w-full">
              <p className="border-b-[1px] border-black pt-8" />
            </div>
          </div>
          <div className="flex space-x-3 w-full">
            <div className="text-[14px] flex w-full">
              <div className="mt-2 w-40">Scrub Nurse 1)&nbsp;:&nbsp;</div>
              <div className="w-full">
                <p className=" border-b-[1px] border-black pt-8" />
              </div>
            </div>
            <div className="text-[14px] flex w-full">
              <div className="mt-2 w-40">Scrub Nurse 2)&nbsp;:&nbsp;</div>
              <div className="w-full">
                <p className=" border-b-[1px] border-black pt-8" />
              </div>
            </div>
          </div>
          <div className="text-[14px] flex w-full">
            <div className="mt-2 w-32">Diagnosis&nbsp;:&nbsp;</div>
            <div className="w-full">
              <p className=" border-b-[1px] border-black pt-8" />
              <p className=" border-b-[1px] border-black pt-8" />
            </div>
          </div>
          <div>
            <div className=" text-[14px] my-2">
              <span className="font-semibold">Operative Findings </span>
              <span>
                {" "}
                (Use this space for mentioning important findings of the
                procedure) :-
              </span>
              <p className=" border-b-[1px] border-black pt-8" />
              <p className=" border-b-[1px] border-black pt-8" />
              <p className=" border-b-[1px] border-black pt-8" />
              <p className=" border-b-[1px] border-black pt-8" />
              <p className=" border-b-[1px] border-black pt-8" />
              <p className=" border-b-[1px] border-black pt-8" />
            </div>
          </div>
          <div>
            <div className=" text-[14px] my-2">
              <span className="font-semibold">
                Intra Operative Events (If any):-
              </span>
              <p className=" border-b-[1px] border-black pt-8" />
              <p className=" border-b-[1px] border-black pt-8" />
              <p className=" border-b-[1px] border-black pt-8" />
              <p className=" border-b-[1px] border-black pt-8" />
              <p className=" border-b-[1px] border-black pt-8" />
              <p className=" border-b-[1px] border-black pt-8" />
            </div>
          </div>

          <div className="break-before-page ">
            <div className=" p-2 text-[14px] items-center">
              <span>
                Specimen to be sent for Histopathological Examination{" "}
              </span>
              Yes <Checkbox />
              No <Checkbox />
            </div>
            <div className="">
              <table className=" text-[14px] w-full">
                <tr className=" border-black border-[1px]">
                  <th className=" border-r-[1px] border-black">Specimen</th>
                  <th>Test to be conducted</th>
                </tr>
                <tr className=" border-r-[1px] border-l-[1px] border-b-[1px] border-black">
                  <td className=" border-r-[1px] border-black">&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr className=" border-r-[1px] border-l-[1px] border-b-[1px] border-black">
                  <td className=" border-r-[1px] border-black">&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr className=" border-r-[1px] border-l-[1px] border-b-[1px] border-black">
                  <td className=" border-r-[1px] border-black">&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr className=" border-r-[1px] border-l-[1px] border-b-[1px] border-black">
                  <td className=" border-r-[1px] border-black">&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
              </table>
            </div>
            <div className="border-[1px] border-black my-2 ">
              <div className=" flex justify-center space-x-3">
                <h1 className=" font-semibold text-[14px]">
                  Tick the Classification of case: Clean <Checkbox />
                </h1>
                <h1 className=" font-semibold text-[14px]">
                  / Clean-Contaminated <Checkbox />
                </h1>
                <h1 className=" font-semibold text-[14px]">
                  / Contaminated <Checkbox />
                </h1>
                <h1 className=" font-semibold text-[14px]">
                  / Dirty-Infected <Checkbox />
                </h1>
              </div>

              <div className="p-1 space-x-2 text-[14px] flex">
                <p>
                  <spa className=" whitespace-nowrap font-semibold">
                    Clean -{" "}
                  </spa>
                  An uninfected operative wound in which no inflammation is
                  encountered and the respiratory, alimentary, genital, or
                  uninfected urinary tract is not entered. In addition, clean
                  wounds are primarily closed and, if necessary, drained with
                  closed drainage. Operative incisional wounds that follow
                  nonpenetrating (blunt) trauma should be included in this
                  category if they meet the criteria.
                </p>
              </div>
              <div className="p-1 space-x-2 text-[14px] flex">
                <p>
                  <spa className=" whitespace-nowrap font-semibold">
                    C lean-Contaminated -
                  </spa>
                  An operative wound in which the respiratory, alimentary,
                  genital, or urinary tracts are entered under controlled
                  conditions and without unusual contamination. Specifically,
                  operations involving the biliary tract, appendix, vagina, and
                  oropharynx are included in this category, provided no evidence
                  of infection or major break in technique is encountered.
                </p>
              </div>
              <div className="p-1 space-x-2 text-[14px] flex">
                <p>
                  <spa className=" whitespace-nowrap font-semibold">
                    Contaminated -
                  </spa>
                  Open, fresh, accidental wounds. In addition, operations with
                  major breaks in sterile technique (e.g., open cardiac massage)
                  or gross spillage from the gastrointestinal tract, and
                  incisions in which acute, nonpurulent inflammation is
                  encountered are included in this category.
                </p>
              </div>
              <div className="p-1 space-x-2 text-[14px] flex">
                <p>
                  <spa className=" whitespace-nowrap font-semibold">
                    {" "}
                    Dirty -{" "}
                  </spa>
                  Old traumatic wounds with retained devitalized tissue and
                  those that involve existing clinical infection or perforated
                  viscera. This definition suggests that the organisms causing
                  postoperative infection were present in the operative field
                  before the operation.
                </p>
              </div>
              <h1 className="p-2 text-[14px]">
                http://www.cdc.gov/hicpac/SSI/table7-8-9-10-SSI.html
              </h1>
            </div>
          </div>
          <div className="break-before-page ">
            <h1 className=" text-[14px] my-3 font-semibold">
              Operative Details (Use this space mentioning the procedure
              details):-
            </h1>
            <p className=" border-b-[1px] border-black pt-8" />
            <p className=" border-b-[1px] border-black pt-8" />
            <p className=" border-b-[1px] border-black pt-8" />
            <p className=" border-b-[1px] border-black pt-8" />
            <p className=" border-b-[1px] border-black pt-8" />
            <p className=" border-b-[1px] border-black pt-8" />
            <p className=" border-b-[1px] border-black pt-8" />
            <p className=" border-b-[1px] border-black pt-8" />
            <p className=" border-b-[1px] border-black pt-8" />
            <p className=" border-b-[1px] border-black pt-8" />
            <p className=" border-b-[1px] border-black pt-8" />
            <p className=" border-b-[1px] border-black pt-8" />
            <p className=" border-b-[1px] border-black pt-8" />
            <p className=" border-b-[1px] border-black pt-8" />
            <p className=" border-b-[1px] border-black pt-8" />
            <p className=" border-b-[1px] border-black pt-8" />
            <p className=" border-b-[1px] border-black pt-8" />
            <p className=" border-b-[1px] border-black pt-8" />
            <p className=" border-b-[1px] border-black pt-8" />
            <p className=" border-b-[1px] border-black pt-8" />
            <p className=" border-b-[1px] border-black pt-8" />
            <p className=" border-b-[1px] border-black pt-8" />
            <p className=" border-b-[1px] border-black pt-8" />
          </div>
          <div className="break-before-page ">
            <h1 className=" text-[14px] my-3 font-semibold">
              Operative Details (Use this space mentioning the procedure
              details):-
            </h1>
            <p className=" border-b-[1px] border-black pt-8" />
            <p className=" border-b-[1px] border-black pt-8" />
            <p className=" border-b-[1px] border-black pt-8" />
            <p className=" border-b-[1px] border-black pt-8" />
            <p className=" border-b-[1px] border-black pt-8" />
            <p className=" border-b-[1px] border-black pt-8" />
            <p className=" border-b-[1px] border-black pt-8" />
            <p className=" border-b-[1px] border-black pt-8" />
            <p className=" border-b-[1px] border-black pt-8" />
            <p className=" border-b-[1px] border-black pt-8" />
            <p className=" border-b-[1px] border-black pt-8" />
            <p className=" border-b-[1px] border-black pt-8" />
            <p className=" border-b-[1px] border-black pt-8" />
            <p className=" border-b-[1px] border-black pt-8" />
            <p className=" border-b-[1px] border-black pt-8" />
            <p className=" border-b-[1px] border-black pt-8" />
            <p className=" border-b-[1px] border-black pt-8" />
            <p className=" border-b-[1px] border-black pt-8" />
            <p className=" border-b-[1px] border-black pt-8" />
            <p className=" border-b-[1px] border-black pt-8" />
            <p className=" border-b-[1px] border-black pt-8" />
            <p className=" border-b-[1px] border-black pt-8" />
            <p className=" border-b-[1px] border-black pt-8" />
          </div>
          <div className="break-before-page ">
            <h1 className=" text-[14px] my-3 font-semibold">
              Operative Details (Use this space mentioning the procedure
              details):-
            </h1>
            <h1 className=" text-[14px] font-semibold">
              Specific Post-Operative Instructions:-
            </h1>
            <p className=" border-b-[1px] border-black pt-8" />
            <p className=" border-b-[1px] border-black pt-8" />
            <p className=" border-b-[1px] border-black pt-8" />
            <p className=" border-b-[1px] border-black pt-8" />
            <p className=" border-b-[1px] border-black pt-8" />
            <p className=" border-b-[1px] border-black pt-8" />
            <p className=" border-b-[1px] border-black pt-8" />
            <p className=" border-b-[1px] border-black pt-8" />
            <p className=" border-b-[1px] border-black pt-8" />
            <p className=" border-b-[1px] border-black pt-8" />
            <p className=" border-b-[1px] border-black pt-8" />
            <p className=" border-b-[1px] border-black pt-8" />
            <p className=" border-b-[1px] border-black pt-8" />
            <p className=" border-b-[1px] border-black pt-8" />
            <div className=" flex justify-between">
              <div className="text-[14px]">
                <span className=" font-semibold">Shift To :</span>
                <span>Ward</span>
                <Checkbox />
                <br />
                <h1>_______________________</h1>
                <h1 className=" font-semibold">Surgeon's Signature</h1>
              </div>
              <div className="text-[14px]">
                <span className=" font-semibold">Intensive Care Unit</span>
                <Checkbox />
                <h1>_______________________</h1>
                <h1 className=" font-semibold">Date & Time</h1>
              </div>
            </div>
          </div>
        </tbody>
      </table>
    </div>
  );
}
