import { yupResolver } from "@hookform/resolvers/yup";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { Checkbox, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import SearchIconButton from "../../../common/components/Buttons/SearchIconButton";
import DropdownField from "../../../common/components/FormFields/DropdownField";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import MLCModalEdit from "../../../common/components/mlcDetails/MLCModalEdit";
import BarCodePrint from "../../../common/components/printBarcode/BarCodePrint";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  cabinDropdown,
  doctorDropdown,
  unitDropdown,
  visitDetailsBarcode,
} from "../../services/patientappointmentservices/PatientVisitListServices";

import VisibilityIcon from "@mui/icons-material/Visibility";
import { format } from "date-fns";
import {
  BarcodeIcon,
  BarcodeOnIcon,
  EyeOffIcon,
  EyeOnIcon,
  PrintOnIcon,
} from "../../../assets/icons/CustomIcons";
import { MlcIcon } from "../../../assets/icons/MlcIcon";
import CommonDynamicTablePaginationNew from "../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";
import SearchBar from "../../../common/components/FormFields/SearchBar";
import LoadingSpinner from "../../../common/components/loadingspinner/loadingSpinner";
import CommonPrintModal from "../../../common/components/printModal/CommonPrintModal";
import { fetchEmergencyMlcPrint } from "../../../emergency/services/emergencyservice/EmergencyServices";
import {
  casePaperReportPrint,
  fetchAllVisitList,
  searchVisitList,
} from "../../../opd/services/patientappointmentservices/PatientVisitListServices";
import ViewAppointmentsModal from "../AppointmentBooking/ViewAppointmentsModal";
import { PreAssessmentIcon } from "../../../assets/CommonAssets";
import PreAssessmentModal from "../patientqueue/PreAssessmentModal";
const removeHeaders = [
  "Id",
  "PatientId",
  "Cabin",
  "Authority Name",
  "PoliceStation",
  "VisitId",
  "WaitingTime",
  "isDeath",
  "MLC No",
  "BodyHandOverDate",
  "BodyHandOverTime",
  "GenderId",
  "mlcDetails",
  "EmergencyId",
  "AuthorutyBuckleNo",
  "BodyHandOverBuckelNo",
  "BodyHandOverPoliceStation",
  "cmoHandingOverBody",
  "Reporting Date",
  "Reporting Time",
  "mlcTypeId",
  "broughtBy",
  "BodyHandOver Time",
  "BodyHandOver Date",
  "mlcType",
  "TariffId",
  "UnitId",
  "MLCId",
  "GenderId",
  "TariffId",
  "PatientCatagoryId",
  "PatientSourceId",
  "CompanyId",
  "DepartmentId",
];
export default function PatientVisitList(props) {
  const location = useLocation();
  const { updateComponent } = props;

  /******************Use Form Destrecturing  *************/
  const schema = yup.object().shape({});
  const defaultValues = {
    fromDateCalender: new Date(),
    toDateCalender: new Date(),
    doctor: null,
    cabin: null,
    unit: null,
  };

  const {
    control,
    reset,
    register,
    watch,
    setValue,

    formState: {},
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  let getFromDate = format(
    new Date(watch("fromDateCalender")),
    "yyyy-MM-dd'T'hh:mm:ss.SSS'Z'"
  );
  let getToDate = format(
    new Date(watch("toDateCalender")),
    "yyyy-MM-dd'T'hh:mm:ss.SSS'Z'"
  );
  /******************state variable *************/
  const [userActions, setUserActions] = useState([]);
  const [openPrintModal, setOpenPrintModal] = React.useState();
  const [printUrl, setPrintUrl] = React.useState();
  const [options, setOptions] = useState([]);
  const [doctor, setDoctor] = useState();
  const [unit, setUnit] = useState();
  const [cabin, setCabin] = useState();
  const [data, setData] = React.useState({ result: [] });
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [dataResult, setDataResult] = React.useState([]);
  const [count, setCount] = useState();
  const [searchString, setSearchString] = useState("");
  const [openMLC, setOpenMLC] = React.useState(false);
  const [isFromVisitList, setIsFromVisitList] = React.useState(false);
  const [selectedMlcData, setSelectedMlcData] = React.useState(false);
  const [admissionId, setAdmissionId] = React.useState(null);
  const [patientInfo, setPatientInfo] = React.useState(null);
  const [patientData, setpatientData] = React.useState(null);
  const [loadingSpinner, setLoadingSpinner] = React.useState(false);
  const [privilege, setprivilege] = React.useState();
  const [recordWarning, showRecordWarning] = React.useState(false);
  const [openAppointmentListModal, setOpenAppointmentListModal] =
    useState(false);
  /******************Other variables************/
  let doctorVal = watch("doctor");
  let cabinVal = watch("cabin");
  let unitVal = watch("unit");

  const navigate = useNavigate();
  /******************useEffects************/

  //used for fetch apdateed data at midnight
  useEffect(() => {
    if (updateComponent) {
      setValue("fromDateCalender", new Date());
      setValue("toDateCalender", new Date());
    }
  }, [updateComponent]);

  // dropdown
  useEffect(() => {
    getDoctorDropdownList();
    getCabinDropdownList();
    getUnitDropdownList();
  }, []);
  //visit listing
  useEffect(() => {
    let listObj = {
      cabin: cabinVal ? cabinVal.label : null,
      doctorId: doctorVal ? doctorVal.id : null,
      fromDate: getFromDate,
      menuId: location?.state?.menuId,
      page: 0,
      searchId: searchString?.id ? searchString?.id : "",
      searchString: "",
      size: rowsPerPage,
      toDate: getToDate,
      unitId: unitVal ? unitVal.id : null,
    };

    console.log(listObj, "listObj");

    setLoadingSpinner(true);
    showRecordWarning(false);
    fetchAllVisitList(listObj)
      .then((response) => response.data)
      .then((res) => {
        setData(res);
        setCount(res.count);
        setUserActions(res.actions);
        setDataResult(res.result);
        setLoadingSpinner(false);
      })
      .catch(() => {
        setLoadingSpinner(false);
        showRecordWarning(true);
      });
  }, [getFromDate, getToDate, searchString, doctorVal, unitVal]);

  // set default login unit to dropdown
  useEffect(() => {
    let userInformation = JSON.parse(localStorage.getItem("userInfo"));

    let unitObj = {
      id: userInformation.unitId,
      label: userInformation.unitName,
      value: userInformation.unitName,
    };

    setValue("unit", unitObj);
  }, []);

  /******************Functions************/
  // open print modal
  const handleOpenPrintModal = (row, index, type) => {
    let reqireVisitId = row?.VisitId;

    type === "casePaper" &&
      casePaperReportPrint(reqireVisitId).then((response) => {
        setOpenPrintModal(true);
        setPrintUrl(response);
      });

    type === "barcode" &&
      visitDetailsBarcode(reqireVisitId).then((response) => {
        setOpenPrintModal(true);
        setPrintUrl(response);
      });

    if (type === "mlc") {
      //let id = row.AdmissionId > 0 ? row.AdmissionId : row.EmergencyId;
      //let formId = row.AdmissionId > 0 ? 1 : 2;

      fetchEmergencyMlcPrint(row.VisitId, 1).then((response) => {
        setPrintUrl(response);
        setOpenPrintModal(true);
      });
    }
  };

  const handleClosePrintModal = () => {
    setOpenPrintModal(false);
  };

  //populate the CommonMasterTable using the function populateTable
  const populateTable = (forPagination) => {
    let listObj = {
      cabin: cabinVal ? cabinVal.label : null,
      doctorId: doctorVal ? doctorVal.id : null,
      fromDate: getFromDate,
      menuId: location?.state?.menuId,
      page: !forPagination ? 0 : page,
      searchId: searchString?.id ? searchString?.id : null,
      searchString: "",
      size: rowsPerPage,
      toDate: getToDate,
      unitId: unitVal ? unitVal.id : null,
    };

    console.log(listObj, "listObj");

    setLoadingSpinner(true);
    showRecordWarning(false);
    fetchAllVisitList(listObj)
      .then((response) => {
        setCount(response.data.count);
        return response.data;
      })
      .then((res) => {
        setLoadingSpinner(false);

        setUserActions(res.actions);

        if (forPagination) {
          setDataResult((prevData) => [...prevData, ...res.result]);
        } else {
          setDataResult(res.result);
          setPage(0);
        }
      })
      .catch(() => {
        setLoadingSpinner(false);
        showRecordWarning(true);
      });
  };

  function handleChange(searchString) {
    let searchObj = {
      doctorId: doctorVal ? doctorVal.id : null,
      fromDate: getFromDate,
      searchString: searchString,
      toDate: getToDate,
    };

    searchVisitList(searchObj)
      .then((response) => response.data)
      .then((res) => {
        setOptions(res?.result);
      })
      .catch((response) => {});
  }
  function autoSelectedValue(selectedValue) {
    if (selectedValue !== "") {
      setSearchString(selectedValue);
    } else if (selectedValue === null) {
      setSearchString("");
    }
  }

  const handleOpenMLCModal = () => {
    setOpenMLC(true);
  };
  const handleCloseMLCModal = () => {
    setOpenMLC(false);
  };

  //function to call dropdown doctor
  const getDoctorDropdownList = (searchDoctorString) => {
    if (searchDoctorString !== "") {
      doctorDropdown(searchDoctorString)
        .then((response) => response.data)
        .then((res) => {
          if (searchDoctorString !== null) {
            setDoctor(res.result);
          }
        });
    }
  };

  //function to call cabin service
  const getCabinDropdownList = () => {
    cabinDropdown()
      .then((response) => response.data)
      .then((res) => {
        setCabin(res.result);
      });
  };
  //function to call unit service
  const getUnitDropdownList = () =>
    unitDropdown()
      .then((response) => response.data)
      .then((res) => {
        setUnit(res.result);
      });

  /////
  const renderActions = (row, index) => {
    return (
      <div className="flex gap-2 items-center cursor-pointer">
        {userActions &&
          userActions.map((actions, i) => (
            <>
              {actions.isAction === true ? (
                <>
                  <div className="flex gap-1 items-center cursor-pointer">
                    {actions.action === "Edit" ? (
                      <>
                        <Tooltip title="Edit">
                          <DriveFileRenameOutlineIcon
                            className="text-customBlue"
                            onClick={() => {
                              navigate(`/opd/registration`, {
                                state: {
                                  row: row,
                                  menuId: actions.menuId,
                                  isEdit: true,
                                },
                              });
                            }}
                          />
                        </Tooltip>
                      </>
                    ) : (
                      ""
                    )}
                    {actions.action === "Print" ? (
                      <>
                        <Tooltip title="Print Case Paper">
                          <button
                            onClick={() => {
                              handleOpenPrintModal(row, index, "casePaper");
                            }}
                          >
                            <PrintOnIcon />
                          </button>
                        </Tooltip>
                      </>
                    ) : (
                      ""
                    )}
                    {actions.action === "MLC" ? (
                      <>
                        {/* <Tooltip title="MLC" className=""> */}
                        <button
                          onClick={() => {
                            setOpenMLC(true);
                            setSelectedMlcData(row);
                            setpatientData(row);
                            setAdmissionId(row.VisitId);
                            setPatientInfo(row);
                            setprivilege(actions.action);
                          }}
                        >
                          {row["MLC No"] === null ? (
                            <div className="flex items-center">
                              <Tooltip title="Add MLC">
                                <MlcIcon />
                              </Tooltip>
                            </div>
                          ) : (
                            <Tooltip title="View MLC">
                              <VisibilityIcon sx={{ color: "#1e3a8a" }} />
                            </Tooltip>
                          )}
                        </button>
                        {/* </Tooltip> */}
                      </>
                    ) : (
                      ""
                    )}
                    {actions.action === "Print" ? (
                      <>
                        <BarcodeOnIcon
                          title={"Barcode"}
                          onClick={() => {
                            handleOpenPrintModal(row, index, "barcode");
                          }}
                        />
                      </>
                    ) : (
                      ""
                    )}
                    {/* {actions.action === "Pre Assessment" ? (
                      <>
                        <Tooltip title="Pre Assessment">
                          <button
                            type="button"
                            className="-m-4"
                            onClick={() => {
                              setOpenAssessmentModal(true);
                              setSelectedPreAssessment(row)
                            }}
                          >
                            <PreAssessmentIcon />
                          </button>
                        </Tooltip>
                      </>
                    ) : (
                      ""
                    )} */}
                  </div>
                </>
              ) : null}
            </>
          ))}
      </div>
    );
  };

  const renderInput = (row, index, header) => {
    return (
      <>
        {header === "Medico Legal Case" &&
        // row[header] &&
        userActions.some(
          (item) => item.action === "MLCEdit" && item.isAction === true
        ) ? (
          <>
            <div className="flex gap-1 cursor-pointer">
              <Tooltip title="Mark MLC">
                <Checkbox
                  disabled={row["MLC No"] === null ? false : true}
                  size="small"
                  checked={row["Medico Legal Case"]}
                  sx={{ padding: 0 }}
                  onClick={(e) => {
                    if (e.target.checked && row["MLC No"] === null) {
                      setOpenMLC(true);
                      setSelectedMlcData(row);
                      setpatientData(row);
                      setAdmissionId(row.VisitId);
                      setPatientInfo(row);
                      //setprivilege(actions.action);
                      setprivilege("MLCEdit");
                    }
                  }}
                />
              </Tooltip>

              <Tooltip title="Edit MLC">
                <DriveFileRenameOutlineIcon
                  // sx={{ color: "#1e3a8a" }}
                  className={
                    // !row.IsCancel ||
                    row["MLC No"] !== null
                      ? `text-customBlue`
                      : `text-[#d7d7d7]`
                  }
                  onClick={(e) => {
                    if (row["MLC No"] !== null) {
                      setOpenMLC(true);
                      setSelectedMlcData(row);
                      setpatientData(row);
                      setAdmissionId(row.VisitId);
                      setPatientInfo(row);
                      //setprivilege(actions.action);
                      setprivilege("MLCEdit");
                    }
                  }}
                />
              </Tooltip>

              <Tooltip title="Print MLC">
                <LocalPrintshopIcon
                  className={
                    row["MLC No"] !== null
                      ? `text-customGray`
                      : `text-[#d7d7d7]`
                  }
                  onClick={() => {
                    row["MLC No"] !== null &&
                      handleOpenPrintModal(row, index, "mlc");
                  }}
                />
              </Tooltip>
            </div>
          </>
        ) : //row[header] &&
        userActions.some(
            (item) => item.action === "MLCView" && item.isAction === true
          ) ? (
          <>
            <div className="flex gap-1">
              {row["MLC No"] !== null ? (
                <EyeOnIcon
                  title="View MLC"
                  onClick={(e) => {
                    setOpenMLC(true);
                    setSelectedMlcData(row);
                    setpatientData(row);
                    setAdmissionId(row.VisitId);
                    setPatientInfo(row);
                    setprivilege("MLCView");
                  }}
                />
              ) : (
                <EyeOffIcon />
              )}

              <Tooltip title="Print MLC">
                <LocalPrintshopIcon
                  className={
                    row["MLC No"] !== null
                      ? `text-[#d7d7d7]`
                      : `text-customGray`
                  }
                  onClick={() => {
                    row["MLC No"] !== null &&
                      handleOpenPrintModal(row, index, "mlc");
                  }}
                />
              </Tooltip>
            </div>
          </>
        ) : (
          <div className="ml-2">No</div>
        )}
      </>
    );
  };

  const RenderBarcodeButton = () => {
    return (
      <div className="flex items-center ">
        <Tooltip title="Print Barcode">
          <BarcodeIcon />
        </Tooltip>
      </div>
    );
  };

  return (
    <>
      <div className="mt-10 mx-auto w-full">
        <div className="py-2">
          <p className="text-center text-2xl text-gray-700 font-Poppins">
            Patient Visit List
          </p>
        </div>

        <div className="flex gap-2 md:grid-cols-3 p-1">
          <div className="lg:col-span-2 w-[190%]">
            <SearchBar
              searchIcon={true}
              isClearable={true}
              name="searchVisitList"
              placeholder="Search by Name / MR No."
              dataArray={options}
              isSearchable={true}
              handleInputChange={handleChange}
              onChange={autoSelectedValue}
            />
          </div>
          <div className="w-full">
            <DatePickerFieldNew
              control={control}
              name="fromDateCalender"
              label="From Date"
              // value={new Date()}
              disableFuture={true}
              disablePast={false}
              inputFormat="dd-MM-yyyy"
              // inputRef={{
              //   ...register("fromDateCalender", {
              //     onChange: (e) => {
              //       if (e.target.value.toString().length === 55) {
              //         let dateVal = format(e.target.value, "yyyy-MM-dd");
              //         let timeVal = dateVal + "T" + "00:00:00.000Z";
              //         setSelectedFromDate(timeVal);
              //       }
              //     },
              //   }),
              // }}
            />
          </div>
          <div className="w-full">
            <DatePickerFieldNew
              control={control}
              name="toDateCalender"
              label="To Date"
              // value={new Date()}
              disableFuture={true}
              inputFormat="dd-MM-yyyy"
              // inputRef={{
              //   ...register("toDateCalender", {
              //     onChange: (e) => {
              //       if (e.target.value.toString().length === 55) {
              //         let dateVal = format(e.target.value, "yyyy-MM-dd");
              //         let timeVal = dateVal + "T" + "00:00:00.000Z";
              //         setSelectedToDate(timeVal);
              //       }
              //     },
              //   }),
              // }}
            />
          </div>
          <div className="w-full">
            <SearchDropdown
              control={control}
              name="doctor"
              placeholder="Doctor"
              label="Doctor"
              isSearchable={true}
              searchIcon={true}
              isClearable={true}
              dataArray={doctor}
              handleInputChange={getDoctorDropdownList}
            />
          </div>

          <div className="w-full">
            <DropdownField
              name="unit"
              dataArray={unit}
              isSearchable={false}
              placeholder="Unit "
              label="Unit "
              control={control}
            />
          </div>
        </div>

        <div className="lg:grid lg:grid-cols-2 2xl:grid 2xl:grid-cols-2 md:grid  w-full gap-2">
          <div className=" lg:grid lg:justify-end md:grid md:grid-cols-3 md:gap-2 lg:gap-2 py-1">
            {/*<div className="whitespace-nowrap ">
              <CheckboxField
                name="isMedicoLegalCase"
                label="Medico Legal Case"
                control={control}
              />
            </div>*/}
            <div className="flex gap-2 pl-2">
              <SearchIconButton />
              {/* { Actions.isAction && Actions.action === "opdBill" ? ( 
              <div className=" flex gap-2 justify-end">
                <CommonButton
                  type="button"
                  label="Reset"
                  className="border border-customRed text-customRed"
                  onClick={() => {
                    setSelectedFromDate(new Date());
                    setSelectedToDate(new Date());
                    reset(defaultValues);
                  }}
                />
              </div>*/}
              <div className="">
                <button
                  className="border border-gray-500 h-[2.3rem] rounded-md  w-16 cursor-pointer p-1 bg-white"
                  onClick={(e) => {
                    e.preventDefault();
                    setValue("fromDateCalender", new Date());
                    setValue("toDateCalender", new Date());
                    reset(defaultValues);
                  }}
                >
                  <RefreshIcon />
                </button>
              </div>
            </div>
          </div>
          <div className="flex md:w-full justify-end py-1">
            <div className=" flex gap-2 justify-end">
              {/* ) : null} */}
              {userActions &&
                userActions.map((obj) => (
                  <>
                    {!obj.isAction && obj.action === "OPD Bill" ? (
                      <div className="">
                        <CommonButton
                          label="OPD Bill"
                          className="bg-blue-900 text-white"
                          onClick={() => {
                            let Row = {};
                            navigate(`/opd/bill`, {
                              state: { Row, menuId: obj.menuId },
                            });
                          }}
                        />
                      </div>
                    ) : null}
                    {!obj.isAction && obj.action === "Patient Visit" ? (
                      <div className="">
                        <CommonButton
                          type="submit"
                          label="Patient Visit"
                          className="whitespace-nowrap   bg-[#1e3a8a] text-white"
                          onClick={() => {
                            navigate(`/opd/registration`, {
                              state: { menuId: obj.menuId },
                            });
                          }}
                        />
                      </div>
                    ) : null}
                    {!obj.isAction && obj.action === "Book Appointment" ? (
                      <div className="">
                        <CommonButton
                          className="whitespace-nowrap  bg-green-600 text-white"
                          label="Book Appointment"
                          onClick={() => {
                            let Row = {};

                            navigate(`/opd/phoneAppointments`, {
                              state: { Row, menuId: obj.menuId },
                            });
                          }}
                        />
                      </div>
                    ) : null}
                    {!obj.isAction && obj.action === "View Appointments" ? (
                      <div className="">
                        <CommonButton
                          className="whitespace-nowrap  bg-customBlue text-white"
                          label="View Appointments"
                          onClick={() => {
                            let Row = {};

                            setOpenAppointmentListModal(true);
                          }}
                        />
                      </div>
                    ) : null}
                  </>
                ))}
            </div>
          </div>
        </div>
        {loadingSpinner ? (
          <div className="grid justify-center items-center pt-12">
            <LoadingSpinner />
          </div>
        ) : null}
        {/* CommonMasterTable Component */}
        {data.result.length > 0 && loadingSpinner === false ? (
          <CommonDynamicTablePaginationNew
            dataResult={dataResult}
            highlightRow={false}
            renderActions={renderActions}
            renderInput={renderInput}
            editableColumns={["Medico Legal Case"]}
            populateTable={populateTable}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            count={count}
            tableClass="2xl:h-[650px]"
            removeHeaders={removeHeaders}
          />
        ) : null}
        {recordWarning === true && loadingSpinner === false ? (
          <div className="flex justify-center">
            <h3 className="flex justify-center mt-20 font-bold text-gray-600">
              No Records Found...
            </h3>
          </div>
        ) : null}

        {openPrintModal ? (
          <CommonPrintModal
            open={openPrintModal}
            setOpen={setOpenPrintModal}
            handleClose={handleClosePrintModal}
            handleOpen={handleOpenPrintModal}
            urlforPrint={printUrl}
          />
        ) : null}
        {openMLC ? (
          <MLCModalEdit
            open={openMLC}
            setOpen={setOpenMLC}
            handleOpen={handleOpenMLCModal}
            handleClose={handleCloseMLCModal}
            registered={selectedMlcData.Date}
            admissionId={admissionId}
            emergencyId={selectedMlcData?.EmergencyId}
            opdIpd={0}
            ipdEmergency={0}
            //isEdit={true}
            populateTable={populateTable}
            MLCNo={selectedMlcData["MLC No"]}
            setIsFromVisitList={setIsFromVisitList}
            isFromVisitList={isFromVisitList}
            patientData={patientData}
            patientInfo={patientInfo}
            menuId={location?.state?.menuId}
            privilege={privilege}
            isFromForm={false}
            //
            patientId={selectedMlcData.PatientId}
            isEmergency={false}
            //
          />
        ) : null}
        {openAppointmentListModal ? (
          <ViewAppointmentsModal
            open={openAppointmentListModal}
            setOpen={setOpenAppointmentListModal}
            menuId={location?.state?.menuId}
          />
        ) : null}


      </div>
    </>
  );
}
