import { useEffect, useRef, useState } from "react";

//importing the common components
import DropdownField from "../../../common/components/FormFields/DropdownField";
import RadioField from "../../../common/components/FormFields/RadioField";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import {
  fetchDepartmentConsentTemplates,
  fetchDepartmentForConsent,
  fetchPatientInformationById,
  fetchTempleteList,
  OtConsentPrintData,
  postSaveOTConsent,
  searchPatientInformation,
} from "../../services/otConsentServices/OtConsentServices ";

//imports from the react-hook-form library
import JoditEditor from "jodit-react";
import { useForm } from "react-hook-form";

//imports fromn the mui library
import { useLocation, useNavigate } from "react-router-dom";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import CommonDynamicTableNew from "../../../common/components/CommonTable/CommonDynamicTableNew";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import { removeLocationData } from "../../../common/components/RemoveLocationdata";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../common/components/Toasts/CustomToasts";
import { fetchUserActionsByMenuId } from "../../../commonServices/functionalityServices/FunctionalityServices";
import { PrintOnIcon } from "../../../assets/icons/CustomIcons";
import OtConsentPrint from "../../prints/OtConsentPrint";
import ConsentPrintPreview from "./ConsentPrintPreview";
import { Box, Modal, Tooltip } from "@mui/material";
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";
import { PrintIcon } from "../../../assets/CommonAssets";
import { routeToPrinterDevice } from "../../../commonServices/barCodeServices/BarCodeServices";

function OtConsent(props) {
  let navigate = useNavigate();
  let location = useLocation();
  //
  const [userActions, setUserActions] = useState([]);
  const [privilege, setPrivilege] = useState([]);
  const [templateContent, setTemplateContent] = useState("");

  //
  let token = JSON.parse(localStorage.getItem("userInfo"));
  //
  const defaultValues = {
    patientType: 1,
    languageName: 0,
    templateName: null,
    departmentName: null,
  };

  const { control, register, setValue, watch, resetField, handleSubmit } =
    useForm({
      defaultValues,
    });

  let editor = useRef(null);
  let PatientType = watch("patientType");
  let dept = watch("departmentName");
  let templete = watch("templateName");

  console.log("editor", editor?.current?.value);

  let writerContent = "";

  const config = {
    readonly: false,
    height: 400,
    allowResizeY: false,
    statusbar: false,
    buttons: [
      "bold",
      "underline",
      "strikethrough",
      "italic",
      "image",
      "indent",
      "outdent",
      "fontsize",
      "paragraph",
      "brush",
      "|",
      "align",
      "ul",
      "ol",
      "table",
      "hr",
      "symbol",
      "eraser",
      "copyformat",
      "superscript",
      "subscript",
      "undo",
      "redo",
      "find",
      "preview",
      "print",
    ],
    imageDefaultWidth: 100,
    uploader: {
      insertImageAsBase64URI: true,
    },
  };

  const patientTypeArray = [
    {
      id: 0,
      value: "OPD",
      label: "OPD",
    },
    {
      id: 1,
      value: "IPD",
      label: "IPD",
    },
  ];

  const languageArray = [
    {
      id: 0,
      value: "English",
      label: "English",
    },
    {
      id: 1,
      value: "Marathi",
      label: "Marathi",
    },
  ];

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const [openBackdrop, setOpenBackdrop] = useState(false);

  const [options, setOptions] = useState([]);

  const [patientInfo, setPatientInfo] = useState(null);

  const [disableSearch, setDisableSearch] = useState(null);

  const [templeteList, setTempleteList] = useState([]);

  const [selectedTblRow, setSelectedTblRow] = useState(null);

  const [templetDesc, setTempletDesc] = useState(null);

  const [consentCode, setConsentCode] = useState(null);

  const [deptForConsent, setDeptForConsent] = useState();

  const [consentTemplete, setConsentTemplete] = useState();

  const [formData, setFormData] = useState();

  const [admissionId, setAdmissionId] = useState(props.admissionId || null);

  let TemplateName = watch("templateName");

  console.log("templetDesc", templetDesc);

  //Change handler for "Search By Patient Name / MR No" SearchDropdown.
  const handleChange = (searchString) => {
    if (searchString !== "") {
      searchPatientInformation(searchString, PatientType)
        .then((response) => response.data)
        .then((res) => {
          setOptions(res.result);
        });
    }
  };

  function getPatientInfo(selPatientObj) {
    //call the below function to get the patient information by id.

    fetchPatientInformationById(selPatientObj.id, PatientType)
      .then((response) => response.data)
      .then((res) => {
        setPatientInfo(res.result);
      });

    //Department For Consent
  }

  useEffect(() => {
    getPatientInfo({ id: props.PatientId });
  }, [props]);

  useEffect(() => {
    //selectedTblRow
    if (selectedTblRow !== null) {
      setValue("departmentName", {
        id: selectedTblRow.DepartmentID,
        label: selectedTblRow.department,
        value: selectedTblRow.department,
      });
      setValue("templateName", {
        id: selectedTblRow.ConsentTemplateId,
        label: selectedTblRow["Template Name"],
        value: selectedTblRow["Template Description"],
        Description: selectedTblRow["Template Description"],
      });
    }
  }, [selectedTblRow]);

  useEffect(() => {
    getDept();
  }, []);

  useEffect(() => {
    if (templete !== null) {
      setTempletDesc(templete.value);
      setTemplateContent(templete.value);
    }

    // admissionId && getTempleteList();
    dept && getDepartmentConsentTemplates();
  }, [dept, admissionId, PatientType, templete]);

  useEffect(() => {
    admissionId && getTempleteList();
  }, [admissionId, PatientType, props]);

  const getTempleteList = () => {
    if (admissionId !== null) {
      fetchTempleteList(admissionId, PatientType)
        .then((response) => response.data)
        .then((res) => {
          setTempleteList(res.result);
        });
    }
  };

  const getDepartmentConsentTemplates = () => {
    if (dept !== null) {
      fetchDepartmentConsentTemplates(dept.id)
        .then((response) => response.data)
        .then((res) => {
          setConsentTemplete(res.result);
        });
    }
  };

  const getDept = () => {
    fetchDepartmentForConsent()
      .then((response) => response.data)
      .then((res) => {
        setDeptForConsent(res.result);
      });
  };

  /////receiving data of navigate
  useEffect(() => {
    if (location?.state !== null && location?.state.patientInfo !== null) {
      setPatientInfo(location.state.patientInfo);
      setDisableSearch(location?.state?.isEdit);
      setAdmissionId(location?.state?.patientInfo?.admissionId);
      // clear location which contains navigation data,after refresh
      !props.isFromClinicalCarechart &&
        removeLocationData(
          location?.state?.menuId,
          "/operationTheater/consent",
          navigate
        );
    }
  }, []);

  const onSubmit = (data) => {
    if (patientInfo !== null && data.templateName !== null) {
      setFormData(data);
      setOpenConfirmationModal(true);
    } else if (patientInfo === null) {
      warningAlert("Please Select Patient...");
    } else if (data.templateName === null) {
      warningAlert("Please Select Templete...");
    }
  };

  const finalObj = {
    addedBy: 0,
    consentFor: 0,
    consentDtlId: 0,
    consentTempId: 0,
    nokAddress: "",
    nokName: "",
    nokPhoneNo: "",
    nokRelationshipId: "",
    opdIpd: 0,
    opdIpdExternalId: 0,
    tempDescription: "",
  };
  const handleFormSubmit = (data) => {
    setOpenBackdrop(true);
    setOpenConfirmationModal(false);

    finalObj.addedBy = token.userId;
    finalObj.consentFor = 0;
    finalObj.consentDtlId =
      selectedTblRow !== null ? selectedTblRow.ConsentDtlId : 0;
    finalObj.consentTempId = data.templateName.id;
    finalObj.nokAddress = "";
    finalObj.nokName = patientInfo?.patientName;
    finalObj.nokPhoneNo = patientInfo?.MobileNo;
    finalObj.nokRelationshipId = "";
    finalObj.opdIpd = Number(data.patientType);

    if (Number(data.patientType) === 0) {
      finalObj.opdIpdExternalId = patientInfo?.visitId;
    } else if (Number(data.patientType) === 1) {
      finalObj.opdIpdExternalId = patientInfo?.admissionId;
    }

    finalObj.tempDescription = templateContent;

    finalObj.menuId = props.menuId || location?.state?.menuId;
    finalObj.privilege = privilege;

    ///////////////
    postSaveOTConsent(finalObj)
      .then((response) => response.data)
      .then((res) => {
        if (res.statusCode === 302) {
          warningAlert(res.message);
        } else if (res.statusCode === 200) {
          successAlert(res.message);
          setOpenBackdrop(false);
          setConsentTemplete(null);
          setTempletDesc(null);
          resetField("templateName");
          resetField("departmentName");
          resetField("languageName");
          getTempleteList();
        }
      })
      .catch((res) => {
        errorAlert(res.message || res.response.data.message);
        setOpenBackdrop(false);
      });
  };

  ///get User Action
  useEffect(() => {
    (location?.state?.menuId || props.menuId) &&
      fetchUserActionsByMenuId(props.menuId || location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });
  }, []);

  const handleSelectedRow = (row) => {
    setSelectedTblRow(row);
    setTempletDesc(row["Template Description"]);
  };

  //

  const [openPrintModal, setOpenPrintModal] = useState(false);
  const [isPrintPreview, setIsPrintPreview] = useState(false);
  const [otConsentId, setOtConsentId] = useState(null);

  const renderPrint = () => {
    setTimeout(() => {
      setOpenPrintModal(false);
    }, 1000);

    return (
      <div className="hidden">
        {!isPrintPreview ? (
          <OtConsentPrint otConsentId={otConsentId} consentCode={consentCode} />
        ) : (
          <ConsentPrintPreview
            patientInfo={patientInfo}
            templateContent={templateContent}
            TemplateName={TemplateName}
            consentCode={consentCode}
          />
        )}
      </div>
    );
  };

  const renderActions = (row, index) => {
    return (
      <div className="flex gap-1">
        {userActions.map(
          (obj) =>
            !obj.isAction && (
              <>
                {obj.action === "Print" && (
                  <div>
                    <PrintOnIcon
                      title="Print"
                      onClick={() => {
                        // handleOpenConsentPrintModal(row);
                        setOtConsentId(row?.ConsentDtlId);
                        setIsPrintPreview(false);
                        setOpenPrintModal(true);
                      }}
                    />
                  </div>
                )}
              </>
            )
        )}
      </div>
    );
  };

  const [consentReportPdf, setConsentReportPdf] = useState("");
  const [openConsentPrintModal, setOpenConsentPrintModal] = useState(false);

  const handleOpenConsentPrintModal = (row) => {
    setOpenBackdrop(true);
    OtConsentPrintData(row?.ConsentDtlId)
      .then((response) => {
        console.log("response123", response);
        if (response.status === 200) {
          console.log("response456", response);
          setOpenBackdrop(false);

          const blob = new Blob([response.data], { type: "application/pdf" });
          const reader = new FileReader();

          reader.readAsDataURL(blob); // Read the blob as a Base64 data URL
          reader.addEventListener("loadend", (e) => {
            const base64Data = e.target.result;

            if (!base64Data.startsWith("data:application/pdf;base64,")) {
              setOpenConsentPrintModal(true);
              setConsentReportPdf(""); // Handle case for invalid data
            } else {
              setOpenConsentPrintModal(true);
              setConsentReportPdf(base64Data); // Store Base64 string
              console.log("Base64 Data:", base64Data);
            }
          });
        } else if (response.status !== 200) {
          setOpenBackdrop(false);
        }
      })
      .catch((error) => {
        console.log("error123", error);
        errorAlert(error.response.data.message || error?.message);
        setOpenBackdrop(false);
      });
  };

  let defaultPrinters = JSON.parse(localStorage.getItem("defaultPrinters"));

  const handleDirectPrint = () => {
    const printData = [
      {
        printerName: defaultPrinters?.defaultPrinter,
        pdf: consentReportPdf,
        numberCopies: defaultPrinters?.printPages,
        pageSize: defaultPrinters?.printSize,
      },
    ];

    routeToPrinterDevice(printData)
      .then(() => {
        successAlert();
      })
      .catch((error) => {
        console.error("Error while printing:", error);
        // Optionally, handle the error (e.g., show a message to the user)
      });
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-12">
          <div className="text-xl font-semibold flex justify-center">
            OT Consent
          </div>

          {/* the search bar's parent div */}
          <div className="grid grid-cols-3 gap-3 justify-evenly">
            <div>
              <div className="mt-1.5">
                <SearchDropdown
                  name="patientSearch"
                  control={control}
                  searchIcon={true}
                  placeholder="Search By Patient Name / MR.No./ Mobile No."
                  handleInputChange={handleChange}
                  isClearable={true}
                  dataArray={options}
                  isDisabled={props.isFromClinicalCarechart || disableSearch}
                  inputRef={{
                    ...register("patientSearch", {
                      onChange: (e) => {
                        if (e.target.value !== null) {
                          setAdmissionId(
                            PatientType === 1
                              ? e.target.value.AdmissionId
                              : e.target.value.VisitId
                          );
                          getPatientInfo(e.target.value);
                        } else {
                          setPatientInfo(null);
                          setTempleteList([]);
                          resetField("departmentName");
                          resetField("templateName");
                          setTempletDesc(null);
                          setAdmissionId(null);
                          setSelectedTblRow(null);
                        }
                      },
                    }),
                  }}
                />
              </div>
            </div>

            <div className="col-span-2">
              <div className="flex">
                <div className="mt-2">
                  <div className="mt-1">
                    <RadioField
                      control={control}
                      name="patientType"
                      dataArray={patientTypeArray}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {!!patientInfo && (
            <>
              {/* Patient Information */}
              <div className="border items-center justify-center bg-gray-100 border-gray-300 text-left w-full rounded-md mb-2 px-2 mt-2">
                <div className="py-2 grid  lg:grid-cols-4 2xl:grid-cols-4 gap-2 p-3 ">
                  <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                    <span className=" font-semibold w-28">Patient Name</span>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="text-gray-700 font-normal">
                        {patientInfo && patientInfo?.patientName}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center gap-2 w-full font-semibold text-sm py-1">
                    <span className=" w-28">MRNo</span>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="text-gray-700 font-normal">
                        {patientInfo && patientInfo?.mrNo}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center gap-2 w-full font-semibold text-sm py-1">
                    <span className=" w-28">Gender</span>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="text-gray-700 font-normal">
                        {patientInfo && patientInfo?.gender}
                      </span>
                    </div>
                  </div>

                  <div className="flex items-center gap-2 w-full font-semibold text-sm py-1">
                    <span className=" w-28">Age</span>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="text-gray-700 font-normal">
                        {patientInfo && patientInfo?.ageYear}
                      </span>
                    </div>
                  </div>

                  <div className="flex items-center gap-2 w-full font-semibold text-sm py-1">
                    <span className=" w-28">Patient Source</span>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="text-gray-700 font-normal">
                        {patientInfo && patientInfo?.patientSource}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center gap-2 w-full font-semibold text-sm py-1">
                    <span className=" w-28">Patient Category</span>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="text-gray-700 font-normal">
                        {patientInfo && patientInfo?.patientCategory}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center gap-2 w-full font-semibold text-sm py-1">
                    <span className="font-semibold w-28 ">Tariff</span>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="text-gray-700 font-normal">
                        {patientInfo && patientInfo?.tariff}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center gap-2 w-full font-semibold text-sm py-1">
                    <span className="font-semibold w-28">Company</span>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="text-gray-700 font-normal">
                        {patientInfo && patientInfo?.company}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center gap-2 w-full font-semibold text-sm py-1">
                    <span className="font-semibold w-28">Doctor</span>
                    <div className="whitespace-nowrap flex space-x-2 items-center">
                      <span>:</span>
                      <span className="text-gray-700 font-normal ">
                        {patientInfo && patientInfo?.doctorName}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              {/* The table component */}
              {templeteList.length > 0 ? (
                <div className="mt-2">
                  <CommonDynamicTableNew
                    dataResult={templeteList}
                    handleSelectedRow={handleSelectedRow}
                    tableClass="rounded lg:h-52 md:h-72"
                    renderActions={renderActions}
                    removeHeaders={[
                      "id",
                      "Template Description",
                      "Service Name",
                      "NOK Address",
                      "NOKRelationshipId",
                      "ConsentDtlId",
                      "DepartmentID",
                      "ConsentFor",
                      "ConsentTemplateId",
                      "EngMar",
                      "Opd_Ipd",
                      "DepartmentID",
                    ]}
                  />
                </div>
              ) : null}

              {/* The Department dropdown and Consent Template dropdown */}
              <div className="grid grid-cols-3 mt-2 gap-3 justify-evenly">
                <div>
                  <DropdownField
                    control={control}
                    name="departmentName"
                    placeholder="Select Department"
                    isSearchable={false}
                    dataArray={deptForConsent}
                    inputRef={{
                      ...register("departmentName", {
                        onChange: (e) => {
                          resetField("templateName");
                        },
                      }),
                    }}
                  />
                </div>
                <div>
                  <DropdownField
                    control={control}
                    name="templateName"
                    placeholder="Select Template"
                    isSearchable={false}
                    dataArray={consentTemplete}
                    inputRef={{
                      ...register("templateName", {
                        onChange: (e) => {
                          setTempletDesc(e.target.value.value);
                          setConsentCode(e.target.value?.ConsentCode);
                          setSelectedTblRow(null);
                        },
                      }),
                    }}
                  />
                </div>
                <div>
                  <div className="grid justify-items-center grid-cols-1 mt-2">
                    <RadioField
                      control={control}
                      name="languageName"
                      dataArray={languageArray}
                    />
                  </div>
                </div>
              </div>

              {/* div for JoditEditor component */}
              <div className="mt-2">
                <JoditEditor
                  ref={editor}
                  value={
                    templetDesc !== null
                      ? templetDesc +
                        (consentCode !== null
                          ? "<br/>Consent Code: " + consentCode
                          : "")
                      : ""
                  }
                  config={config}
                  onBlur={(newContent) => {
                    if (newContent !== "") {
                      console.log("newContent", newContent);
                      setTemplateContent(newContent);
                    }
                  }}
                />
              </div>

              {/* div for New , Save , Modify , print and cancel button  */}
              <div className="flex flex-row gap-3 justify-end mt-2">
                {userActions.map(
                  (obj) =>
                    !obj.isAction && (
                      <>
                        {obj.action === "Create" && (
                          <>
                            <div>
                              <CommonButton
                                onClick={() => {
                                  if (!!templateContent.trim()) {
                                    setIsPrintPreview(true);
                                    setOpenPrintModal(true);
                                  } else {
                                    warningAlert(
                                      "Kindly Add template description"
                                    );
                                  }
                                }}
                                label="Print Preview"
                                className="bg-customGray text-white"
                              />
                            </div>
                            <div>
                              <CommonButton
                                onClick={() => {
                                  setTempletDesc(null);
                                  resetField("templateName");
                                  resetField("departmentName");
                                  setSelectedTblRow(null);
                                }}
                                label="New"
                                className="bg-customOrange text-white"
                              />
                            </div>

                            <div>
                              <CommonButton
                                onClick={() => {
                                  setPatientInfo(null);
                                  setValue("patientSearch", null);
                                  setDisableSearch(null);
                                  setTempleteList([]);
                                  setTempletDesc(null);
                                  resetField("templateName");
                                  resetField("departmentName");
                                  setAdmissionId(null);
                                  setSelectedTblRow(null);
                                }}
                                label="Reset"
                                className="border border-customRed text-customRed"
                              />
                            </div>

                            <div
                              onClick={() => {
                                setPrivilege(obj.action);
                              }}
                            >
                              {/* <SaveButton /> */}
                              <CommonButton
                                label="Save"
                                className="bg-customGreen text-white"
                                type="submit"
                              />
                            </div>
                          </>
                        )}
                      </>
                    )
                )}
              </div>
            </>
          )}
        </div>
        {openPrintModal && renderPrint()}
      </form>

      {/* backdrop */}
      <CommonBackDrop openBackdrop={openBackdrop} />

      {/* //Confirmation Modal// */}
      <ConfirmationModal
        confirmationOpen={openConfirmationModal}
        confirmationHandleClose={() => setOpenConfirmationModal(false)}
        confirmationSubmitFunc={() => handleFormSubmit(formData)}
        confirmationLabel="Confirmation "
        confirmationMsg={"Are You Sure ?"}
        confirmationButtonMsg="Proceed"
      />
      {openConsentPrintModal ? (
        <Modal
          open={openConsentPrintModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              marginX: "auto",
              position: "absolute",
              top: "1%",
              left: "1%",
              backgroundColor: "white",
              width: "97%",
              height: "93%",
            }}
            className="space-y-2"
          >
            <div className=" bg-white font-bold flex justify-between px-4">
              <p className="text-lg">Consent Print</p>
              <div className="flex space-x-5 items-center">
                <Tooltip title="Print Consent">
                  <button
                    type="button"
                    className="mr-10 mt-2.5"
                    onClick={() => handleDirectPrint()}
                  >
                    <PrintIcon />
                  </button>
                </Tooltip>
                <button onClick={() => setOpenConsentPrintModal(false)}>
                  <CancelPresentationIconButton />
                </button>
              </div>
            </div>

            {consentReportPdf !== "" ? (
              <embed
                src={`${consentReportPdf}#toolbar=0`}
                frameBorder="0"
                width="100%"
                height="100%"
              />
            ) : (
              <div>
                <p>Report is Not Available</p>
              </div>
            )}
          </Box>
        </Modal>
      ) : null}
    </>
  );
}

export default OtConsent;
