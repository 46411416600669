import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { parseISO } from "date-fns";
import PropTypes from "prop-types";
import React, { createContext, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import * as yup from "yup";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../common/components/Toasts/CustomToasts";
import { fetchUserActionsByMenuId } from "../../../commonServices/functionalityServices/FunctionalityServices";
import {
  fetchDoctor,
  searchRMOsData,
} from "../../../commonServices/miscellaneousServices/MiscServices";
import { getPatientInfoOpdIpdAutocomplete } from "../../../commonServices/patientInfoServices/PatientInfoServices";
import { fetchPatientTransferPatientInfo } from "../../services/bedTransfer/BedTransferService";
import {
  fetchPatchData,
  postPatientTransfer,
} from "../../services/patientTransfer/PatientTransferServices";
import CurrentMedication from "./CurrentMedication";
import DetailsByRmo from "./DetailsByRmo";
import PatientTransferDetails from "./PatientTransferDetails";
import PatientTransferListing from "./PatientTransferListing/patientTransferListing";

///
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
//// context
export const PatientTransferContext = createContext("");
////

const PatientTransfer = (props) => {
  let location = useLocation();
  //
  const [userActions, setUserActions] = useState([]);
  const [privilege, setPrivilege] = useState([]);
  //
  const [finalData, setFinalData] = useState("");
  const [patchData, setPatchData] = useState(null);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [options, setOptions] = useState([]);
  const [tabValue, setTabValue] = React.useState(0);
  const [selecteAdmissionId, setSelecteAdmissionId] = useState(
    props.admissionId || null
  );
  //
  const [medicationData, setMedicationData] = useState([]);
  const [emoloyeeName, setEmoloyeeName] = useState();
  const [rmoNameData, setRmoNameData] = useState([]);

  //
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  //finalize
  const [isFinalize, setIsFinalize] = useState(0);
  const [ptIdForEdit, setPtIdForEdit] = useState(0);

  //listing modal
  const [openPatientTransferModal, setOpenPatientTransferModal] =
    React.useState(false);
  const handleOpenPatientTransferModal = () =>
    setOpenPatientTransferModal(true);
  const handleClosePatientTransferModal = () =>
    setOpenPatientTransferModal(false);
  //
  //

  const schema = yup.object().shape({
    rmoName: yup
      .object()
      .nullable()
      .shape({
        value: yup.string().required("Please Select Category"),
        label: yup.string().required("Please Select Category"),
      })
      .required("Required"),
  });
  ////
  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      rmoName: null,
      bedFrom: null,
      bedTo: null,
      bp: "",
      comment: "",
      criteria: 1,
      departmentFrom: null,
      departmentTo: null,
      dietetics: "",
      dignosisAtTheTimeOfTransfer: "",
      knownAllergies: "",
      mews: "",
      noteOnSurgeryProcedures: "",
      otherNoteDetails: "",
      patientTransferSearch: "",
      pendingInvestigations: "",
      pulse: "",
      ptId: 0,
      reasonForTransfer: "",
      respiration: "",
      temp: "",
      transferAuthorisedBy: null,
      transferDate: new Date(),
      transferTime: new Date(),
      wardFrom: null,
      wardTo: null,
      //

      itemName: "",
      dose: "",
      route: "",
      frequency: "",
      // instructions: "",
    },
  });

  const { control, watch, register, setValue } = methods;

  let RMO = watch("rmoName");

  useEffect(() => {
    getEmoloyeeName();
  }, []);

  // Api to Get Employee name

  const getEmoloyeeName = (searchString) => {
    searchString &&
      fetchDoctor(searchString).then((response) => {
        setEmoloyeeName(response.data.result);
      });
    // fetchEmoloyeeName().then((response) => {
    //   setEmoloyeeName(response.data.result);
    // });
  };

  const getRMOsName = (searchString) => {
    searchString &&
      searchRMOsData(searchString)
        .then((res) => {
          setRmoNameData(res?.data?.result);
        })
        .catch((err) => err);
  };

  //handleChange
  const handleChangeSearch = (searchString) => {
    if (searchString !== undefined && searchString !== "") {
      getPatientInfoOpdIpdAutocomplete(searchString, 1)
        .then((response) => response.data)
        .then((res) => {
          setOptions(res.result);
        });
    }
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const finalObj = {
    admissionId: 0,
    authorizedBy: "",
    bp: "",
    casualtyOnly: 0,
    commentOtherReference: "",
    diagnosis: "",
    dietetics: "",
    finalize: 0,
    fromBed: "",
    fromDepartment: "",
    fromWard: "",
    investigationRemark: "",
    knownAllergies: "",
    mews: "",
    otherNoteDetails: "",
    patientTransferDetailsDtoList: [],
    pendingInvestingations: "",
    procedures: "",
    pulse: "",
    reason: "",
    respiration: "",
    rmoName: "",
    temp: "",
    toBed: "",
    toDepartment: "",
    toWard: "",
  };

  const onSubmit = (data) => {
    if (!selectedPatient) {
      warningAlert("Please Select Patient !");
    } else if (selectedPatient) {
      setOpenConfirmationModal(true);
      setFinalData(data);
    }
  };

  const handleFormSubmit = (data) => {

    console.log("Form Data", data);
    
    setOpenBackdrop(true);
    setOpenConfirmationModal(false);

    finalObj.admissionId =
      patchData !== null ? patchData.admissionId : selectedPatient.AdmissionId; ///////
    finalObj.authorizedBy =
      data.transferAuthorisedBy !== null ? data.transferAuthorisedBy.label : "";
    finalObj.bp = data.bp;
    finalObj.casualtyOnly = data.criteria !== "" ? data.criteria : 0; /////
    finalObj.commentOtherReference = data.comment;
    finalObj.diagnosis = data.dignosisAtTheTimeOfTransfer;
    finalObj.dietetics = data.dietetics;
    finalObj.finalize = isFinalize; /////////
    finalObj.fromBed = data.bedFrom !== null ? data.bedFrom.label : "";
    finalObj.fromDepartment =
      data.departmentFrom !== null ? data.departmentFrom.label : "";
    finalObj.fromWard = data.wardFrom !== null ? data.wardFrom.label : "";
    finalObj.investigationRemark = ""; ///////////////
    finalObj.knownAllergies = data.knownAllergies;
    finalObj.mews = data.mews;
    finalObj.otherNoteDetails = data.otherNoteDetails;
    //
    let medicineArr = [];
    for (let i = 0; i < medicationData.length; i++) {
      let obj = {
        dose: medicationData[i].Dose,
        frequency: medicationData[i].Frequency,
        instruction: medicationData[i].Instructions,
        itemName: medicationData[i].ItemName,
        route: medicationData[i].Route,
        ptDetailsId:
          patchData?.medicationList[i]?.PTDetailID !== undefined
            ? patchData.medicationList[i].PTDetailID
            : 0,
      };
      medicineArr.push(obj);
    }
    finalObj.patientTransferDetailsDtoList = medicineArr;

    finalObj.pendingInvestingations = data.pendingInvestigations;
    finalObj.procedures = data.noteOnSurgeryProcedures;
    finalObj.ptId = ptIdForEdit;
    finalObj.pulse = data.pulse;
    finalObj.reason = data.reasonForTransfer;
    finalObj.respiration = data.respiration;
    finalObj.rmoName = data.rmoName !== null ? data.rmoName.name : ""; //////
    finalObj.temp = data.temp;
    finalObj.toBed = data.bedTo !== null ? data.bedTo.label : "";
    finalObj.toDepartment =
      data.departmentTo !== null ? data.departmentTo.label : "";
    finalObj.toWard = data.wardTo !== null ? data.wardTo.label : "";

    finalObj.menuId = location?.state?.menuId;
    finalObj.privilege = privilege;

    postPatientTransfer(finalObj)
      .then((response) => response.data)
      .then((res) => {
        // setValue("dischrgeSummarySearch",null)
        if (res.statusCode === 302) {
          warningAlert(res.message);
        } else if (res.statusCode === 200) {
          successAlert(res.message);
          setOpenBackdrop(false);
          methods.reset();
          setSelectedPatient(null);
          setTabValue(0);
          setMedicationData([]);
          setPatchData(null);
          setIsFinalize(0);
          setSelecteAdmissionId(null);
          setPtIdForEdit(0);
        }
      })
      .catch((res) => {
        errorAlert(res.message);
        setOpenConfirmationModal(false);
        setOpenBackdrop(false);
      });
  };

  //Edit/patch data ser ice
  const handleEditData = (row) => {
    if (row !== undefined) {
      setPtIdForEdit(row.PTID);
      fetchPatchData(row.PTID)
        .then((response) => response.data)
        .then((res) => {
          setPatchData(res.result);
          setSelectedPatient(res.result);

          //
        });
    }
  };

  const searchPatientInfo = (admissionId) => {
    if (admissionId !== null) {
      fetchPatientTransferPatientInfo(admissionId)
        .then((response) => response.data)
        .then((res) => {
          setSelectedPatient(res.result);
          setValue(
            "otherNoteDetails",
            res?.result?.HistoryOfPresentIllness || ""
          );

          // if (ptIdForEdit?.length > 0) {
          setValue("wardFrom", {
            id: res?.result?.WardId,
            label: res?.result?.Ward,
            value: res?.result?.Ward,
          });
          setValue("departmentFrom", {
            id: res?.result?.DepartmentId,
            label: res?.result?.Department,
            value: res?.result?.Department,
          });
          setValue("bedFrom", {
            id: res?.result?.BedId,
            label: res?.result?.BedNo,
            value: res?.result?.BedNo,
          });

          setValue("bp", res?.result?.BP);
          setValue("mews", res?.result?.Mews);
          setValue("pulse", res?.result?.Pulse);
          setValue("respiration", res?.result?.Respiration);
          setValue("temp", res?.result?.Temp);
          // }
        });
    }
  };

  //patch Data
  useEffect(() => {
    if (patchData !== null) {
      setSelecteAdmissionId(patchData.admissionId);
      setMedicationData(patchData.medicationList);
      setIsFinalize(patchData.Finalize);
      //

      if (patchData.RMOName !== "" ) {
        setValue("rmoName", {
          id: patchData?.rmoId,
          label: patchData?.RMOName,
          value: patchData?.RMOName,
          name:patchData?.RMOName
        });
      }

      if (patchData.AuthorizedBy !== "" ) {
        setValue("transferAuthorisedBy", {
          id: patchData?.authorizedById,
          label: patchData?.AuthorizedBy,
          value: patchData?.AuthorizedBy,
        });
      }

      setValue("bedFrom", patchData.FromBed);

      if (patchData.ToBed !== "" && patchData.bedId !== null) {
        setValue("bedTo", {
          id: patchData.bedId,
          label: patchData.ToBed,
          value: patchData.ToBed,
        });
      }

      setValue("bp", patchData.BP);
      setValue("comment", patchData.CommentOtherReference);
      setValue("criteria", patchData.CasualtyOnly);
      setValue("departmentFrom", {
        label: patchData.FromDepartment,
        value: patchData.FromDepartment,
      });

      setValue("departmentTo", {
        // id: patchData.departmentId,
        label: patchData.ToDepartment,
        value: patchData.ToDepartment,
      });

      setValue("wardFrom", {
        label: patchData.FromWard,
        value: patchData.FromWard,
      });
      setValue("bedFrom", {
        label: patchData.FromBed,
        value: patchData.FromBed,
      });

      // if (patchData.ToDepartment !== "" && patchData.departmentId !== null) {
      //   setValue("departmentTo", {
      //     id: patchData.departmentId,
      //     label: patchData.ToDepartment,
      //     value: patchData.ToDepartment,
      //   });
      // }
      setValue("dietetics", patchData.Dietetics);
      setValue("dignosisAtTheTimeOfTransfer", patchData.Diagnosis);
      setValue("knownAllergies", patchData.KnownAllergies);
      setValue("mews", patchData.Mews);
      setValue("noteOnSurgeryProcedures", patchData.Procedures);
      setValue("otherNoteDetails", patchData.OtherNoteDetails);
      // setValue("patientTransferSearch",patchData);
      setValue("pendingInvestigations", patchData.PendingInvestingations);
      setValue("pulse", patchData.Pulse);
      setValue("reasonForTransfer", patchData.Reason);
      setValue("respiration", patchData?.Respiration);
      setValue("temp", patchData.Temp);
      setValue("transferDate", parseISO(patchData.Date));
      setValue("transferTime", parseISO(patchData.Time));
      // setValue("wardFrom", patchData.FromWard);
      if (patchData.ToWard !== "" && patchData.wardId !== null) {
        setValue("wardTo", {
          id: patchData.wardId,
          label: patchData.ToWard,
          value: patchData.ToWard,
        });
      }
    } else {
      setValue(
        "otherNoteDetails",
        selectedPatient?.HistoryOfPresentIllness || ""
      );
    }
  }, [patchData, emoloyeeName]);

  ///get User Action
  useEffect(() => {
    (location?.state?.menuId || props.menuId) &&
      fetchUserActionsByMenuId(props.menuId || location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });
  }, []);

  useEffect(() => {
    searchPatientInfo(props.admissionId);
  }, [props.admissionId]);

  console.log("propsprops321", props);

  console.log("patchData123", patchData);

  return (
    <>
      <div className="mt-12">
        {/* //heading// */}
        <div className="text-center text-black font-bold text-xl ">
          Patient Transfer
        </div>
        {/* search */}
        <div className=" py-2 grid grid-cols-3">
          <div className="md:col-span-2 lg:col-span-1">
            <SearchDropdown
              control={control}
              placeholder="Search By Patient Name / MR.No./Mob No."
              dataArray={options}
              name="patientTransferSearch"
              searchIcon={true}
              isClearable={true}
              handleInputChange={handleChangeSearch}
              isDisabled={
                patchData !== null || props.isFromClinicalCarechart
                  ? true
                  : false
              }
              inputRef={{
                ...register("patientTransferSearch", {
                  onChange: (e) => {
                    if (e.target.value !== null) {
                      setSelecteAdmissionId(e.target.value.AdmissionId);
                      searchPatientInfo(e.target.value.AdmissionId);
                    } else {
                      methods.reset();
                      setSelectedPatient(null);
                      setMedicationData([]);
                      setTabValue(0);
                      setIsFinalize(0);
                      setSelecteAdmissionId(null);
                    }
                  },
                }),
              }}
            />
          </div>
          <div className="md:col-span-1 lg:col-span-2 flex justify-end">
            {userActions.map((obj) => (
              <>
                {!obj.isAction &&
                obj.action === "View" &&
                props.isFromClinicalCarechart !== true ? (
                  <>
                    <div>
                      <CommonButton
                        label="Transfer List"
                        className="bg-customBlue text-white"
                        onClick={() => handleOpenPatientTransferModal()}
                      />
                    </div>
                  </>
                ) : null}
              </>
            ))}
          </div>
        </div>
        {/* patientInfo */}
        <fieldset
          className="border border-gray-300 col-span-3 w-full
               text-left lg:px-2 md:px-5 rounded bg-gray-100 p-2  "
        >
          <div className="py-2 grid  grid-cols-2 2xl:grid-cols-4 gap-2 p-3 ">
            <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
              <span className=" font-semibold w-28">Patient Name</span>
              <div className="flex space-x-2 items-center">
                <span>:</span>
                <span className="text-gray-700 font-normal">
                  {(selectedPatient && selectedPatient.patientName) ||
                    selectedPatient?.PatientName}
                </span>
              </div>
            </div>

            <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
              <span className=" font-semibold w-28">MR. No.</span>
              <div className="flex space-x-2 items-center">
                <span>:</span>
                <span className="text-gray-700 font-normal">
                  {selectedPatient && selectedPatient.MRNo}
                </span>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
      <div className="mt-3">
        <FormProvider {...methods}>
          <form
          //  onSubmit={methods.handleSubmit(onSubmit)}
          >
            <div className="grid grid-cols-12">
              <div className="col-span-12">
                <PatientTransferContext.Provider
                  value={{
                    //
                    medicationData,
                    setMedicationData,
                    //
                    emoloyeeName,
                    getEmoloyeeName,
                    rmoNameData,
                    getRMOsName
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <Box
                      sx={{
                        borderBottom: 1,
                        borderColor: "divider",
                        backgroundColor: "#dfe6e9",
                      }}
                    >
                      <Tabs
                        value={tabValue}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                        variant="scrollable"
                        scrollButtons
                      >
                        <Tab
                          label="Patient Transfer Details"
                          disabled={selectedPatient !== null ? false : true}
                          {...a11yProps(0)}
                        />
                        <Tab
                          label="Details Filled By RMO"
                          disabled={selectedPatient !== null ? false : true}
                          {...a11yProps(1)}
                        />
                        <Tab
                          label="Current Medication"
                          disabled={selectedPatient !== null ? false : true}
                          {...a11yProps(2)}
                        />
                      </Tabs>
                    </Box>
                    <CustomTabPanel value={tabValue} index={0}>
                      <PatientTransferDetails />
                    </CustomTabPanel>

                    <CustomTabPanel value={tabValue} index={1}>
                      <DetailsByRmo />
                    </CustomTabPanel>

                    <CustomTabPanel value={tabValue} index={2}>
                      <CurrentMedication />
                    </CustomTabPanel>
                  </Box>
                </PatientTransferContext.Provider>

                <div className="flex gap-3 justify-end lg:col-span-5 ">
                  {userActions.map((obj) => (
                    <>
                      {!obj.isAction &&
                        obj.action === "Finalise" &&
                        isFinalize !== 1 && (
                          <>
                            <div
                              onClick={() => {
                                if (RMO !== null && selectedPatient !== null) {
                                  setIsFinalize(1);
                                  setPrivilege(obj.action);
                                }
                              }}
                            >
                              <CommonButton
                                label="Finalize"
                                className="bg-customBlue text-white"
                                onClick={methods.handleSubmit(onSubmit)}
                              />
                            </div>
                          </>
                        )}
                      {!obj.isAction && obj.action === "Create" && (
                        <>
                          <div>
                            <CommonButton
                              label="Reset"
                              className="border border-customRed text-customRed"
                              onClick={() => {
                                methods.reset();
                                setSelectedPatient(null);
                                setMedicationData([]);
                                setTabValue(0);
                                setPatchData(null);
                                setIsFinalize(0);
                                setPtIdForEdit(0);
                              }}
                            />
                          </div>
                        </>
                      )}

                      {!obj.isAction &&
                      obj.action === "Create" &&
                      isFinalize !== 1 ? (
                        <>
                          <div
                            onClick={() => {
                              setIsFinalize(0);
                              setPrivilege(obj.action);
                            }}
                          >
                            {/* <SaveButton
                              onClick={methods.handleSubmit(onSubmit)}
                            /> */}
                            <CommonButton
                              label="Save"
                              className="bg-customGreen text-white"
                              onClick={methods.handleSubmit(onSubmit)}
                            />
                          </div>
                        </>
                      ) : null}
                    </>
                  ))}
                </div>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>

      {/*  Modal    */}
      {openPatientTransferModal ? (
        <PatientTransferListing
          open={openPatientTransferModal}
          setOpen={setOpenPatientTransferModal}
          handleOpen={handleOpenPatientTransferModal}
          handleClose={handleClosePatientTransferModal}
          handleEditData={handleEditData}
          userActions={userActions}
          AdmissionId={props.admissionId}

          //
        />
      ) : null}

      {/* backdrop */}
      <CommonBackDrop openBackdrop={openBackdrop} />
      {/* //Confirmation Modal// */}
      <ConfirmationModal
        confirmationOpen={openConfirmationModal}
        confirmationHandleClose={() => {
          setOpenConfirmationModal(false);
          setIsFinalize(0);
        }}
        confirmationSubmitFunc={() => handleFormSubmit(finalData)}
        confirmationLabel="Confirmation "
        confirmationMsg="Are You Sure ?"
        confirmationButtonMsg="Proceed"
      />
    </>
  );
};

export default PatientTransfer;
