import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";
import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";
import DropdownField from "../../../common/components/FormFields/DropdownField";

import { format, isAfter } from "date-fns";
import { useEffect } from "react";
import CommonDynamicTablePaginationNew from "../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import { fetchCodeFormList } from "../../services/codeFormsServices/CodeFormsServices";
import { EditOnIcon } from "../../../assets/icons/CustomIcons";
import LoadingSpinner from "../../../common/components/loadingspinner/loadingSpinner";

const codeTypeArr = [
  { id: 0, label: "Red", value: "Red" },
  { id: 1, label: "Violet", value: "Violet" },
  { id: 2, label: "Pink", value: "Pink" },
  { id: 3, label: "Brown", value: "Brown" },
  { id: 4, label: "Yellow", value: "Yellow" },
  { id: 5, label: "Orange", value: "Orange" },
  { id: 6, label: "Black", value: "Black" },
  { id: 7, label: "Blue", value: "Blue" },
];

let showSpinner1 = false;

export default function CodeFormsListing(props) {
  const token = JSON.parse(localStorage.getItem("userInfo"));

  const { handleClose, setSelectedRow } = props;
  ///related to table
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(25);
  const [count, setCount] = useState(null);
  const [dataResult, setDataResult] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);

  //
  const ModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "95%",
    minHeight: "70%",
    maxHeight: "90%",
    bgcolor: "background.paper",
    borderRadius: 2,
    overflowY: "auto",
    border: "none",
    "&:focus": {
      outline: "none",
    },
  };

  const methods = useForm({
    // resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      fromDate: new Date(),
      toDate: new Date(),
      codeType: {
        id: 0,
        label: "Red",
        value: "Red",
      },
    },
  });

  const {
    formState: { errors },
    control,
    setValue,
    register,
    watch,
    setError,
  } = methods;

  let FromDate = watch("fromDate");
  let ToDate = watch("toDate", new Date());
  let CodeType = watch("codeType");

  //
  // setUserActions(res.actions);

  useEffect(() => {
    if (FromDate && ToDate && CodeType) {
      isAfter(FromDate, new Date(1900, 1, 1)) &&
        isAfter(ToDate, new Date(1900, 1, 1)) &&
        CodeType &&
        populateTable();
    }
  }, [FromDate, ToDate, CodeType]);

  const populateTable = (forPagination) => {
    showSpinner1 = true;
    setShowSpinner(true);

    const listObj = {
      toDate: format(ToDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
      fromDate: format(FromDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
      page: !forPagination ? 0 : page,
      size: rowsPerPage,
      codeType: CodeType?.id || 0,
    };

    fetchCodeFormList(listObj)
      .then((response) => response.data)
      .then((res) => {
        if (forPagination) {
          setDataResult((prevData) => [...prevData, ...res.result]);
        } else {
          setDataResult(res.result);
          setPage(0);
        }
        setCount(res.count);
        setShowSpinner(false);
      });
  };

  ////////table related
  const renderActions = (row) => {
    return (
      <>
        <div className="flex gap-2 items-center">
          {/* {userActions.map((actions) => ( */}
          <>
            {/* {actions.isAction ? ( */}
            <>
              {/* <div className="text-customGray">
                    <Tooltip title="Print">
                      <LocalPrintshopIcon onClick={() => {}} />
                    </Tooltip>
                  </div> */}

              <div className="flex cursor-pointer items-center">
                {
                  // actions.action === "Create" &&
                  <EditOnIcon
                    title="Edit"
                    onClick={() => {
                      setSelectedRow(row);
                      // setPrivilege(actions.action);
                      handleClose();
                    }}
                  />
                }
              </div>
            </>
            {/* ) : null} */}
          </>
          {/* ))} */}
        </div>
      </>
    );
  };

  return (
    <div>
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[100%]">
          <CancelPresentationIconButton
            onClick={() => {
              handleClose();
            }}
          />

          <form>
            <div className="mx-3 mb-3 mt-2 items-center align-middle">
              <div className=" flex flex-wrap p-2 rounded  text-black font-semibold">
                Code Forms List
              </div>
              <hr className="border mx-1  border-customBlue" />

              <div className="grid md:grid-cols-3 lg:grid-cols-6 gap-3 mt-4">
                <div className="grid col-span-2 grid-cols-2 gap-3">
                  {/* from Date */}
                  <div className="">
                    <DatePickerFieldNew
                      control={control}
                      name="fromDate"
                      label="From Date"
                      value={new Date()}
                      disableFuture={true}
                      disablePast={false}
                      inputFormat="dd-MM-yyyy"
                    />
                  </div>
                  {/* from Date */}
                  <div className="">
                    <DatePickerFieldNew
                      control={control}
                      name="toDate"
                      label="To Date"
                      value={new Date()}
                      disableFuture={true}
                      disablePast={false}
                      inputFormat="dd-MM-yyyy"
                    />
                  </div>
                </div>{" "}
                <div className="">
                  <DropdownField
                    control={control}
                    // error={errors.MLCType}
                    name="codeType"
                    placeholder="Code Type"
                    dataArray={codeTypeArr}
                    isSearchable={false}
                  />
                </div>
              </div>

              <div>
                {showSpinner ? (
                  <div className="flex justify-center mt-14">
                    <LoadingSpinner />
                  </div>
                ) : dataResult.length > 0 ? (
                  <CommonDynamicTablePaginationNew
                    dataResult={dataResult}
                    populateTable={populateTable}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    count={count}
                    tableClass="lg:h-[350px] 2xl:h-[550px]"
                    removeHeaders={[
                      "ID",
                      "Code Type",
                      "Creation Date",
                      "Creation Time",
                      "Escalated To Admin",
                      "Is Mock Drill",
                      "Outcome Of Code",
                      "Time Of Arrival",
                      "timeForPatch",
                      "Is Strock Time",
                      "Strock Time",
                      "Basic Life",
                      "Adv Life",
                      "MRI_CT",
                      "Code Out Come",
                      "Code Team",
                      "Current Location",
                    ]}
                    renderActions={renderActions}
                    highlightRow={false}
                  />
                ) : (
                  <p className="text-center my-6 ">No Data Found</p>
                )}
              </div>

              {/* <div className="gap-3 my-3 flex justify-end">
                <CommonButton
                  label="Cancel"
                  className="border border-customRed text-customRed"
                  onClick={() => handleClose()}
                />
              </div> */}
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
