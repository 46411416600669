import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

import DropdownField from "../../../common/components/FormFields/DropdownField";
import InputField from "../../../common/components/FormFields/InputField";

import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import { warningAlert } from "../../../common/components/Toasts/CustomToasts";
import {
  fetchCabin,
  fetchCampData,
  fetchCompanyAsst,
  fetchCompanyByPatientCategory,
  fetchDepartment,
  fetchDoctorListByDepartmentId,
  fetchEmoloyeeDependent,
  fetchEmoloyeeName,
  fetchEmployeeDetails,
  fetchOccupation,
  fetchPatientCategory,
  fetchPatientsource,
  fetchReferanceDoctor,
  fetchTariffByCompCategory,
} from "../../../commonServices/miscellaneousServices/MiscServices";
import {
  fetchSource,
  fetchVisitStatus,
} from "../../services/registrationServices/PatientRegistrationServices";
import CreateableSelect from "../../../common/components/FormFields/CreateableSelect";

const VisitDetails = (props) => {
  let token = JSON.parse(localStorage.getItem("userInfo"));
  const [patientSource, setPatientSource] = useState();
  const [patientCategory, setPatientCategory] = useState();
  const [source, setSource] = useState();
  const [department, setDepartment] = useState();
  const [departmentId, setDepartmentId] = useState();
  const [departmentSearchString, setDepartmentSearchString] = useState();
  const [doctor, setDoctor] = useState();
  const [doctorSearchString, setDoctorSearchString] = useState();
  const [cabin, setCabin] = useState();
  const [tariff, setTariff] = useState([]);
  const [company, setCompany] = useState(false);
  const [companyAsst, setCompanyAsst] = useState();
  const [emoloyeeName, setEmoloyeeName] = useState();
  const [emoloyeeDependent, setEmoloyeeDependent] = useState();
  const [occupation, setOccupation] = useState();
  //
  const [referralDoctor, setReferralDoctor] = useState();
  const [campData, setCampData] = useState();
  const [referanceDoctorSearchString, setReferanceDoctorSearchString] =
    useState();



  const {
    setPatientCategoryChange,
    patientCategoryChange,
    registerOnly,
    searchedPatientDetails,
    setIsAlreadyVisited,
  } = props;

  const {
    control,
    formState: { errors },
    watch,
    register,
    setValue,
    resetField,
    setError,
  } = useFormContext();

  const PatientCategory = watch("patientCategory");
  const PatientSource = watch("patientSource");
  const empId = watch("staffName");
  const dependantId = watch("staffDependent");
  const selectedCompany = watch("company");
  const selectedDoctor = watch("doctor");
  const selectedDepartment = watch("department");
  const firstName = watch("firstName");
  const middleName = watch("middleName");
  const lastName = watch("lastName");
  const staffName = watch("staffName");
  //
  //
  useEffect(() => {
    getDepartment();
  }, [departmentSearchString]);

  useEffect(() => {
    getDoctor();
  }, [doctorSearchString, departmentId]);

  // useEffect(() => {
  //   getCompany();
  // }, [PatientCategory]);

  useEffect(() => {
    getCompanyAsst();
    getTariff();
  }, [selectedCompany, PatientCategory]);

  useEffect(() => {
    if (empId !== "" && empId !== undefined) {
      getEmployeeDetails();
    }
  }, [empId, dependantId]);

  useEffect(() => {
    if (empId !== "" && empId !== undefined) {
      getEmoloyeeDependent();
    }
  }, [empId]);

  useEffect(() => {
    getCampData();
    getPatientsource();
    getPatientCategory();
    getSource();
    getCabin();
    getEmoloyeeName();
    getOccupation();
  }, []);

  useEffect(() => {
    if (searchedPatientDetails !== null) {
      if (patientCategoryChange === " Staff") {
        checkIsEmployee(searchedPatientDetails.EmpName);
      } else if (patientCategoryChange === "Staff Dependant") {
        checkIsEmployeeDependent(searchedPatientDetails.DependentName);
      }
    }
  }, [searchedPatientDetails, patientCategoryChange]);

  //////to check typed name is employee or not
  const checkIsEmployee = (e) => {
    if (e !== null) {
      let Staff = e.split(" ").join("").toLowerCase();

      let patientName = (lastName + firstName + middleName).toLowerCase();

      // if () {

      if (patientName !== Staff && patientCategoryChange === "Staff") {
        props.setCustomError(true);
        warningAlert("Patient Name & Employee Name Mismatch !");
      } else if (patientName === Staff && patientCategoryChange === "Staff") {
        props.setCustomError(false);
      }
    }
  };
  //////to check typed name is Employee Dependent or not
  const checkIsEmployeeDependent = (e) => {
    if (e !== null) {
      let Staff = e?.split(" ")?.join("")?.toLowerCase();

      let patientName = (firstName + lastName)?.toLowerCase();

      if (patientCategoryChange === "Staff Dependant") {
        if (
          patientName !== Staff &&
          patientCategoryChange === "Staff Dependant"
        ) {
          props.setCustomError(true);
          warningAlert("Patient Name & Employee Dependent Name Mismatch !");
        } else if (
          patientName === Staff &&
          patientCategoryChange === "Staff Dependant"
        ) {
          props.setCustomError(false);
        }
      }
    }
  };

  // Api to Get Patient Source
  const getPatientsource = () => {
    fetchPatientsource()
      .then((response) => {
        //
        setPatientSource(response.data.result);
      })
      .catch((response) => {
        //
      });
  };

  // Api to Get Patient Source
  const getCampData = () => {
    fetchCampData()
      .then((response) => {
        //
        setCampData(response.data.result);
      })
      .catch((response) => {
        //
      });
  };

  // Api to Get Patient Source
  const getSource = () => {
    fetchSource()
      .then((response) => {
        //
        setSource(response.data.result);
      })
      .catch((response) => {
        //
      });
  };
  // Api to Get Patient Category
  const getPatientCategory = () => {
    fetchPatientCategory()
      .then((response) => {
        //
        setPatientCategory(response.data.result);
      })
      .catch((response) => {
        //
      });
  };
  // Api to Get department
  const getDepartment = () => {
    departmentSearchString &&
      fetchDepartment(departmentSearchString)
        .then((response) => {
          //
          setDepartment(response.data.result);
        })
        .catch((response) => {
          //
        });
  };

  // Api to Get Doctor by department id
  const getDoctor = () => {
    departmentId &&
      fetchDoctorListByDepartmentId(departmentId, token.unitId)
        .then((response) => {
          setDoctor(response.data.result);
        })
        .catch((response) => {
          //
        });
  };

  // Api to Get cabin
  const getCabin = () => {
    fetchCabin()
      .then((response) => {
        //
        setCabin(response.data.result);
      })
      .catch((response) => {
        //
      });
  };

  // Api to Get Tariff
  const getTariff = () => {
    //

    if (PatientCategory !== null) {
      fetchTariffByCompCategory(
        PatientCategory?.id,
        selectedCompany?.id !== undefined ? selectedCompany?.id : 0
      ).then((response) => {
        //
        setTariff(response.data.result);
        if (PatientCategory?.id === 16) {
          setValue("tariff", response?.data?.result[1]);
        } else {
          setValue("tariff", response?.data?.result[0]);
        }
      });
    }
  };

  // Api to Get Company
  const getCompany = (catId) => {
    if (!!catId) {
      fetchCompanyByPatientCategory(catId).then((response) => {
        //
        setCompany(response.data.result);
      });
    }
  };

  // Api to Get Asst Company
  const getCompanyAsst = () => {
    selectedCompany &&
      fetchCompanyAsst(selectedCompany.id)
        .then((response) => {
          //
          setCompanyAsst(response.data.result);
        })
        .catch((response) => {
          //
        });
  };

  // Api to Get Employee name
  const getEmoloyeeName = () => {
    fetchEmoloyeeName()
      .then((response) => {
        //
        setEmoloyeeName(response.data.result);
      })
      .catch((response) => {
        //
      });
  };
  // Api to Get Employee dependent name
  const getEmoloyeeDependent = () => {
    if (empId !== null && empId.id) {
      fetchEmoloyeeDependent(empId.id)
        .then((response) => {
          //
          setEmoloyeeDependent(response.data.result);
        })
        .catch((response) => {
          //
        });
    }
  };
  // Api to Get limit used code
  const getEmployeeDetails = () => {
    if (empId !== null && empId !== undefined && dependantId !== null) {
      fetchEmployeeDetails(
        empId.id,
        dependantId !== undefined ? dependantId.id : 0
      )
        .then((response) => {
          if (empId !== undefined && empId !== "") {
            setValue("code", response.data.result.Code);
            setValue("limit", response.data.result.PharmacyBalanceAmt);
            setValue("used", response.data.result.PharmacyHMSAmt);
          }
          // setEmoloyeeDetails(response.data.result);
        })
        .catch((response) => {
          //
        });
    }
  };

  //API For Referral Doctor List
  useEffect(() => {
    getReferanceDoctor();
  }, [referanceDoctorSearchString]);

  const getReferanceDoctor = () => {
    referanceDoctorSearchString &&
      fetchReferanceDoctor(referanceDoctorSearchString)
        .then((response) => {
          setReferralDoctor(response.data.result);
        })
        .catch((response) => {
          //
        });
  };

  // Api to Get Employee name
  const getOccupation = () => {
    fetchOccupation()
      .then((response) => {
        //
        setOccupation(response.data.result);
      })
      .catch((response) => {
        //
      });
  };
  ////to get visited status is already visited or not
  useEffect(() => {
    searchedPatientDetails &&
      selectedDepartment &&
      selectedDoctor &&
      //
      getVisitStatus();
  }, [searchedPatientDetails, selectedDepartment, selectedDoctor]);

  const getVisitStatus = () => {
    setDepartmentId(selectedDepartment.id);

    fetchVisitStatus(
      searchedPatientDetails.id,
      selectedDepartment.id,
      selectedDoctor.id
    )
      .then((response) => {
        setIsAlreadyVisited(response.data.result.visitMarked);
        if (response.data.result.visitMarked && !props.isForEdit === 1) {
          warningAlert("Visit Already Marked For This Doctor !");
        }
      })
      .catch((response) => {
        //
      });
  };

  return (
    <>
      {registerOnly !== true ? (
        <div className="grid lg:grid-cols-5 md:grid-cols-3 gap-3">
          {/* ///Source /// */}
          <div>
            <DropdownField
              control={control}
              // error={errors.patientSource}
              name="source"
              label=" Source"
              dataArray={source}
              isSearchable={false}
              placeholder=" Source"
              isClearable={false}
            />
          </div>
          {/* ///Patient Source /// */}
          <div>
            <DropdownField
              control={control}
              error={errors.patientSource}
              name="patientSource"
              label="Patient Source *"
              dataArray={patientSource}
              isSearchable={false}
              placeholder="Patient Source *"
              isClearable={false}
            />
          </div>
          {PatientSource.value === "Camp" ? (
            <div>
              <DropdownField
                control={control}
                error={errors.camp}
                name="camp"
                label="Camp"
                dataArray={campData}
                isSearchable={false}
                placeholder="Camp"
                isClearable={false}
              />
            </div>
          ) : null}
          {PatientSource.value === "Referal" ? (
            <div>
              <CreateableSelect
                name="referralDoctor"
                placeholder="Adviced Doctor"
                dataArray={referralDoctor}
                onInputChange={(e) => {
                  setReferanceDoctorSearchString(e);
                }}
                searchIcon={true}
                error={errors.referralDoctor}
                control={control}
                isClearable={true}
              />
            </div>
          ) : null}

          {/* ///Patient Category /// */}
          <div>
            <DropdownField
              control={control}
              error={errors.patientCategory}
              name="patientCategory"
              label="Patient Category"
              dataArray={patientCategory}
              isSearchable={false}
              placeholder="Patient Category *"
              isClearable={false}
              inputRef={{
                ...register("patientCategory", {
                  onChange: (e) => {
                    setPatientCategoryChange(e.target.value.value);
                    getCompany(e?.target?.value?.id);

                    resetField("staffName");
                    resetField("staffDependent");
                    resetField("company");
                    resetField("companyAsst");
                    resetField("code");
                    resetField("limit");
                    resetField("used");
                    //

                    if (e.target.value.value !== "Staff") {
                      props.setCustomError(false);
                    } else if (e.target.value.value !== "Staff Dependant") {
                      props.setCustomError(false);
                    }
                  },
                }),
              }}
            />
          </div>
          {/* ///Department /// */}
          <div>
            <SearchDropdown
              control={control}
              error={errors.department}
              name="department"
              label="Department"
              dataArray={department}
              handleInputChange={(e) => {
                //
                setDepartmentSearchString(e);
              }}
              inputRef={{
                ...register("department", {
                  onChange: (e) => {
                    if (e.target.value !== null) {
                      setDepartmentId(e.target.value.id);
                    }

                    //
                    resetField("doctor");
                  },
                }),
              }}
              isSearchable={true}
              placeholder="Department *"
              isClearable={true}
              searchIcon={true}
            />
          </div>
          {/* ///Doctor /// */}
          <div>
            <SearchDropdown
              control={control}
              error={errors.doctor}
              name="doctor"
              label="Doctor"
              dataArray={doctor}
              handleInputChange={(e) => {
                //
                setDoctorSearchString(e);
              }}
              isSearchable={true}
              searchIcon={true}
              placeholder="Doctor *"
              isClearable={true}
            />
          </div>
          {/* ///Cabin /// */}
          <div>
            <DropdownField
              control={control}
              error={errors.cabin}
              name="cabin"
              label="Cabin"
              dataArray={cabin}
              isSearchable={false}
              placeholder="Cabin *"
              isClearable={false}
            />
          </div>
          {/* ///tariff /// */}
          <div>
            <DropdownField
              control={control}
              name="tariff"
              error={errors.tariff}
              dataArray={tariff}
              isSearchable={false}
              placeholder="Tariff *"
              isClearable={false}
            />
          </div>
          {patientCategoryChange !== "Self" &&
          patientCategoryChange !== "International" &&
          patientCategoryChange !== "Ipf" ? (
            <>
              {/* ///Company /// */}
              <div>
                <DropdownField
                  control={control}
                  error={errors.company}
                  name="company"
                  dataArray={company}
                  placeholder="Company *"
                  isClearable={true}
                  isSearchable={true}
                  searchIcon={true}
                  inputRef={{
                    ...register("company", {
                      onChange: (e) => {
                        //

                        if (e) {
                          resetField("companyAsst");
                        } else {
                          setCompanyAsst([]);
                        }
                      },
                    }),
                  }}
                />
              </div>
              {/* ///Company (Ass.) /// */}
              <div>
                <DropdownField
                  control={control}
                  error={errors.companyAsst}
                  name="companyAsst"
                  dataArray={companyAsst}
                  placeholder="Company(Asst.)*"
                  isClearable={true}
                  isSearchable={true}
                  searchIcon={true}
                />
              </div>
            </>
          ) : null}
        </div>
      ) : null}

      {/* ///Fields display According patient catagory change /// */}
      {patientCategoryChange === "Staff" && registerOnly !== true ? (
        <>
          <hr className="border my-2 divide-x-8 bg-slate-300 border-slate-300" />
          <p className="font-bold tracking-wide text-sm my-3 font-Poppins">
            Employee Information
          </p>
          <div className="grid lg:grid-cols-5 md:grid-cols-3 gap-3">
            {/* ///Employee Name /// */}

            <div>
              <DropdownField
                control={control}
                error={errors.staffName}
                name="staffName"
                label="Staff Name *"
                dataArray={emoloyeeName}
                placeholder=" Staff Name *"
                isClearable={false}
                isSearchable={true}
                searchIcon={false}
                inputRef={{
                  ...register("staffName", {
                    onChange: (e) => {
                      checkIsEmployee(e.target.value.value);
                    },
                  }),
                }}
              />
            </div>
            {/* //staff dependent
            <div>
              <DropdownField
                control={control}
                error={errors.staffName}
                name="staffDependent"
                label="Staff Dependent "
                dataArray={emoloyeeDependent}
                isSearchable={false}
                placeholder=" Staff Dependent "
                isClearable={false}
              />
            </div> */}
            {/* ///Code/// */}
            <div>
              <InputField
                // sx={{backgroundColor: '#808080'}}
                name="code"
                variant="outlined"
                label="Code "
                // error={errors.code}
                control={control}
                disabled={true}
                isDisabled={true}
                inputProps={{ maxLength: 50 }}
              />
            </div>

            {/* ///Limit/// */}
            <div>
              <InputField
                name="limit"
                variant="outlined"
                label="Limit "
                //   error={errors.limit}
                control={control}
                disabled={true}
                inputProps={{ maxLength: 50 }}
              />
            </div>
            {/* ///Used/// */}
            <div>
              <InputField
                name="used"
                variant="outlined"
                label="Used "
                //   error={errors.used}
                control={control}
                disabled={true}
                inputProps={{ maxLength: 50 }}
              />
            </div>
          </div>
        </>
      ) : null}
      {patientCategoryChange === "Staff Dependant" && registerOnly !== true ? (
        <>
          <hr className="border my-2 divide-x-8 bg-slate-300 border-slate-300" />
          <p className="font-bold tracking-wide text-sm my-3 font-Poppins">
            Employee Information
          </p>

          <div className="grid lg:grid-cols-5 md:grid-cols-3 gap-3">
            {/* ///Employee Name /// */}

            <div>
              <DropdownField
                control={control}
                error={errors.staffName}
                name="staffName"
                label="Staff Name *"
                dataArray={emoloyeeName}
                placeholder=" Staff Name *"
                isClearable={false}
                isSearchable={true}
                searchIcon={false}
                inputRef={{
                  ...register("staffName", {
                    onChange: (e) => {
                      // checkIsEmployee(e.target.value.value);
                      setValue("staffDependent", null);
                    },
                  }),
                }}
              />
            </div>
            {/* //staff dependent */}
            <div>
              <DropdownField
                control={control}
                // error={errors.staffName}
                name="staffDependent"
                label="Staff Dependent "
                dataArray={emoloyeeDependent}
                isSearchable={false}
                placeholder=" Staff Dependent "
                isClearable={false}
                inputRef={{
                  ...register("staffDependent", {
                    onChange: (e) => {
                      checkIsEmployeeDependent(e.target.value.value);
                    },
                  }),
                }}
              />
            </div>
            {/* ///Code/// */}
            <div>
              <InputField
                // sx={{backgroundColor: '#808080'}}
                name="code"
                variant="outlined"
                label="Code "
                // error={errors.code}
                control={control}
                disabled={true}
                inputProps={{ maxLength: 50 }}
              />
            </div>

            {/* ///Limit/// */}
            <div>
              <InputField
                name="limit"
                variant="outlined"
                label="Limit "
                //   error={errors.limit}
                control={control}
                disabled={true}
                inputProps={{ maxLength: 50 }}
              />
            </div>
            {/* ///Used/// */}
            <div>
              <InputField
                name="used"
                variant="outlined"
                label="Used "
                //   error={errors.used}
                control={control}
                disabled={true}
                inputProps={{ maxLength: 50 }}
              />
            </div>
          </div>
          {/* {registerOnly === true ? (
            <>
              <hr className="border my-2 divide-x-8 bg-slate-300 border-slate-300" />
              <p className="font-bold tracking-wide text-sm my-3 font-Poppins">
                Patient Office Details
              </p>
            </>
          ) : null} */}

          {/* <div className="grid lg:grid-cols-5 md:grid-cols-3 gap-3">
            ///Occupation ///

            <div>
              <DropdownField
                control={control}
                // error={errors.referralType}
                name="occupation"
                label="Occupation"
                dataArray={occupation}
                isSearchable={false}
                placeholder="Occupation"
                isClearable={false}
              />
            </div>
            ///Office Name///
            <div>
              <InputField
                name="officeName"
                variant="outlined"
                label="Office Name "
                error={errors.officeName}
                control={control}
                disabled={false}
                inputProps={{ maxLength: 50 }}
              />
            </div>

            ///Address///
            <div className="col-span-2">
              <InputField
                name="patientOfficeAddress"
                variant="outlined"
                label="Address "
                error={errors.patientOfficeAddress}
                control={control}
                disabled={false}
                inputProps={{ maxLength: 50 }}
              />
            </div>
          </div> */}
        </>
      ) : null}

      {/* {patientCategoryChange !== "Staff Dependant" &&
      patientCategoryChange !== "Staff" ? ( */}

      {registerOnly !== true ? (
        <>
          <hr className="border my-2 divide-x-8 bg-slate-300 border-slate-300" />
          <p className="font-bold tracking-wide text-sm my-3 font-Poppins">
            Patient Office Details
          </p>
        </>
      ) : null}

      <div className="grid lg:grid-cols-5 md:grid-cols-3 gap-3">
        {/* ///Occupation /// */}

        <div>
          <DropdownField
            control={control}
            // error={errors.referralType}
            name="occupation"
            label="Occupation"
            dataArray={occupation}
            isSearchable={false}
            placeholder="Occupation"
            isClearable={false}
          />
        </div>
        {/* ///Office Name/// */}
        <div>
          <InputField
            name="officeName"
            variant="outlined"
            label="Office Name "
            error={errors.officeName}
            control={control}
            disabled={false}
            inputProps={{ maxLength: 50 }}
          />
        </div>

        {/* ///Address/// */}
        <div className="lg:col-span-2">
          <InputField
            name="patientOfficeAddress"
            variant="outlined"
            label="Address "
            error={errors.patientOfficeAddress}
            control={control}
            disabled={false}
            inputProps={{ maxLength: 50 }}
          />
        </div>
      </div>

      {/* ) : null} */}
    </>
  );
};

export default VisitDetails;
