import {
  Box,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import React from "react";
import CheckBoxField from "../../common/components/FormFields/CheckBoxField";
import { getSalesItemDetails } from "./Services";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";

function SalesListTable(props) {
  const [selectedIndex, setSelectedIndex] = React.useState(-1);

  const removeHeaders = (headers, fieldToRemove) => {
    return headers.filter((v) => {
      return !fieldToRemove.includes(v);
    });
  };

  //set rows object to table
  const allHeaders = Object.keys(props.data.result[0]);

  const headers = removeHeaders(allHeaders, [
    "Id",
    "EMPId",
    "Cash_Credit_Staff",
    "BalanceAmountNonSelf",
    "IsConsignment",
    "Opd_Ipd_External",
    "Opd_IPD_ExternalId",
    "employeeId",
    "SalesId",
    "StoreId",
  ]);

  const handleSelectChange = (row) => {
    let salesId = row.SalesId;
    props.setSelectedPatient(row)
    props.setSelectedSalesId(salesId);
    props.setPatientName(row?.["Patient Name"]);
    props.setPatientInfo(row);
  };

  return (
    <>
      <div className="w-auto grid">
        <Box sx={{ width: "100%", overflow: "hidden" }}>
          <Paper sx={{ width: "100%", my: 2 }}>
            <TableContainer
              sx={{
                "&::-webkit-scrollbar": {
                  width: 7,
                  height: 10,
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#7393B3",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "lightBlue",
                },
              }}
              className="rounded max-h-60"
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow
                    sx={{
                      "& th": {
                        paddingY: 0.5,
                        backgroundColor: "#F1F1F1",
                      },
                    }}
                  >
                    <TableCell>
                      <span className="text-gray-600 font-bold">Action</span>
                    </TableCell>
                    {headers?.map((header, index) => (
                      <TableCell className="whitespace-nowrap" key={index}>
                        <span className="text-gray-600 font-bold">
                          {header}
                        </span>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {props.data?.result?.map((row, index) => {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          "& td": {
                            paddingY: 0.1,
                          },
                        }}
                      >
                        <TableCell>
                          <div className="flex gap-1 items-center">
                            <div>
                              <Checkbox
                                size="small"
                                checked={selectedIndex === index}
                                onChange={() => {
                                  setSelectedIndex(index);
                                  handleSelectChange(row);
                                }}
                              />
                            </div>
                            <div>
                              {props.component === "counter" ? (
                                <Tooltip title="Print Bill">
                                  <button
                                    onClick={() => {
                                      props.openPrintWindow(row?.SalesId);
                                    }}
                                  >
                                    <LocalPrintshopIcon />
                                  </button>
                                </Tooltip>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </TableCell>
                        {headers &&
                          headers.map((header, i) => (
                            <TableCell
                              className="whitespace-nowrap capitalize"
                              key={i}
                            >
                              {row[header]}
                            </TableCell>
                          ))}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </div>
    </>
  );
}

export default SalesListTable;

