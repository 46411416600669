import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Modal,
} from "@mui/material";

import BlankProfile from "../../../assets/registrationAssets/blankProfile.jpeg";
import BasicInfo from "./BasicInfo";
import AddressInfo from "./AddressInfo";
import ConfirmationModal from "../../../common/components/ConfirmationModal";

import {
  warningAlert,
  successAlert,
  errorAlert,
  validationAlert,
} from "../../../common/components/Toasts/CustomToasts";
import * as yup from "yup";
import VisitDetails from "./VisitDetails";
import {
  fetchBlacklistAproval,
  fetchOpdRegistrationCasePaperPrint,
  fetchSearchedregistrationData,
  PostMyPatientRegistrationInfo,
} from "../../services/registrationServices/PatientRegistrationServices";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import { baseUrl } from "../../../http-common";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import { useLocation } from "react-router-dom";
import { fetchUserActionsByMenuId } from "../../../commonServices/functionalityServices/FunctionalityServices";
import CommonPrintModal from "../../../common/components/printModal/CommonPrintModal";
import OpdBillModal from "./OpdBillModal";
import { format } from "date-fns";

const PatientRegistrationForm = () => {
  const location = useLocation();
  //userActions
  const [userActions, setUserActions] = useState([]);
  const [privilege, setPrivilege] = useState();

  //
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [openBlacklistModal, setOpenBlacklistModal] = React.useState(false);
  //useState for errors
  const [customError, setCustomError] = useState(false);
  const [duplicateAdhar, setDuplicateAdhar] = useState(false);
  const [duplicatePatient, setDuplicatePatient] = useState(false);
  //useState to Expanded Accordion
  const [expandPanal1, setExpandPanal1] = useState(true);

  const [expandPanal3, setExpandPanal3] = useState(true);

  //useState to get Age based on selected DOB
  const [age, setAge] = useState(0);
  const [gender, setGender] = useState();
  //useState to setProfile Pic for Patient
  const [profilePic, setProfilePic] = useState(BlankProfile);
  const [profilePicName, setProfilePicName] = React.useState(null);

  //useState to Identification Document File for Patient
  const [identificationDocFile, setIdentificationDocFile] = useState("");

  //date
  const [registrationDate, setRegistrationDate] = useState(new Date());
  // const [dobState, setDobState] = useState(new Date());
  const [visitDate, setVisitDate] = useState(new Date());

  const [searchedPatientDetails, setSearchedPatientDetails] = useState(null);
  //
  const [searchPatientId, setsearchPatientId] = useState(null);
  const [visitId, setVisitId] = useState(0);
  const [isForEdit, setIsForEdit] = useState(false);
  //
  const [bloodgroups, setBloodgroups] = useState();
  const [maritalStatus, setmaritalStatus] = useState();
  const [registerOnly, setRegisterOnly] = useState();
  const [selectedDoc, setSelectedDoc] = useState("Aadhar Card");
  const [patientCategoryChange, setPatientCategoryChange] = useState("Self");
  //for visit Details...
  const [formData, setFormData] = React.useState();
  const [openModal, setOpenModal] = React.useState(false);

  //
  const [isAlreadyVisited, setIsAlreadyVisited] = React.useState(0);

  const [openBlacklistCarryModal, setOpenBlacklistCarryModal] =
    React.useState(false);
  //opd bill modal
  const [openOpdBillModal, setOpenOpdBillModal] = React.useState(false);
  const handleOpenOpdBillModal = () => setOpenOpdBillModal(true);
  const handleCloseOpdBillModal = () => setOpenOpdBillModal(false);

  //prints
  const [urlforPrint, setUrlforPrint] = useState();
  const [openPrintModal, setOpenPrintModal] = React.useState(false);
  const handleClosePrintModal = () => {
    setOpenPrintModal(false);
  };
  const handleOpenPrintModal = (patientId) => {
    fetchOpdRegistrationCasePaperPrint(patientId).then((response) => {
      setUrlforPrint(response);
    });
    setOpenPrintModal(true);
  };
  //
  const patientData = "location.state";
  let patientId = null;
  if (patientData !== null) {
    patientId = patientData.patientId;
  }
  //////to get loginId adn unitId from local starage
  let token = JSON.parse(localStorage.getItem("userInfo"));
  let unitId = token.unitId;

  const schema = yup.object().shape({
    check: yup.boolean(),

    ///email//
    email: yup.string().nullable().email().notRequired(),

    ///prefix//
    prefix: yup
      .object()
      .nullable()
      .shape({
        value: yup.string().required("Please Select Prefix"),
        label: yup.string().required("Please Select Prefix"),
      })
      .required("Required"),

    ///firstName//
    firstName: yup
      .string()
      .matches(
        /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s.,-_]*)$/gi,
        "Please Give Last Name in Proper Format"
      )
      .required("Required")
      .min(1),

    ///middleName//
    middleName: yup.string().notRequired(),

    ///lastName//

    lastName: yup
      .string()
      .matches(
        /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s.,-_]*)$/gi,
        "Please Give Last Name in Proper Format"
      )
      .required("Required")
      .min(1),

    ///mobileNumber//
    mobileNumber: yup
      .string()
      .matches(/^[0-9]+$/, "Provide Valid Mobile No.")
      .min(10, "Provide Valid Mobile No.")
      .max(10, "Provide Valid Mobile No.")
      .required("Required"),

    dobDate: yup
      .date()
      .typeError("Please Provide Date of Birth in Valid Format")
      .min("01/01/1902", "You are Not Eligible")
      .max(new Date(), "You can't be born in the future!")
      .required(),

    ///age//
    age: yup
      .string()
      .matches(/^[0-9]+$/, "Not Valid")
      .min(0, "You must be at least 18 years")
      .max(121, "You must be at most 60 years")
      .required("Required"),

    ///email//

    email: yup
      .string()
      .email("Invalid email format")
      .when("noEmail", {
        is: false,
        then: yup.string().required("Email is required"),
        otherwise: yup.string().notRequired(),
      }),

    ///gender//
    gender: yup
      .object()
      .nullable()
      .shape({
        value: yup.string().notRequired("Please Select gender"),
        label: yup.string().notRequired("Please Select gender"),
      })
      .required("Required"),

    //adharNumber//
    adharNumber: yup.string().test((val) => {
      if (val === "0") return val.toString().length === 1;
      if (val !== "0") return val.toString().length === 12;
    }),

    //passportNumber
    //  passportNumber: yup.string().when(["country"], {
    //    is: (country) => {
    //      if (country !== null) {

    //

    //        if (country.label === "India") {
    //
    //          clearErrors("passportNumber");
    //          return true;
    //        }

    //        return country.label !== "India";
    //      }
    //    },
    //    then: yup.string().required("Required"),
    //  }),

    ///nationality//
    nationality: yup
      .object()
      .nullable()
      .shape({
        value: yup.string().required("Please Select Nationality"),
        label: yup.string().required("Please Select Nationality"),
      })
      .notRequired(),

    ///////////////////////   Address Information ///////////////////

    houseFlatNumber: yup.string().required("Required"),
    society: yup.string().required("Required"),
    nearestLandmark: yup.string().required("Required"),

    ///country
    country: yup
      .object()
      .nullable()
      .shape({
        value: yup.string().required("Please Select Country"),
        label: yup.string().required("Please Select Country"),
      })
      .required("Required"),

    ///state
    state: yup
      .object()
      .nullable()
      .shape({
        value: yup.string().required("Please Select State"),
        label: yup.string().required("Please Select State"),
      })
      .required("Required"),

    ///district
    district: yup
      .object()
      .nullable()
      .shape({
        value: yup.string().required("Please Select District"),
        label: yup.string().required("Please Select District"),
      })
      .required("Required"),

    ///city
    city: yup
      .object()
      .nullable()
      .shape({
        value: yup.string().required("Please Select City"),
        label: yup.string().required("Please Select City"),
      })
      .required("Required"),

    ///area
    area: yup
      .object()
      .nullable()
      .shape({
        value: yup.string().required("Please Select Area"),
        label: yup.string().required("Please Select Area"),
      })
      .required("Required"),

    ///pinCode
    //pinCode: yup.string().nullable().Number().notRequired(),
    // pinCode: yup
    //   .string()
    //   .matches(/^[0-9]+$/, "Not Valid")
    //   .max(6, "Not Valid")
    //   .notRequired(),

    //////////////////////// Referral Doctor Details //////////////////////////

    ///referralDoctor
    referralDoctor: yup
      .object()
      .nullable()
      .shape({
        value: yup.string().required("Please Select Referral Doctor"),
        label: yup.string().required("Please Select Referral Doctor"),
      })
      .notRequired(),

    ////////////////////////////  Visit Details   //////////////////////////////

    ///    dateOfVisit

    dateOfVisit: yup
      .date()
      .typeError("Please Provide Date in Valid Format")
      .min("01/01/1950", "You are Not Eligible")
      .max(new Date(), "You can't be visit in the future!")
      .notRequired(),

    ///    patientCategory: yup
    patientCategory: yup
      .object()
      .nullable()
      .shape({
        value: yup.string().required("Required"),
        label: yup.string().required("Required"),
      })
      .when("check", {
        is: (check) => {
          if (check) {
            clearErrors("patientCategory");
          }
          return check === false;
        },
        then: yup.object().nullable().required("Required"),
      }),

    ////patientSource
    patientSource: yup
      .object()
      .nullable()
      .shape({
        value: yup.string().required("Required"),
        label: yup.string().required("Required"),
      })
      .when("check", {
        is: (check) => {
          if (check) {
            clearErrors("patientSource");
          }
          return check === false;
        },
        then: yup.object().nullable().required("Required"),
      }),

    ///department
    department: yup
      .object()
      .nullable()
      .shape({
        value: yup.string().required("Required"),
        label: yup.string().required("Required"),
      })
      .when("check", {
        is: (check) => {
          if (check) {
            clearErrors("department");
          }
          return check === false;
        },
        then: yup.object().nullable().required("Required"),
      }),
    ////doctor
    doctor: yup
      .object()
      .nullable()
      .shape({
        value: yup.string().required("Required"),
        label: yup.string().required("Required"),
      })
      .when("check", {
        is: (check) => {
          if (check) {
            clearErrors("doctor");
          }
          return check === false;
        },
        then: yup.object().nullable().required("Required"),
      }),

    ////cabin
    cabin: yup
      .object()
      .nullable()
      .shape({
        value: yup.string().required("Required"),
        label: yup.string().required("Required"),
      })
      .when("check", {
        is: (check) => {
          if (check) {
            clearErrors("cabin");
          }
          return check === false;
        },
        then: yup.object().nullable().required("Required"),
      }),

    ////tariff
    tariff: yup
      .object()
      .nullable()
      .shape({
        value: yup.string().required("Required"),
        label: yup.string().required("Required"),
      })
      .when("check", {
        is: (check) => {
          if (check) {
            clearErrors("tariff");
          }
          return check === false;
        },
        then: yup.object().nullable().required("Required"),
      }),

    //company

    company: yup
      .object()
      .nullable()
      .shape({
        value: yup.object().nullable().required("Required"),
        label: yup.object().nullable().required("Required"),
      })
      // .required("Required")
      .when(["check", "patientCategory"], (check, patientCategory) => {
        if (patientCategory !== null) {
          if (typeof patientCategory.label !== "undefined") {
            if (
              (patientCategory.value === "Self" &&
                patientCategory.value === "International" &&
                patientCategory.value === "Ipf") ||
              check !== false
            ) {
              return yup
                .object()
                .nullable()
                .shape({
                  value: yup.string().notRequired(),
                  label: yup.string().notRequired(),
                })
                .notRequired();
            } else if (
              check === false &&
              patientCategory.value !== "Self" &&
              patientCategory.value !== "International" &&
              patientCategory.value !== "Ipf"
            ) {
              return yup
                .object()
                .nullable()
                .shape({
                  value: yup.string().required("Required"),
                  label: yup.string().required("Required"),
                })
                .required("Required");
            }
          }
        }
      }),

    companyAsst: yup
      .object()
      .nullable()
      .shape({
        value: yup.object().nullable().required("Required"),
        label: yup.object().nullable().required("Required"),
      })
      // .required("Required")
      .when(["check", "patientCategory"], (check, patientCategory) => {
        if (patientCategory !== null) {
          if (typeof patientCategory.label !== "undefined") {
            if (
              (patientCategory.value === "Self" &&
                patientCategory.value === "International" &&
                patientCategory.value === "Ipf") ||
              check !== false
            ) {
              return yup
                .object()
                .nullable()
                .shape({
                  value: yup.string().notRequired(),
                  label: yup.string().notRequired(),
                })
                .notRequired();
            } else if (
              check === false &&
              patientCategory.value !== "Self" &&
              patientCategory.value !== "International" &&
              patientCategory.value !== "Ipf"
            ) {
              return yup
                .object()
                .nullable()
                .shape({
                  value: yup.string().required("Required"),
                  label: yup.string().required("Required"),
                })
                .required("Required");
            }
          }
        }
      }),

    //////////////////// employee details / staff details /////////////////////////

    //staff name
    staffName: yup
      .object()
      .nullable()
      .shape({
        value: yup.string().required("Required"),
        label: yup.string().required("Required"),
      })
      .when(["check", "patientCategory"], {
        is: (check, patientCategory) => {
          if (patientCategory !== null) {
            if (
              patientCategory.label !== "Staff" &&
              patientCategory.label !== "Staff Dependant"
            ) {
              clearErrors("staffName");
            }

            return (
              check === false &&
              (patientCategory.label === "Staff" ||
                patientCategory.label === "Staff Dependant")
            );
          }
        },
        then: yup.object().nullable().required("Required"),
      }),

    //code
    code: yup.string().notRequired(),

    //limit
    limit: yup.string().notRequired(),

    //used
    used: yup.string().notRequired(),
  });

  useEffect(() => {
    if (location.state !== null) {
      setsearchPatientId(location?.state?.row?.PatientId);
      setVisitId(location?.state?.row?.VisitId);
      setIsForEdit(location?.state?.isEdit);
    }
  }, []);

  // clear location which contains navigation data,after refresh
  //////

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      check: false,
      searchPatient: "",
      selectEmergencyNo: null,
      fromAppointment: false,
      //Basic Info

      email: "",
      noEmail: false,
      //registrationDate: new Date(),
      prefix: {
        id: 2,
        label: "Mr",
        value: "Mr",
      },
      firstName: "",
      middleName: "",
      lastName: "",
      dobDate: new Date(),
      newbornBaby: false,
      age: "",
      ageInYears: 0,
      ageInMonths: 0,
      ageInDays: 0,
      gender: {
        id: 2,
        label: "Male",
        value: "Male",
      },
      mobileNumber: "",
      altMobileNumber: 0,
      nationality: {
        id: 1,
        label: "Indian",
        value: "Indian",
      },
      adharNumber: "",
      passportNumber: "",

      //Address Info
      houseFlatNumber: "",
      society: "",
      nearestLandmark: "",
      address: "",
      country: {
        id: 2,
        value: "India",
        label: "India",
      },
      state: {
        id: 2,
        label: "Maharashtra",
        value: "Maharashtra",
      },
      district: {
        id: 3,
        label: "Pune",
        value: "Pune",
      },
      city: { id: 2, label: "Pune", value: "Pune" },
      area: null,
      pinCode: "",

      //Referral Doctor

      referralDoctor: 0,

      ///visit Details
      //dateOfVisit: new Date(),
      source: 0,
      patientSource: {
        id: 2,
        label: "Hospital",
        value: "Hospital",
      },
      camp: 0,
      patientCategory: {
        id: 2,
        label: "Self",
        value: "Self",
      },
      department: 0,
      doctor: 0,
      cabin: 0,
      tariff: 0,
      //  {
      //   id: 2,
      //   label: "Hospital",
      //   value: "Hospital",
      // }
      company: 0,
      companyAsst: 0,

      // patient office details
      occupation: 0,
      officeName: "",
      patientOfficeAddress: "",

      // employee details / staff details
      staffName: "",
      code: "0",
      limit: "0",
      used: "0",
    },
  });

  const { clearErrors, watch, resetField } = methods;

  const handleChangePanal1 = () => {
    setExpandPanal1(!expandPanal1);
  };

  const handleChangePanal3 = () => {
    setExpandPanal3(!expandPanal3);
  };

  var isFromAppointment = watch("fromAppointment");
  var isRegisterOnly = watch("check");

  const handleReset = () => {
    setProfilePic(BlankProfile);
    methods.setValue("dobDate", new Date());
    // setDobState(new Date());
    methods.setValue("companyAsst", null);
    setPatientCategoryChange("Self");
    //
    setsearchPatientId(0);
    setSearchedPatientDetails(null);
    // setDobState(new Date());
    // setIsForEdit(false);
    //
    methods.reset();
    setVisitId(0);
    setPrivilege("");
    setCustomError(false);
  };

  const finalObject = {
    userId: "",
    unitId: "",
    menuId: "",
    emergencyId: 0,
    visitId: 0,
    //   //Basic Info
    email: null,
    registrationDate: null,

    //patientImageName: null,
    patientId: 0,
    appointmentId: 0,
    patientPhoto: null,
    imageBase64: null,
    prefix: null,
    firstName: null,
    middleName: null,
    lastName: null,
    dob: null,
    age: null,
    ageInYears: 0,
    ageInMonths: 0,
    ageInDays: 0,
    gender: 0,
    mobileNumber: null,
    altMobileNumber: null,
    nationality: 0,
    adharNumber: "",
    passportNo: "",

    //   //Address Info
    houseFlatNumber: "",
    society: "",
    landmark: "",
    country: null,
    state: null,
    district: null,
    city: null,
    area: null,
    pinCode: null,

    //   //Referral Doctor

    refDoctorId: 0,

    ///visit Details
    source: 0,
    dateOfVisit: 0,
    patientSource: 0,
    camp: 0,
    source: 0,
    patientCategory: 0,
    isDependent: false,
    tariff: 0,
    department: 0,
    doctor: 0,
    cabin: 0,
    company: 0,
    companyAsst: 0,
    VisitDate: null,

    // patient office details
    occupation: 0,
    officeName: null,
    patientOfficeAddress: null,

    // employee details / staff details
    empId: 0,
    dependentId: 0,

    empCode: 0,
    limit: 0,
    used: 0,
  };

  /////
  const onSubmit = (data) => {
    if (
      !data.newbornBaby &&
      data.ageInYears === 0 &&
      data.ageInMonths === 0 &&
      data.ageInDays === 0
    ) {
      warningAlert("Please Enter Valid Age !");
    } else if (
      data.country.label !== "India" &&
      (data.passportNumber === null || data.passportNumber === "")
    ) {
      warningAlert("Please Enter Passport Number...");
    } else if (customError) {
      warningAlert("Patient Name & Employee/Dependent Name Mismatch !");
    } else if (duplicateAdhar) {
      warningAlert("Aadhar Number is Already Exists.");
    } else if (duplicatePatient) {
      warningAlert("Patient Already Registered.");
    } else if (!customError && !duplicateAdhar) {
      setOpenModal(true);
      setFormData(data);
    }
  };

  const handleFormSubmit = (data) => {
    setOpenModal(false);

    //Assign Data to Final Object

    finalObject.visitId =
      visitId && Number(visitId) !== null ? Number(visitId) : 0;
    finalObject.userId = Number(token.userId);
    finalObject.unitId = Number(unitId);

    // finalObject.menuId =
    //   location?.state?.menuId && Number(location.state.menuId);
    finalObject.menuId = location?.state?.menuId;
    finalObject.privilege = privilege;

    finalObject.emergencyId =
      data.selectEmergencyNo !== undefined && data.selectEmergencyNo !== null
        ? data.selectEmergencyNo.id
        : searchedPatientDetails !== undefined &&
          searchedPatientDetails !== null &&
          searchedPatientDetails.EmergencyPatientId !== 0 &&
          searchedPatientDetails.EmergencyPatientId !== null
        ? searchedPatientDetails.EmergencyPatientId
        : 0;
    finalObject.patientId =
      searchedPatientDetails !== undefined &&
      searchedPatientDetails !== null &&
      searchedPatientDetails !== undefined &&
      searchedPatientDetails !== null
        ? Number(searchedPatientDetails.id)
        : Number(0);
    //
    finalObject.appointmentId =
      searchedPatientDetails !== undefined &&
      searchedPatientDetails !== null &&
      searchedPatientDetails.AppointmentID !== undefined &&
      searchedPatientDetails.AppointmentID !== null
        ? Number(searchedPatientDetails.AppointmentID)
        : Number(0);

    //

    //Basic Info

    finalObject.mobileNumber = data.mobileNumber;
    finalObject.altMobileNumber = data.altMobileNumber
      ? data.altMobileNumber
      : 0;
    finalObject.registrationDate = registrationDate;

    data.email !== null
      ? (finalObject.email = data.email)
      : (finalObject.email = null);

    finalObject.check = data.check;

    let profilePicString = profilePic.toString().split(",")[1];
    finalObject.patientPhoto = profilePicName;
    finalObject.imageBase64 = profilePicName !== null ? profilePicString : null;
    finalObject.prefix = data.prefix.id !== null ? Number(data.prefix.id) : 0;

    data.firstName !== null
      ? (finalObject.firstName =
          data.firstName.charAt(0).toUpperCase() + data.firstName.slice(1))
      : (finalObject.firstName = null);

    data.middleName !== null
      ? (finalObject.middleName =
          data.middleName.charAt(0).toUpperCase() + data.middleName.slice(1))
      : (finalObject.middleName = null);

    data.lastName !== null
      ? (finalObject.lastName =
          data.lastName.charAt(0).toUpperCase() + data.lastName.slice(1))
      : (finalObject.lastName = null);

    // finalObject.dob = dobState;
    finalObject.dob = format(data.dobDate, "yyyy-MM-dd'T'HH:mm:ss.000'Z'");
    finalObject.ageInYears = parseInt(data.ageInYears);

    data.ageInMonths !== 0 && data.ageInMonths !== undefined
      ? (finalObject.ageInMonths = parseInt(data.ageInMonths))
      : (finalObject.ageInMonths = parseInt(0));

    data.ageInDays !== 0 && data.ageInDays !== undefined
      ? (finalObject.ageInDays = parseInt(data.ageInDays))
      : (finalObject.ageInDays = parseInt(0));

    finalObject.age = parseInt(data.age);

    data.gender !== null
      ? (finalObject.gender = Number(data.gender.id))
      : (finalObject.gender = Number(0));

    data.nationality !== null
      ? (finalObject.nationality = Number(data.nationality.id))
      : (finalObject.nationality = Number(0));

    finalObject.adharNumber = Number(data.adharNumber);

    finalObject.passportNo = data.passportNumber;

    // //Address Info
    finalObject.houseFlatNumber = data.houseFlatNumber;
    finalObject.society = data.society;
    finalObject.landmark = data.nearestLandmark;
    finalObject.address = data.address;

    data.country !== null
      ? (finalObject.country = Number(data.country.id))
      : (finalObject.country = null);

    data.state !== null
      ? (finalObject.state = Number(data.state.id))
      : (finalObject.state = null);

    data.district !== null
      ? (finalObject.district = Number(data.district.id))
      : (finalObject.district = null);

    data.city !== null
      ? (finalObject.city = Number(data.city.id))
      : (finalObject.city = null);

    data.area !== null
      ? (finalObject.area = Number(data.area.id))
      : (finalObject.area = null);

    finalObject.pinCode = Number(data.pinCode);
    // //Referral Info

    data.referralDoctor !== null && data.referralDoctor.id
      ? (finalObject.refDoctorId = Number(data.referralDoctor.id))
      : (finalObject.refDoctorId = Number(0));

    finalObject.refDoctorName = data?.referralDoctor?.label || "";

    finalObject.dateOfVisit = visitDate;

    data.source !== null
      ? (finalObject.source = Number(data.source.id))
      : (finalObject.source = Number(0));

    data.patientSource !== null
      ? (finalObject.patientSource = Number(data.patientSource.id))
      : (finalObject.patientSource = Number(0));

    data.patientCategory !== null
      ? (finalObject.patientCategory = Number(data.patientCategory.id))
      : (finalObject.patientCategory = Number(0)); //patientCategoryChange

    data.camp !== null && data.camp !== 0
      ? (finalObject.camp = Number(data.camp.id))
      : (finalObject.camp = Number(0)); //patientCategoryChange

    finalObject.isDependent = data.patientCategory.id !== 5 ? false : true;

    data.department !== null
      ? (finalObject.department = Number(data.department.id))
      : (finalObject.department = Number(0));

    data.doctor !== null
      ? (finalObject.doctor = Number(data.doctor.id))
      : (finalObject.doctor = Number(0));

    data.cabin !== null
      ? (finalObject.cabin = data.cabin.value)
      : (finalObject.cabin = "");

    data.tariff !== 0 && data.tariff !== null
      ? (finalObject.tariff = Number(data.tariff.id))
      : (finalObject.tariff = Number(0));

    data.company !== null
      ? (finalObject.company = Number(data.company.id))
      : (finalObject.company = Number(0));

    data.companyAsst !== 0 && data.companyAsst !== null
      ? (finalObject.companyAsst = Number(data.companyAsst.id))
      : (finalObject.companyAsst = Number(0));

    data.staffName !== null
      ? (finalObject.empId = Number(data.staffName.id))
      : (finalObject.empId = Number(0));

    data.staffDependent !== null && data.staffDependent !== undefined
      ? (finalObject.dependentId = Number(data.staffDependent.id))
      : (finalObject.dependentId = Number(0));

    finalObject.empCode = data.empCode;
    finalObject.limit = Number(data.limit);
    finalObject.used = Number(data.used);

    data.occupation !== null && data.occupation !== 0
      ? (finalObject.occupation = Number(data.occupation.id))
      : (finalObject.occupation = Number(0));

    finalObject.officeName = data.officeName;
    finalObject.patientOfficeAddress = data.patientOfficeAddress;

    setOpenBackdrop(true);

    ///////////////
    PostMyPatientRegistrationInfo(finalObject)
      .then((response) => response.data)
      .then((res) => {
        if (res.statusCode === 200) {
          setIdentificationDocFile("");
          setProfilePic(BlankProfile);
          handleReset();
          successAlert(res.message);
          methods.reset();
          setPatientCategoryChange("Self");
          setOpenModal(false);
          setOpenBackdrop(false);
          resetField("searchPatient"); //clear search
          setIsForEdit(false);
          setVisitId(0);
          isRegisterOnly !== true &&
            !isForEdit &&
            handleOpenPrintModal(res.result);
        }
      })
      .catch((res) => {
        errorAlert(res.response.data.message || res.message);
        setOpenModal(false);
        setOpenBackdrop(false);

        let validationsDB = res?.response?.data?.fieldErrors;
        errorAlert(res.message || res.response.data.message);
        const handleValidation = () => {
          return (
            <div>
              {validationsDB.map((item, index) => (
                <div key={index}>{`${index + 1}.${item.field}`}</div>
              ))}
            </div>
          );
        };
        validationsDB.length > 0 && validationAlert(handleValidation());
      });
  };

  useEffect(() => {
    searchPatientId && getSearchedData();
  }, [
    // searchPatientId
    searchPatientId,
  ]);

  const getSearchedData = () => {
    fetchSearchedregistrationData(
      searchPatientId,
      visitId === undefined || visitId === 0 ? (isFromAppointment ? 1 : 0) : 0
    )
      .then((response) => response.data)
      .then((res) => {
        setSearchedPatientDetails(res.result);
      })
      .catch((response) => {});
  };

  useEffect(() => {
    if (searchedPatientDetails !== null) {
      searchedPatientDetails?.imgPath !== null &&
        setProfilePic(`${baseUrl}/file/${searchedPatientDetails?.imgPath}`);
      //
      if (searchedPatientDetails?.IsBlackListed === 1) {
        warningAlert("Patient is blacklisted kindly contact billing.");
        setOpenBlacklistCarryModal(true);
      }
      //
      setDuplicatePatient(false);
      ///populate data in fields
      searchedPatientDetails.PatientCategory !== null
        ? setPatientCategoryChange(searchedPatientDetails.PatientCategory)
        : setPatientCategoryChange("Self");

      // setDobState(searchedPatientDetails.dob);

      methods.setValue("dobDate", searchedPatientDetails?.dob, {
        shouldValidate: true,
      });

      methods.setValue("email", searchedPatientDetails.email, {
        shouldValidate: true,
      });
      methods.setValue(
        "prefix",
        {
          id: searchedPatientDetails.prefixId,
          label: searchedPatientDetails.salutation,
          value: searchedPatientDetails.salutation,
        },
        { shouldValidate: true }
      );
      methods.setValue("firstName", searchedPatientDetails.firstName, {
        shouldValidate: true,
      });
      methods.setValue("middleName", searchedPatientDetails.middleName, {
        shouldValidate: true,
      });
      methods.setValue("lastName", searchedPatientDetails.lastName, {
        shouldValidate: true,
      });
      methods.setValue("dobDate", searchedPatientDetails.dob, {
        shouldValidate: true,
      });
      methods.setValue("age", searchedPatientDetails.Age, {
        shouldValidate: true,
      });
      methods.setValue("ageInYears", searchedPatientDetails.ageInYears, {
        shouldValidate: true,
      });
      methods.setValue("ageInMonths", searchedPatientDetails.ageInMonths, {
        shouldValidate: true,
      });
      methods.setValue("ageInDays", searchedPatientDetails.ageInDays, {
        shouldValidate: true,
      });
      methods.setValue(
        "gender",
        {
          id: searchedPatientDetails.genderId,
          label: searchedPatientDetails.gender,
          value: searchedPatientDetails.gender,
        },
        { shouldValidate: true }
      );
      methods.setValue("mobileNumber", searchedPatientDetails.mobileNo, {
        shouldValidate: true,
      });
      methods.setValue(
        "altMobileNumber",
        searchedPatientDetails.altMobileNumber,
        {
          shouldValidate: true,
        }
      );
      methods.setValue("adharNumber", searchedPatientDetails.adharNo, {
        shouldValidate: true,
      });
      methods.setValue("passportNumber", searchedPatientDetails.passportNo, {
        shouldValidate: true,
      });

      if (
        searchedPatientDetails.CountryId !== undefined &&
        searchedPatientDetails.country !== null
      ) {
        methods.setValue(
          "country",
          {
            id: searchedPatientDetails.CountryId,
            label: searchedPatientDetails.country,
            value: searchedPatientDetails.country,
          },
          { shouldValidate: true }
        );
      }

      if (
        searchedPatientDetails.StateId !== undefined &&
        searchedPatientDetails.State !== null
      ) {
        methods.setValue(
          "state",
          {
            id: searchedPatientDetails.StateId,
            label: searchedPatientDetails.State,
            value: searchedPatientDetails.State,
          },
          { shouldValidate: true }
        );
      }

      methods.setValue("houseFlatNumber", searchedPatientDetails.FlatHouseNo, {
        shouldValidate: true,
      });
      methods.setValue("nearestLandmark", searchedPatientDetails.Landmark, {
        shouldValidate: true,
      });
      methods.setValue("address", searchedPatientDetails.Address || "", {
        shouldValidate: true,
      });
      methods.setValue("society", searchedPatientDetails.Society, {
        shouldValidate: true,
      });

      if (
        searchedPatientDetails.cityId !== undefined &&
        searchedPatientDetails.cityId !== null &&
        searchedPatientDetails.cityId !== 0 &&
        searchedPatientDetails.city !== null
      ) {
        methods.setValue(
          "city",
          {
            id: searchedPatientDetails.cityId,
            label: searchedPatientDetails.city,
            value: searchedPatientDetails.city,
          },
          { shouldValidate: true }
        );
      }

      if (
        searchedPatientDetails.areaId !== undefined &&
        searchedPatientDetails.area !== null &&
        searchedPatientDetails.areaId > 1
      ) {
        methods.setValue(
          "area",
          {
            id: searchedPatientDetails.areaId,
            label: searchedPatientDetails.area,
            value: searchedPatientDetails.area,
          },
          { shouldValidate: true }
        );
      }

      methods.setValue("pinCode", searchedPatientDetails.pincode, {
        shouldValidate: true,
      });

      if (
        searchedPatientDetails.DepartmentId !== undefined &&
        searchedPatientDetails.DepartmentName !== null
      ) {
        methods.setValue(
          "department",
          {
            id: searchedPatientDetails.DepartmentId,
            label: searchedPatientDetails.DepartmentName,
            value: searchedPatientDetails.DepartmentName,
          },
          { shouldValidate: true }
        );
      }

      if (
        searchedPatientDetails.DoctorId !== undefined &&
        searchedPatientDetails.DoctorName !== null
      ) {
        methods.setValue(
          "doctor",
          {
            id: searchedPatientDetails.DoctorId,
            label: searchedPatientDetails.DoctorName,
            value: searchedPatientDetails.DoctorName,
          },
          { shouldValidate: true }
        );
      }

      if (
        searchedPatientDetails.SourceId !== undefined &&
        searchedPatientDetails.source !== null
      ) {
        methods.setValue(
          "source",
          {
            id: searchedPatientDetails.SourceId,
            label: searchedPatientDetails.source,
            value: searchedPatientDetails.source,
          },
          { shouldValidate: true }
        );
      }

      if (
        searchedPatientDetails.PatientSourceId !== undefined &&
        searchedPatientDetails.PatientSource !== null
      ) {
        methods.setValue(
          "patientSource",
          {
            id: searchedPatientDetails.PatientSourceId,
            label: searchedPatientDetails.PatientSource,
            value: searchedPatientDetails.PatientSource,
          },
          { shouldValidate: true }
        );
      }

      if (
        //searchedPatientDetails.RefDoctorId &&
        searchedPatientDetails.RefDoctorId !== undefined &&
        searchedPatientDetails.RefDoctorName &&
        searchedPatientDetails.RefDoctorName !== null
      ) {
        methods.setValue(
          "referralDoctor",
          {
            id: searchedPatientDetails.RefDoctorId,
            label: searchedPatientDetails.RefDoctorName,
            value: searchedPatientDetails.RefDoctorName,
          },
          { shouldValidate: true }
        );
      }
      if (
        searchedPatientDetails.campId !== undefined &&
        searchedPatientDetails.camp !== null
      ) {
        methods.setValue(
          "camp",
          {
            id: searchedPatientDetails.campId,
            label: searchedPatientDetails.camp,
            value: searchedPatientDetails.camp,
          },
          { shouldValidate: true }
        );
      }
      if (
        searchedPatientDetails.PatientCatagoryId !== undefined &&
        searchedPatientDetails.PatientCategory !== null
      ) {
        methods.setValue(
          "patientCategory",
          {
            id: searchedPatientDetails.PatientCatagoryId,
            label: searchedPatientDetails.PatientCategory,
            value: searchedPatientDetails.PatientCategory,
          },
          { shouldValidate: true }
        );
      }

      if (
        searchedPatientDetails.CompanyId !== undefined &&
        searchedPatientDetails.PatientCatagoryId !== 2 &&
        searchedPatientDetails.company !== null &&
        searchedPatientDetails.company !== undefined &&
        searchedPatientDetails.PatientCategory !== "Self" &&
        searchedPatientDetails.PatientCategory !== "International" &&
        searchedPatientDetails.PatientCategory !== "Ipf"
      ) {
        methods.setValue(
          "company",
          {
            id: searchedPatientDetails.CompanyId,
            label: searchedPatientDetails.company,
            value: searchedPatientDetails.company,
          },
          { shouldValidate: true }
        );
      }

      if (
        searchedPatientDetails.CompanyId_Ass !== undefined &&
        searchedPatientDetails.companyAss !== null
      ) {
        methods.setValue(
          "companyAsst",
          {
            id: searchedPatientDetails.CompanyId_Ass,
            label: searchedPatientDetails.companyAss,
            value: searchedPatientDetails.companyAss,
          },
          { shouldValidate: true }
        );
      }
      //staff details
      if (
        searchedPatientDetails.EmpId !== undefined &&
        searchedPatientDetails.EmpName !== null
      ) {
        methods.setValue("staffName", {
          id: searchedPatientDetails.EmpId,
          label: searchedPatientDetails.EmpName,
          value: searchedPatientDetails.EmpName,
        });
      }

      if (
        searchedPatientDetails.DependentID !== undefined &&
        searchedPatientDetails.DependentName !== null
      ) {
        methods.setValue("staffDependent", {
          id: searchedPatientDetails.DependentID,
          label: searchedPatientDetails.DependentName,
          value: searchedPatientDetails.DependentName,
        });
      }

      if (searchedPatientDetails.Code !== null) {
        methods.setValue("code", searchedPatientDetails.Code);
      }
      if (searchedPatientDetails.PharmacyAmount !== null) {
        methods.setValue("limit", searchedPatientDetails.PharmacyAmount);
      }
      if (searchedPatientDetails.PharmacyHMSAmt !== null) {
        methods.setValue("used", searchedPatientDetails.PharmacyHMSAmt);
      }

      // cabin

      if (
        searchedPatientDetails.cabin !== undefined &&
        searchedPatientDetails.cabinId !== null
      ) {
        methods.setValue(
          "cabin",
          {
            id: searchedPatientDetails.cabinId,
            label: searchedPatientDetails.cabin,
            value: searchedPatientDetails.cabin,
          },
          { shouldValidate: true }
        );
      }
      //
      if (
        searchedPatientDetails.OccupationId !== undefined &&
        searchedPatientDetails.Occupation !== null
      ) {
        methods.setValue(
          "occupation",
          {
            id: searchedPatientDetails.OccupationId,
            label: searchedPatientDetails.Occupation,
            value: searchedPatientDetails.Occupation,
          },
          { shouldValidate: true }
        );
      }

      methods.setValue("officeName", searchedPatientDetails.officeName, {
        shouldValidate: true,
      });

      methods.setValue(
        "patientOfficeAddress",
        searchedPatientDetails.officeAddress,
        {
          shouldValidate: true,
        }
      );
    }
  }, [searchedPatientDetails]);

  const handleBlackList = () => {
    setOpenBlacklistModal(true);
  };

  useEffect(() => {
    location?.state?.menuId &&
      fetchUserActionsByMenuId(location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });
  }, []);

  ////
  const opdBillItem = userActions.find((item) => item.action === "OPD Bill");
  const opdBillMenuId = opdBillItem ? opdBillItem.menuId : null;

  return (
    <div className="bg-gray-50 py-1 mt-12">
      <div className="grid grid-cols-12 gap-2 mx-2 items-center mt-5">
        <p className="text-end mt-4 text-black font-bold text-xl font-Poppins col-span-7">
          Patient Registration
        </p>

        <div className=" justify-end flex col-span-5">
          {opdBillItem && !opdBillItem?.isAction && (
            <CommonButton
              label="OPD Bill"
              className="bg-customBlue text-white"
              onClick={() => {
                handleOpenOpdBillModal();
              }}
            />
          )}
        </div>
      </div>
      <div className="w-12/12 mx-2 my-1">
        <FormProvider {...methods}>
          <fieldset>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              {/* /// Patient Details //// */}
              <Accordion
                expanded={expandPanal1}
                onChange={handleChangePanal1}
                elevation={6}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  sx={{
                    "&.Mui-expanded": {
                      marginBottom: "-1rem",
                    },
                    "& .MuiAccordionSummary-content.Mui-expanded": {
                      margin: 0,
                    },
                  }}
                >
                  <p className="font-bold tracking-wide text-sm font-Poppins">
                    Patient Basic Information
                  </p>
                </AccordionSummary>
                <AccordionDetails>
                  {/* //Basic Information // */}

                  <BasicInfo
                    // dobState={dobState}
                    // setDobState={setDobState}
                    registrationDate={registrationDate}
                    setRegistrationDate={setRegistrationDate}
                    bloodgroups={bloodgroups}
                    setBloodgroups={setBloodgroups}
                    maritalStatus={maritalStatus}
                    setmaritalStatus={setmaritalStatus}
                    selectedDoc={selectedDoc}
                    setSelectedDoc={setSelectedDoc}
                    age={age}
                    setAge={setAge}
                    profilePic={profilePic}
                    setProfilePic={setProfilePic}
                    setProfilePicName={setProfilePicName}
                    gender={gender}
                    setGender={setGender}
                    identificationDocFile={identificationDocFile}
                    setIdentificationDocFile={setIdentificationDocFile}
                    isVisit={true}
                    setRegisterOnly={setRegisterOnly}
                    searchedPatientDetails={searchedPatientDetails}
                    setsearchPatientId={setsearchPatientId}
                    //
                    setSearchedPatientDetails={setSearchedPatientDetails}
                    //
                    setDuplicateAdhar={setDuplicateAdhar}
                    setDuplicatePatient={setDuplicatePatient}
                    //
                    setPatientCategoryChange={setPatientCategoryChange}
                    isForEdit={isForEdit}
                    setPrivilege={setPrivilege}
                    //
                    handleReset={handleReset}
                  />
                  <hr className="border my-2 divide-x-8 bg-slate-300 border-slate-300" />
                  {/* // Address Information // */}
                  <p className="font-bold tracking-wide text-sm my-3 font-Poppins">
                    Address Details
                  </p>
                  <AddressInfo />
                </AccordionDetails>
              </Accordion>

              {/* /// Visit Details //// */}

              <Accordion
                expanded={expandPanal3}
                onChange={handleChangePanal3}
                elevation={6}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel5bh-content"
                  id="panel5bh-header"
                  sx={{
                    "&.Mui-expanded": {
                      marginBottom: "-1rem",
                    },
                    "& .MuiAccordionSummary-content.Mui-expanded": {
                      margin: 0,
                    },
                  }}
                >
                  {registerOnly !== true ? (
                    <p className="font-bold tracking-wide text-sm font-Poppins">
                      Visit Details
                    </p>
                  ) : (
                    <p className="font-bold tracking-wide text-sm font-Poppins">
                      Patient Office Details
                    </p>
                  )}
                </AccordionSummary>
                <AccordionDetails>
                  <VisitDetails
                    setPatientCategoryChange={setPatientCategoryChange}
                    patientCategoryChange={patientCategoryChange}
                    registerOnly={registerOnly}
                    visitDate={visitDate}
                    setVisitDate={setVisitDate}
                    searchedPatientDetails={searchedPatientDetails}
                    setIsAlreadyVisited={setIsAlreadyVisited}
                    setCustomError={setCustomError}
                    //
                    isForEdit={isForEdit}
                  />
                </AccordionDetails>
              </Accordion>

              <div className="flex justify-end my-4 gap-2">
                {userActions &&
                  userActions?.map((obj) => (
                    <>
                      {!obj.isAction &&
                      obj.action === "Blacklist" &&
                      searchedPatientDetails !== null &&
                      Number(searchedPatientDetails?.IsBlackListed) === 0 ? (
                        <div>
                          <CommonButton
                            label={"Blacklist"}
                            className="border border-customGray text-customGray"
                            onClick={() => {
                              handleBlackList();
                              setPrivilege(obj.action);
                            }}
                          />
                        </div>
                      ) : null}

                      {!obj.isAction &&
                      obj.action === "Whitelist" &&
                      searchedPatientDetails !== null &&
                      Number(searchedPatientDetails?.IsBlackListed) === 1 ? (
                        <div>
                          <CommonButton
                            label={"Whitelist"}
                            className="border border-customGray text-customGray"
                            onClick={() => {
                              handleBlackList();
                              setPrivilege(obj.action);
                            }}
                          />
                        </div>
                      ) : null}

                      {!obj.isAction && obj.action === "Create" ? (
                        <>
                          {!isForEdit && (
                            <div className="">
                              <CommonButton
                                onClick={() => handleReset()}
                                className="border border-customRed text-customRed"
                                label="Reset"
                              />
                            </div>
                          )}

                          {/* ) : null} */}

                          <div
                            onClick={() => {
                              setPrivilege(obj.action);
                            }}
                          >
                            <CommonButton
                              type="submit"
                              className="bg-green-600 text-white"
                              label="Save"
                            />
                          </div>
                        </>
                      ) : null}
                    </>
                  ))}

                {/* {patientId !== null ? <UpdateButton /> : */}
              </div>
            </form>
          </fieldset>
        </FormProvider>
      </div>

      {openPrintModal ? (
        <CommonPrintModal
          open={openPrintModal}
          setOpen={setOpenPrintModal}
          handleOpen={handleOpenPrintModal}
          handleClose={handleClosePrintModal}
          urlforPrint={urlforPrint}
        />
      ) : null}
      {/* backdrop */}
      <CommonBackDrop openBackdrop={openBackdrop} />

      {/* //Confirmation Modal// */}
      <ConfirmationModal
        confirmationOpen={openModal}
        confirmationHandleClose={() => setOpenModal(false)}
        confirmationSubmitFunc={() => handleFormSubmit(formData)}
        confirmationLabel="Confirmation "
        confirmationMsg={
          isForEdit
            ? "Do You Want To Update !"
            : isAlreadyVisited !== 1
            ? "Are You Sure ?"
            : "Visit Already Marked! Do You Want To Continue?"
        }
        confirmationButtonMsg="Proceed"
      />

      <ConfirmationModal
        confirmationOpen={openBlacklistCarryModal}
        confirmationHandleClose={() => {
          setOpenBlacklistCarryModal(false);
          handleReset();
        }}
        confirmationSubmitFunc={() => {
          setOpenBlacklistCarryModal(false);
        }}
        confirmationLabel="Confirmation "
        confirmationMsg={"Patient Blacklisted ! Do You Want To Continue?"}
        confirmationButtonMsg="Proceed"
      />

      <Modal open={openBlacklistModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { sm: "40%", lg: "26%" },
            bgcolor: "background.paper",
            borderRadius: 2,
            border: "none",
            "&:focus": {
              outline: "none",
            },
          }}
        >
          <div className="mx-3 mb-3 mt-2 items-center align-middle">
            <div className=" p-2 rounded  text-black font-semibold whitespace-nowrap">
              Patient Registration
            </div>

            <hr className="border mx-1  border-blue-900" />
            <div className=" p-2 rounded  text-black">
              {searchedPatientDetails?.IsBlackListed === 1
                ? "Do You Want To Remove Patient From BlackList ?"
                : "Do You Want To Add Patient In BlackList ?"}
            </div>
            <div className="flex justify-end my-2 gap-3">
              <div>
                <CommonButton
                  label="Cancel"
                  className="border border-customRed text-customRed"
                  onClick={() => {
                    setOpenBlacklistModal(false);
                  }}
                />
              </div>

              <div>
                <div>
                  <CommonButton
                    type="submit"
                    className=" bg-customGreen text-white  "
                    label="Ok"
                    onClick={() => {
                      let obj = {
                        status:
                          searchedPatientDetails?.IsBlackListed === 1
                            ? false
                            : true,
                        patientId: searchedPatientDetails.id,
                        menuId: location?.state?.menuId,
                        privilege: privilege,
                      };
                      fetchBlacklistAproval(obj).then((response) => {
                        successAlert(response.data.message);
                        handleReset();
                      });

                      setOpenBlacklistModal(false);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>

      {/*View Patient Info*/}
      {openOpdBillModal ? (
        <OpdBillModal
          open={openOpdBillModal}
          setOpen={setOpenOpdBillModal}
          handleOpen={handleOpenOpdBillModal}
          handleClose={handleCloseOpdBillModal}
          menuId={opdBillMenuId}
        />
      ) : null}
    </div>
  );
};
export default PatientRegistrationForm;
