import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import * as yup from "yup";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../common/components/Toasts/CustomToasts";
import { fetchUserActionsByMenuId } from "../../../commonServices/functionalityServices/FunctionalityServices";
import { getPatientInfoOpdIpdAutocomplete } from "../../../commonServices/patientInfoServices/PatientInfoServices";
import {
  fetchCodeBlueFormDetails,
  saveCodeBlueForm,
} from "../../services/codeBlueFormServices/CodeBlueFormServices";
import CBForm1 from "./CBForm1";
import CBForm2 from "./CBForm2";
import CBFormListing from "./cbFormListing/CBFormListing";
import { format, isValid } from "date-fns";

const schema = yup.object().shape({
  victimName: yup.string().required("Required"),
  shortSummeryOfCase: yup.string().required("Required"),
});
////

const CodeBlueForm = () => {
  let token = JSON.parse(localStorage.getItem("userInfo"));
  let userId = token.userId;
  //
  let location = useLocation();

  //
  const [userActions, setUserActions] = useState([]);
  //

  const [options, setOptions] = useState([]);
  const [finalData, setFinalData] = useState("");
  const [selectedPatient, setSelectedPatient] = useState(null);
  // const [patchData, setPatchData] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  //listing modal
  const [openCBModal, setOpenCBModal] = React.useState(false);
  const handleOpenCBModal = () => setOpenCBModal(true);
  const handleCloseCBModal = () => setOpenCBModal(false);
  //
  //
  const [privilege, setPrivilege] = React.useState("");

  //

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      patientSearch: "",
      cdfDate: new Date(),
      cdfTime: new Date(),
      codeAnnouncTime: new Date(),
      nameOfTheCaller: "",
      cBFormNo: "",
      callerLocation: "",
      nameOfOperator: "",
      victimName: "",
      teamArrivalTime: new Date(),
      dignosis: "",
      medicalEmergency: false,
      adult: false,
      pediatric: false,
      isCprInitiated: 1,
      ifYesInitiatedBy: "",
      ifNoExplain: "",
      wasBasicLifeSupport: false,
      wasAdvancedLifeSupport: false,
      codeBlueProceduresFollows: false,
      shortSummeryOfCase: "",
      codeTeamMembers: "",
      difficulties: "",
      comments: "",
      resuscitationSuccessfull: false,
      notRevived: false,
      shiftedToIcu: false,
      stabilizedInWard: false,
      treatedOnOpd: false,
      cprStartTime: null,
      cprEndTime: null,
      cycleOfCprGiven: "",
      isRocsAchieved: 1,
    },
  });

  const { control, register, setValue } = methods;

  //handleChange
  const handleChangeSearch = (searchString) => {
    if (searchString !== undefined && searchString !== "") {
      getPatientInfoOpdIpdAutocomplete(searchString, 1)
        .then((response) => response.data)
        .then((res) => {
          setOptions(res.result);
        });
    }
  };

  const finalObj = {
    menuId: "",
    privilege: "",
    addedBy: 0,
    admissionID: 0,
    adult: 0,
    advancedLifeSupport: 0,
    basicLifeSupport: 0,
    callerLocation: "",
    codeBlueProceduresFollowed: 0,
    codeTeamMembers: "",
    cprinitiated: 0,
    createdDate: new Date(),
    diagnosis: "",
    difficulties: "",
    explaination: "",
    initiatedBy: "",
    medicalEmergency: 0,
    nameOfOperator: "",
    nameOfTheCaller: "",
    notRevived: 0,
    overallComments: "",
    pediatric: 0,
    resuscitationSuccessful: 0,
    shiftedToICU: 0,
    shortSummary: "",
    stabilizedInWARD: 0,
    treatedOnOpd: 0,
    timeOfArrival: new Date(),
    victimName: "",
  };

  // saveCodeBlueForm
  const onSubmit = (data) => {
    // if (selectedPatient !== null) {
    setFinalData(data);
    setOpenConfirmationModal(true);
    // } else {
    //   warningAlert("Please Select Patient!");
    // }
    console.log("Code Blue Form Data", data);
  };

  const handleFormSubmit = (data) => {
    console.log("Code Blue Form Data", data);

    finalObj.codeBlueFormId = !!selectedRow ? selectedRow.ID : 0;
    finalObj.menuId = location.state.menuId;
    finalObj.privilege = privilege;
    finalObj.addedBy = userId;
    finalObj.admissionID = !!selectedPatient ? selectedPatient?.AdmissionId : 0;
    finalObj.adult = data.adult ? 1 : 0; ///checkbox
    finalObj.advancedLifeSupport = data.wasAdvancedLifeSupport ? 1 : 0;
    finalObj.basicLifeSupport = data.wasBasicLifeSupport ? 1 : 0;
    finalObj.callerLocation = data.callerLocation;
    finalObj.codeBlueProceduresFollowed = data.codeBlueProceduresFollows
      ? 1
      : 0; //checkbox
    finalObj.codeTeamMembers = data.codeTeamMembers;
    finalObj.cprinitiated = data.isCprInitiated; //radio
    finalObj.date = format(data.cdfDate, "yyyy-MM-dd'T'HH:mm:ss.000'Z'");

    let IsValidTime = isValid(data.cdfTime);

    finalObj.time = IsValidTime
      ? format(data.cdfTime, "yyyy-MM-dd'T'HH:mm:ss.000'Z'")
      : data?.cdfTime?.concat("Z");

    let isCodeAnnouncTime = isValid(data.codeAnnouncTime);

    finalObj.codeAnnouncedTime = isCodeAnnouncTime
      ? format(data.codeAnnouncTime, "yyyy-MM-dd'T'HH:mm:ss.000'Z'")
      : data?.codeAnnouncTime?.concat("Z");

    finalObj.diagnosis = data.dignosis;
    finalObj.difficulties = data.difficulties;
    finalObj.explaination = data.ifNoExplain;
    finalObj.initiatedBy = data.ifYesInitiatedBy;
    finalObj.medicalEmergency = data.medicalEmergency ? 1 : 0; //check
    finalObj.nameOfOperator = data.nameOfOperator;
    finalObj.nameOfTheCaller = data.nameOfTheCaller;
    finalObj.notRevived = data.notRevived ? 1 : 0; //check
    finalObj.overallComments = data.comments;
    finalObj.pediatric = data.pediatric ? 1 : 0; //check
    finalObj.resuscitationSuccessful = data.resuscitationSuccessfull ? 1 : 0; //check
    finalObj.shiftedToICU = data.shiftedToIcu ? 1 : 0; //check
    finalObj.shortSummary = data.shortSummeryOfCase;
    finalObj.stabilizedInWARD = data.stabilizedInWard ? 1 : 0;
    finalObj.treatedOnOpd = data.treatedOnOpd ? 1 : 0;
    // finalObj.timeOfArrival = data.teamArrivalTime;

    finalObj.cprStartTime = data.cprStartTime
      ? format(data.cprStartTime, "yyyy-MM-dd'T'HH:mm:ss.000'Z'")
      : null;
    finalObj.cprEndTime = data?.cprEndTime
      ? format(data.cprStartTime, "yyyy-MM-dd'T'HH:mm:ss.000'Z'")
      : null;
    finalObj.cprCycles = data.cycleOfCprGiven ? data.cycleOfCprGiven : null;

    let isRocs;
    if (data.isRocsAchieved === 1) {
      isRocs = true;
    } else if (data.isRocsAchieved === 0) {
      isRocs = false;
    }
    finalObj.rosc = isRocs;

    let IsValidArrivalTime = isValid(data.teamArrivalTime);

    finalObj.timeOfArrival = IsValidArrivalTime
      ? format(data.teamArrivalTime, "yyyy-MM-dd'T'HH:mm:ss.000'Z'")
      : data?.teamArrivalTime?.concat("Z");

    finalObj.victimName = data.victimName;

    saveCodeBlueForm(finalObj)
      .then((response) => response.data)
      .then((res) => {
        // setValue("dischrgeSummarySearch",null)
        if (res.statusCode === 302) {
          warningAlert(res.message);
        } else if (res.statusCode === 200) {
          successAlert(res.message);
          setOpenBackdrop(false);
          setOpenConfirmationModal(false);
          methods.reset();
          setSelectedPatient(null);
          setSelectedRow(null);
        }
      })
      .catch((res) => {
        errorAlert(res.message);
        setOpenConfirmationModal(false);
        setOpenBackdrop(false);
      });
  };

  useEffect(() => {
    !!selectedRow?.ID &&
      fetchCodeBlueFormDetails(selectedRow?.ID)
        .then((response) => response.data)
        .then((res) => {
          //setPatchData(res.result);
          patchCodeBlueFormData(res.result);
        });
  }, [selectedRow]);

  const patchCodeBlueFormData = (data) => {
    setSelectedPatient({
      patientName: data.PatientName,
      MRNo: data.MRNo,
      AdmissionId: data.AdmissionID,
    });
    setValue("adult", data.Adult);
    setValue("wasAdvancedLifeSupport", data.AdvancedLifeSupport);
    setValue("nameOfTheCaller", data);
    setValue("cBFormNo", data.CodeBlueNo);
    setValue("wasBasicLifeSupport", data.BasicLifeSupport); //cprr ini
    setValue("callerLocation", data.CallerLocation);
    setValue("codeBlueProceduresFollows", data.CodeBlueProceduresFollowed);
    setValue("codeTeamMembers", data.CodeTeamMembers);
    setValue("isCprInitiated", data.CPRInitiated);
    setValue("cdfDate", new Date(data.Date));
    setValue("cdfTime", `1900-01-01T${data.Time}.000`);
    setValue("dignosis", data.Diagnosis);
    setValue("difficulties", data.Difficulties);
    setValue("ifNoExplain", data.Explaination);
    setValue("ifYesInitiatedBy", data.InitiatedBy);
    setValue("medicalEmergency", data.MedicalEmergency);
    setValue("nameOfOperator", data.NameOfOperator);
    setValue("nameOfTheCaller", data.NameOfTheCaller);
    setValue("notRevived", data.NotRevived);
    setValue("comments", data.OverallComments);
    setValue("pediatric", data.Pediatric);
    setValue("resuscitationSuccessfull", data.ResuscitationSuccessful);
    setValue("shiftedToIcu", data.ShiftedToICU);
    setValue("shortSummeryOfCase", data.ShortSummary);
    setValue("stabilizedInWard", data.StabilizedInWARD);
    setValue("treatedOnOpd", data.treatedOnOpd);
    setValue("teamArrivalTime", `1900-01-01T${data.TimeOfArrival}.000`);
    setValue("victimName", data.VictimName); ///

    setValue("cprStartTime", data?.CprStartTime);
    setValue("cprEndTime", data?.CprEndTime);
    setValue("cycleOfCprGiven", data?.CprCycle);
    setValue("isRocsAchieved", data.rosc === true ? 1 : 0);
  };

  ///get User Action
  useEffect(() => {
    //
    location?.state?.menuId &&
      fetchUserActionsByMenuId(location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });
  }, []);
  return (
    <>
      <div className="mt-12">
        {/* //heading// */}
        <div className="text-center text-black font-bold text-xl ">
          Code Blue Form
        </div>
        {/* search */}

        {/* <div className="md:col-span-2 lg:col-span-1">
            <SearchDropdown
              control={control}
              placeholder="Search By Patient Name / MR.No./Mob No."
              dataArray={options}
              name="patientSearch"
              searchIcon={true}
              isClearable={true}
              handleInputChange={handleChangeSearch}
              isDisabled={selectedRow !== null ? true : false}
              inputRef={{
                ...register("patientSearch", {
                  onChange: (e) => {
                    
                    if (e.target.value !== null) {
                      setSelectedPatient(e.target.value);
                    } else {
                      setSelectedPatient("");
                      setSelectedRow(null);
                      methods.reset();
                    }
                  },
                }),
              }}
            />
            </div> */}
        <div className="flex justify-end">
          {userActions.map((obj) => (
            <>
              {!obj.isAction && obj.action === "View" ? (
                <>
                  <CommonButton
                    label=" View List"
                    onClick={() => handleOpenCBModal()}
                    className="bg-customBlue text-white"
                  />
                </>
              ) : null}
            </>
          ))}
        </div>

        {/* patientInfo */}
        {/* <fieldset
          className="border border-gray-300 col-span-3 w-full
               text-left lg:px-2 md:px-5 rounded bg-gray-100 p-2  "
        >
          <div className="py-2 grid  grid-cols-2 2xl:grid-cols-4 gap-2 p-3 ">
            <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
              <span className=" font-semibold w-28">Patient Name</span>
              <div className="flex space-x-2 items-center">
                <span>:</span>
                <span className="text-gray-700 font-normal">
                  {selectedPatient && selectedPatient.patientName}
                </span>
              </div>
            </div>

            <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
              <span className=" font-semibold w-28">MR. No.</span>
              <div className="flex space-x-2 items-center">
                <span>:</span>
                <span className="text-gray-700 font-normal">
                  {selectedPatient && selectedPatient.MRNo}
                </span>
              </div>
            </div>
          </div>
        </fieldset>
        */}
        <FormProvider {...methods}>
          <form
          //  onSubmit={methods.handleSubmit(onSubmit)}
          >
            <div className="mt-3">
              <CBForm1 />
            </div>
            <hr className="border my-2 col-span-5 divide-x-8 bg-slate-300 border-slate-300" />
            <div className="mt-3">
              <CBForm2 />
            </div>
            <div className="flex gap-3 justify-end pb-2 ">
              {userActions.map((obj) => (
                <>
                  {!obj.isAction && obj.action === "Create" ? (
                    <>
                      <div>
                        <CommonButton
                          label=" Reset"
                          onClick={() => {
                            methods.reset();
                            setSelectedPatient(null);
                            setSelectedRow(null);
                          }}
                          className="border border-customRed text-customRed"
                        />
                      </div>
                      {selectedRow === null && (
                        <div
                          onClick={() => {
                            setPrivilege(obj.action);
                          }}
                        >
                          {/* <SaveButton onClick={methods.handleSubmit(onSubmit)} /> */}
                          <CommonButton
                            label="Save"
                            onClick={methods.handleSubmit(onSubmit)}
                            className="bg-customGreen text-white"
                          />
                        </div>
                      )}
                    </>
                  ) : null}
                </>
              ))}
            </div>
          </form>
        </FormProvider>
      </div>

      {/*  Modal    */}
      {openCBModal ? (
        <CBFormListing
          open={openCBModal}
          setOpen={setOpenCBModal}
          handleOpen={handleOpenCBModal}
          handleClose={handleCloseCBModal}
          userActions={userActions}
          setSelectedRow={setSelectedRow}
        />
      ) : null}

      {/* backdrop */}
      <CommonBackDrop openBackdrop={openBackdrop} />
      {/* //Confirmation Modal// */}
      <ConfirmationModal
        confirmationOpen={openConfirmationModal}
        confirmationHandleClose={() => {
          setOpenConfirmationModal(false);
        }}
        confirmationSubmitFunc={() => handleFormSubmit(finalData)}
        confirmationLabel="Confirmation "
        confirmationMsg="Are You Sure ?"
        confirmationButtonMsg="Proceed"
      />
    </>
  );
};

export default CodeBlueForm;
